class DateHelper {
  formatDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date?.toString());
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    return `${dd} / ${mm} / ${yyyy}`;
  }

  formatSecondToTime(seconds, withSeconds = true) {
    let day = 86400;
    let hour = 3600;
    let minute = 60;

    let daysout = Math.floor(seconds / day);
    let hoursout = Math.floor((seconds - daysout * day) / hour);
    let minutesout = Math.floor(
      (seconds - daysout * day - hoursout * hour) / minute,
    );
    let secondsout =
      seconds - daysout * day - hoursout * hour - minutesout * minute;

    return `${daysout > 0 ? `${daysout}j` : ''} ${
      hoursout > 0 ? `${hoursout}h` : ''
    } ${minutesout > 0 ? `${minutesout}m` : ''} ${
      withSeconds ? (secondsout > 0 ? `${secondsout}s` : '') : ''
    }`;
  }
}
export default new DateHelper();
