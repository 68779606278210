import React from 'react';

import {ReactComponent as ExpandMore} from "./ic_chevron_down.svg";
import {ReactComponent as ExpandLess} from "./ic_chevron_up.svg";

import './Accordion.scss';

const OSAccordion = ({element}) => {
  return (
    <details>
      <summary>
        {element.title}
        <ExpandMore className="control-icon control-icon-expand"/>
        <ExpandLess className="control-icon control-icon-close" />
      </summary>
      <div className="os-body2" style={{marginTop: 16}} dangerouslySetInnerHTML={{__html: element.description}} />
    </details>
  );
};

export default OSAccordion;
