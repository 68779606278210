import Network from './Network.ts';

class DashboardNetwork extends Network {
  async listPartners() {
    return await this.get('/partners', {});
  }
  async ShowPartners(partnersId: number){
    return await this.get(`/partners/${partnersId}`)
  }
}

export default new DashboardNetwork();
