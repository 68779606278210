import React from 'react';
import ContentLoader from "react-content-loader"

const TransactionsLoader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={50}
    backgroundColor="#dedede"
    foregroundColor="#cacaca">
    <circle cx="20" cy="18" r="16" />
    <rect x="45" y="5" rx="5" ry="5" width="65%" height="10" />
    <rect x="45" y="20" rx="5" ry="5" width="40%" height="10" />
  </ContentLoader>
);

export default TransactionsLoader;
