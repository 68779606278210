import { useEffect } from 'react';
import './style.scss';

const UnloggedRoutes = ({ children }) => {
	const cgpAuthToken = localStorage.getItem('CgpUserAuthToken');
	const authToken = localStorage.getItem('UserAuthToken');
	
	useEffect(() => {
		if (authToken && authToken !== 'undefined') {
			// If authToken exists, remove CgpUserAuthToken and redirect to /overview
			localStorage.removeItem('CgpUserAuthToken');
			window.location.replace('/overview');
		} else if (cgpAuthToken && cgpAuthToken !== 'undefined') {
			// If cgpAuthToken exists, remove UserAuthToken and redirect to /cgp/dashboard
			localStorage.removeItem('UserAuthToken');
			window.location.replace('/cgp/dashboard');
		}
	}, [authToken, cgpAuthToken]);
	
	return children;
};

export default UnloggedRoutes;
