import Network from "./Network.ts";

class CompanyBoostNetwork extends Network {
  async list({ page = 1, perPage = 20 }) {
    return await this.get('/company_boosts', {
      page,
      per_page: perPage,
    });
  }

  async details(id: number) {
    return await this.get(`/company_boosts/${id}`, {});
  }

  async createWithdrawBoost() {
    return await this.post('/company_boosts/create_withdraw_boost', {});
  }
}

export default new CompanyBoostNetwork();
