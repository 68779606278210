import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import BoostStore from '../../../stores/BoostStore.ts';

import OSButton from "../../../components/Button";

import './style.scss';

const BoostOfTheWeek = ({defaultProfile}) => {
  const isAnswered = !defaultProfile ? (BoostStore.question.company_answer_id !== null) : (BoostStore.question.user_answer_id !== null);
  //const question = isAnswered ? BoostStore.upcoming : BoostStore.question;

  const upcomingDate = BoostStore.upcoming?.date_from
      ? new Date(BoostStore.upcoming.date_from)
      : new Date();

  return (
    <div className="botw-container">
      <>
        <div className="section-container">
          <div className="section-div">
            { isAnswered && (
              <div className="page-boost-text">
                <p>La prochaine question sera disponible le :</p>
                <p className="page-boost-date">
                  {moment(upcomingDate).format('DD/MM/yyyy')}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="button-style boosts-button">
          { !isAnswered && <OSButton variant="primary" size="large" title="Répondre à la question" link="/opportunites/livret-p/boosts/question-de-la-semaine"/> }
        </div>
      </>
    </div>
  );
};

export default observer(BoostOfTheWeek);
