import React from 'react';

import './style.scss';
import OSButton from "../../../components/Button";

const NotFound = ({cgp}) => {
  return (
    <div className="not-found-container">
      <h2 className="os-h2">Désolé, cette page n'existe pas !</h2>
      <p> La page recherchée n'existe pas, peut-être que l'url est mal ortographiée ?</p>
      <div className="not-found-btn">
        <OSButton title="Revenir à l'accueil" link={cgp ? '/cgp/dashboard' : '/overview'} variant="primary" size="large"/>
      </div>
    </div>
  );
};

export default NotFound;
