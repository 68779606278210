import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import AuthSignupForm from "../../Forms/Auth/SignUp";
import authenticationStore from "../../stores/AuthenticationStore.ts";

import Images from "../../assets/images";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PublicNav from "../../components/Navbar/publicNav";
import Phone from "../../components/Phone";

import "./style.scss";
import Info from "../../components/info";

const elements = [
  {img: Images.collaboration.hold, content: <p>Détient les murs de <span className="important-horiz"> 140 restaurants Burger King</span></p>},
  {img: Images.collaboration.balance, content: <p><span className="important-horiz">411 millions d'euros</span> investis à fin 2022</p>},
  {img: Images.collaboration.growth, content: <p><span className="important-horiz">Performance de +140%*</span>depuis le lancement du fonds en 2016</p>},
  {img: Images.collaboration.occupancy, content: <p><span className="important-horiz">Taux d'occupation à 100%</span> avec <span className="important-horiz">28 millions d'euros</span> de loyers annuels</p>},
  {img: Images.collaboration.target, content: <p>Rendement cible à <span className="important-horiz">7% / an</span></p>},
  {img: Images.collaboration.liquidity, content: <p>Liquidité dans <span className="important-horiz">8 ans</span></p>},
]

const Horizio = () => {
  const [otp, setOtp] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchUser() {
    await authenticationStore.loadUserData().then(response => {
      setUser(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    otp && fetchUser();
  }, [otp]);

  return (
    <>
      <Helmet>
        <title>Openstone X Horiz.io</title>
      </Helmet>
      <PublicNav />
      <div className="os-flex collab-container" style={{alignItems: "flex-start"}}>
        <div className="collab-left-container horiz-left-container">
          <div className="sub-container horiz-container">
            <img src={Images.collaboration.horiz} alt="logo-horiz.io" />
            <h1 className="os-h1" style={{fontSize: 28, marginTop: 1, marginBottom:1}}>Investissez dans le fonds BK OPCI</h1>
            <div className="avantages-container">
              {elements.map((e, index) => (
                <div className="os-flex avantage-element" key={index}>
                  <img src={e.img} alt=""/>
                  {e.content}
                </div>
              ))}
            </div>
            <p className="info-text">*Les performances passées ne préjugent pas des performances futures</p>
          </div>
        </div>
        <div className="collab-auth-container">
          <div className="collab-auth-card collab-top">
            <h4 className="auth-title os-h4">Investissez dès maintenant sur le Fonds BK</h4>
            <span className="already">Déjà inscrit ?
            <Link to="/" className="already-link"> Connectez-vous !</Link>
          </span>
            <h5 className="os-h5">Vos informations</h5>
            {(otp && !loading) ? <Phone user={user} page="collaboration" redirectUrl="/opportunites/alternatifs/oppci-bk"/> : <AuthSignupForm page="collaboration" collaborator="horiz" setPhone={setOtp} /> }
            <Info logo={<LightbulbIcon/>} children="Titulaire d'un compte Livret P. ? Connectez-vous avec votre numéro de téléphone associé."/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Horizio;
