import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";

import SurveyNetwork from "../../../../config/Network/SurveyNetwork.ts";
import UserTransactionNetwork from "../../../../config/Network/UserTransactionNetwork.ts";

const questions1 = [
  {id: 1, text: "J'ai besoin de récupérer mes fonds"},
  {id: 2, text: 'Je préfère investir ailleurs'},
  {id: 3, text: 'Le taux est trop faible'},
  {id: 4, text: "J'ai un problème avec l'application"},
  {id: 5, text: "Je n'ai pas confiance"},
  {id: 6, text: 'Je trouve les questions difficiles'},
  {id: 7, text: 'Autres'},
];

const questions2 = [
  {id: 8, text: 'Vacances'},
  {id: 9, text: 'Impôts'},
  {id: 10, text: 'Imprévus'},
  {id: 11, text: 'Projets'},
  {id: 12, text: 'Autres'},
];

const questions3 = [
  {id: 13, text: 'Le taux est trop bas'},
  {id: 14, text: 'Le fonctionnement est trop compliqué'},
  {id: 15, text: 'Je préfère un autre placement'},
  {id: 16, text: "Je n'ai pas confiance"},
  {id: 17, text: 'Autres'},
];

const Survey = () => {
  const [step, setStep] = useState('initial');
  const [surveyId, setSurveyId] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [withdrawAsked, setWithdrawAsked] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({
    id: 0,
    text: '',
    type: '',
  });
  const [withdrawInfos, setWithdrawInfos] = useState({
    amount: 0,
    iban: '',
    bic: '',
  });

  useEffect(() => {
    if (step === 'initial') {
      setQuestions(questions1);
    } else if (step === 'funds') {
      setQuestions(questions2);
    } else if (step === 'otherInvestment') {
      setQuestions(questions3);
    }
  }, []);

  useEffect(() => {
    setWithdrawInfos({
      amount: localStorage.getItem('@amount'),
      iban: localStorage.getItem('@iban'),
      bic: localStorage.getItem('@bic'),
    });
    setSurveyId(localStorage.getItem('@surveyId'));
  }, []);

  const updateLastStepOfSurvey = async step => {
    await SurveyNetwork.updateFinalAction(surveyId, step);
  };

  const withdrawRequest = async() => {
    setWithdrawAsked(true);
    const responseWithdraw = await UserTransactionNetwork.withdraw(
      withdrawInfos.amount,
      withdrawInfos.iban,
      withdrawInfos.bic,
    );
    if (responseWithdraw) {
      //console.log(responseWithdraw)
    } else {
      toast.error('Oops!', responseWithdraw.data.message);
      setWithdrawAsked(false);
    }
  };

  return (
    <div>

    </div>
  );
};

export default Survey;
