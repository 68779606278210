import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {toast} from "react-toastify";

import OpportunityHelper from "../../Helpers/OpportunityHelper";
import MoneyHelper from "../../Helpers/MoneyHelper";
import EastIcon from '@mui/icons-material/East';

import InvestmentNetwork from "../../config/Network/InvestmentNetwork.ts";
import {CircularProgress} from "@mui/material";

import './style.scss';

const LpTransfer = ({object}) => {
  const {state} = useLocation();
  const { amount, product, transactionId } = state;
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function transfertFunds() {
    setIsSubmitting(true);
    const response = await InvestmentNetwork.transfertlp(transactionId, amount, object.legal_name ? 'Company' : 'User');
    if (response.status === 200) {
      toast.success(response.message, {
        toastId: 'completeTransferLp'
      });
      setTimeout(() => {
        window.location.replace(`/opportunites/${product.category_slug}/${product.slug}/finalise/paiement-status?transaction_type=livretp&transactionAmount=${amount}&status=success`);
      }, 1500)
    } else {
      toast.error(response.message, {
        toastId: 'errorTransferLp'
      })
    }
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1500)
  }

  return (
    <>
      <Helmet>
        <title>Transfert Livret P. - {product.name}</title>
      </Helmet>
      <div className="card-container">
        <h5 className="os-h5">Transfert Livret P. - {product.name}</h5>
        <p >Vous êtes sur le point de transférer {MoneyHelper.formatEuro(amount)} de votre solde Livret P., vers le produit {product.name}, souhaitez-vous continuer ?</p>
        <div className="os-flex products-container">
          <div className="product-container">
            {OpportunityHelper.renderCategory({category_name: "Livret P."}).logo}
            <p>Livret P.</p>
          </div>
          <div className="product-container">
            <EastIcon />
            <p className="os-subtitle1">{MoneyHelper.formatEuro(amount)}</p>
          </div>
          <div className="product-container">
            {OpportunityHelper.renderCategory(product).logo}
            <p>{product.name}</p>
          </div>
        </div>
        <div className="lp_transfer--btn">
          <button className="choose-payment-button__button" onClick={() => transfertFunds()}>
            {isSubmitting && <CircularProgress color="inherit" />}
            Valider le transfert
          </button>
        </div>
      </div>
    </>
  );
};

export default LpTransfer;
