import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

import './style.scss';

const PerPage = ({perPage, setPerPage}) => {
  return (
    <div className="per-page-container">
      <FormControl>
        <InputLabel id="per-page-label">Éléments par page</InputLabel>
        <Select
          labelId="per-page-label"
          id="per-page-select"
          value={perPage || ''}
          label="Opportunities"
          onChange={event => setPerPage(event.target.value)}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default PerPage;
