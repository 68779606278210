import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import OsRadio from "../../../../components/Form/OsRadio";
import OSButton from "../../../../components/Button";

const Statement = ({user, handleStep, page, isCgp, company}) => {
  const [submitting, setSubmitting] = useState(false);

  const validationSchema = yup.object({
    certify_on_honor: yup.string().required("Requis"),
    informed_on_risks: yup.string().required("Requis"),
    no_canvassing: yup.string().required("Requis"),
    non_professional_categorization: yup.string().required("Requis"),
  });

  const formik = useFormik({
    initialValues: {
      certify_on_honor: (page === 'companyForm' ? company.certify_on_honor : user.certify_on_honor)?.toString(),
      informed_on_risks: (page === 'companyForm' ? company.informed_on_risks : user.informed_on_risks)?.toString(),
      no_canvassing: (page === 'companyForm' ? company.no_canvassing : user.no_canvassing)?.toString(),
      non_professional_categorization: (page === 'companyForm' ? company.non_professional_categorization : user.non_professional_categorization)?.toString(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const datasToSubmit = {
        certify_on_honor: values.certify_on_honor,
        informed_on_risks: values.informed_on_risks,
        no_canvassing: values.no_canvassing,
        non_professional_categorization: values.non_professional_categorization
      }
      async function handleRequest() {
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord(datasToSubmit, company.id, 'Company') : await CgpNetwork.updateRecord(datasToSubmit, user.id, 'User');
        } else {
          page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id, datasToSubmit) : await AuthenticationNetwork.editUser(datasToSubmit);
        }
      }
      const response = handleRequest();
      if (response) {
        setSubmitting("false");
        toast.success('Vos informations ont bien été enregistrés', {
          toastId: 'completeInfosSuccessToast'
        });
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord({completed: true}, company.id, 'Company') : await CgpNetwork.updateRecord({completed: true}, user.id, 'User')
        } else {
          if (page === 'companyForm') {
            await CompanyNetwork.updateCompany(company.id, {completed: true});
            localStorage.removeItem('CompanySurveyStep');
          } else {
            await AuthenticationNetwork.editUser({completed: true});
          }
        }
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });
  return (
    <div>
      <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
        <div className="user-survey-questions-container">
          <div className="full-input radio-inputs">
            <OsRadio
              formik={formik}
              name="certify_on_honor"
              value="true"
              label="Je certifie sur l’honneur, l’exactitude et la sincérité des informations et pièces fournies dans le questionnaire d’information et m’engage à signaler à Openstone toute modification des éléments y figurant et qui pourraient notamment avoir une influence sur ma situation personnelle et mes objectifs d’investissement, lesquels ne dépassent pas 20% de mon patrimoine."
            />
          </div>
        </div>

        <div className="user-survey-questions-container">
          <div className="full-input radio-inputs">
            <OsRadio
              formik={formik}
              name="informed_on_risks"
              value="true"
              label="Je reconnais être conscient et avoir été informé des risques de perte en capital et de liquidités que comporte l’investissement dans ces structures non-côtés, ainsi que du risque de défaut de certaines sociétés cibles. Je suis financièrement capable de faire face a la perte partielle ou totale de montant investi."
            />
          </div>
        </div>

        <div className="user-survey-questions-container">
          <div className="full-input radio-inputs">
            <OsRadio
              formik={formik}
              name="no_canvassing"
              value="true"
              label="Je reconnais m’être inscrit en l’absence de tout démarchage bancaire ou financier tel que décrit à l’article L341-1 du code monétaire et financier."
            />
          </div>
        </div>
        <div className="user-survey-questions-container">
          <div className="full-input radio-inputs">
            <OsRadio
              formik={formik}
              name="non_professional_categorization"
              value="true"
              label="Je reconnais avoir pris connaissance des caractéristiques du produit (horizon de placement, risques de perte en capital et de liquidité)"
            />
          </div>
        </div>
	      <div className="user-survey-btn-container">
		      <OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium"/>
		      <button type="submit" className="user-survey-btn" disabled={submitting}>Terminer</button>
	      </div>
	      <div className="user-survey-btn-container">
		      {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
	      </div>
      </form>
    </div>
  );
};

export default Statement;
