import React, {useEffect, useState} from 'react';

import ProductCategoriesNetwork from "../../../../config/Network/ProductCategoriesNetwork.ts";

import MoneyHelper from "../../../../Helpers/MoneyHelper";

import ColoredCard from "../../../../components/card/ColoredCard";
import OSButton from "../../../../components/Button";
import InvestmentSlider from "../../../../components/Sliders/Product/InvestmentSlider";
import OsModal from "../../../../components/Modals";
import Video from "../../../livretp/Video";
import NumberHelper from "../../../../Helpers/NumberHelper";
import LivretPViewLoader from "../../../../components/Loaders/LivretP";
import LivrePViewLoaderMobile from "../../../../components/Loaders/LivretP/Mobile";
import OpportunityHelper from "../../../../Helpers/OpportunityHelper";

import './style.scss';

const CategoryInvestments = () => {
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);

  const toggle = type => {
    if (type === 'video') {
      setIsShowing(!isShowing);
    }
  };

  async function fetchCategory() {
    const apiCategory = await ProductCategoriesNetwork.getCategory(1);
    setCategory(apiCategory);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }

  useEffect(() => {
    fetchCategory()
  }, []);

  function checkPage() { <OsModal title={`Caroline vous dit tout sur les ${category.name}`} isShowing={isShowing} hide={() => toggle('video')} >
	  <Video link={category.category_video}/>
  </OsModal>
    if (loading || category === {}) {
      return (
        <div className="loader-display">
          <div className="desktop-display">
            <LivretPViewLoader />
          </div>
          <div className="mobile-display">
            <LivrePViewLoaderMobile />
          </div>
        </div>
      )
    } else if (!loading && category !== {}) {
      return (
        <div>
          <div className="category-investments-cards">
            <div className="category-investments-infos">
              <div className="investment-details-title">
                <h3 className="os-h3">SCPI</h3>
                <div className="label-container">
                  <span className="label-text">{category.label}</span>
                </div>
              </div>
              <p className="product-description">{category.description} </p>
              <div>
                <h5 className="os-h5">Investissement total {category.category_name} : {MoneyHelper.formatEuro(category.balance)}</h5>
              </div>
            </div>
            <div className="category-investment-slider">
              <InvestmentSlider func={toggle} categoryName={category.category_name}/>
            </div>
          </div>
          <div className="investment-details-title investment-details-title__space ">
            <h4 className="os-h4">Répartition par {category.category_name}:</h4>
            <div>
              <OSButton title={`Voir tous les produits ${category.category_name}`} size="large" variant="secondary" link={`/opportunites/${category.category_slug}`} />
            </div>
          </div>
          <div className="category-investment-products">
            {category.products.map(p => (
              <ColoredCard
                productInfos={{logo: OpportunityHelper.renderCategory().logo, name: p.name, rate: NumberHelper.formatPercentage(p.actual_rate), link: `/opportunites/${category.category_slug}/${p.slug}`}}
                data={p.user_balance}
                page="categoryDetails"
                key={`${p.name}-${p.id}`}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className="category-investments-container">
        {checkPage()}
      </div>
     
    </>
  );
};

export default CategoryInvestments;
