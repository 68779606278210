import APIs from './Network.ts';

class UserInvitationNetwork extends APIs {
  async stats() {
    try {
      return await this.get('user_invitations', {});
    } catch (err) {
      return null;
    }
  }

  async list({page = 1, perPage = 20, status = 'verified'}) {
    try {
      return await this.get('/user_invitations/users', {
        page,
        per_page: perPage,
        status,
      });
    } catch (err) {
      return null;
    }
  }

  async getReferredUsers({page, perPage, status, date, startDate, endDate}) {
    try {
      return await this.get('/user_invitations/referred_users', {
        page,
        per_page: perPage,
        status,
        date,
        start_date: startDate,
        end_date: endDate
      });
    } catch (err) {
      return null;
    }
  }
}

export default new UserInvitationNetwork();
