import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup';

import AuthenticationNetwork from "../../../config/Network/AuthenticationNetwork.ts";
import TextField from "@material-ui/core/TextField";

const WithdrawForm = ({ object, amount, func, otp, defaultProfile }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [otpExpiryDate, setOtpExpiryDate] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [showOtp, setShowOtp] = useState(false);
	
	const requestOTP = async (values) => {
		setIsLoading(true);
		localStorage.setItem('@amount', amount);
		localStorage.setItem('@iban', values.iban);
		localStorage.setItem('@bic', values.bic);
		const response = await AuthenticationNetwork.requestOTP(
			defaultProfile ? object.phone_number : object.user?.phone_number,
			defaultProfile ? object.id : object.user.id
		);
		if (response) {
			func(true);
			setShowOtp(true);
			setOtpExpiryDate(response.otp_receive_new_one_at);
			toast.success('Vos informations ont bien été enregistrées', {
				toastId: 'completeInfosSuccessToast'
			});
		} else {
			toast.error("Une erreur est survenue lors de l'enregistrement de vos informations", {
				toastId: 'completeInfosErrorToast'
			});
		}
		setIsLoading(false);
		setSubmitting(false);
	};
	
	const validationSchema = Yup.object({
		iban: Yup.string()
			.matches(/^FR/, "L'IBAN doit être français")
			.min(26, "L'IBAN doit être composé de 27 caractères")
			.max(27, "L'IBAN doit être composé de 27 caractères")
			.required("L'IBAN doit être renseigné"),
		bic: Yup.string()
			.min(8, "Le BIC doit être composé au minimum de 8 caractères")
			.max(11, "Le BIC doit être composé au maximum de 11 caractères")
			.required('Le BIC doit être renseigné'),
	});
	
	const formik = useFormik({
		initialValues: {
			iban: '',
			bic: ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			setIsLoading(true);
			requestOTP(values);
		},
	});
	
	const handleIbanChange = (event) => {
		const value = event.target.value.replace(/\s+/g, ''); // Remove whitespaces
		formik.setFieldValue('iban', value);
	};
	
	return (
		<div>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div className="user-survey-questions-container">
					<TextField
						fullWidth
						id="iban"
						name="iban"
						label="IBAN (27 caractères)"
						value={formik.values.iban}
						onChange={handleIbanChange}
						error={formik.touched.iban && Boolean(formik.errors.iban)}
						helperText={formik.touched.iban && formik.errors.iban}
						variant="outlined"
						className="by-1"
					/>
					<TextField
						fullWidth
						id="bic"
						name="bic"
						label="BIC"
						value={formik.values.bic}
						onChange={formik.handleChange}
						error={formik.touched.bic && Boolean(formik.errors.bic)}
						helperText={formik.touched.bic && formik.errors.bic}
						variant="outlined"
						className="by-1"
					/>
				</div>
				
				{!showOtp && (
					<div className="user-survey-btn-container">
						{(Object.keys(formik.errors).length !== 0 && (amount < object.withdrawable_amount)) &&
							<span className="error">Vous devez remplir tous les champs afin de continuer</span>}
						<button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
					</div>
				)}
			</form>
		</div>
	);
};

export default WithdrawForm;
