import React from 'react';

import './style.scss';


/**
 * A simple Instructions container component.
 *
 * @param {string} title - The content to display as the title inside the container.
 * @param {string} content - The main content to display inside the container.
 * @param {string} link - The URL for a link where you'll be redirected.
 * @param {string} linkTitle - The title for the link where you'll be redirected.
 * @param {ReactNode} extraChildren - Additional content to be added inside the container.
 */

const InstructionsContainer = ({title, content, link, linkTitle, extraChildren}) => {
  return (
    <div className="instructions-container">
      <h6 className="os-h6">{title}</h6>
      <p className="os-body2">
        {content} {' '}
        {link && <a className="instructions-container-link" href={link}>{linkTitle}</a>}
        {extraChildren}
      </p>
    </div>
  );
};

export default InstructionsContainer;
