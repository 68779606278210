import React, {useEffect, useState} from 'react';
import authenticationStore from "../../stores/AuthenticationStore.ts";
import {toast} from "react-toastify";

import {Helmet} from "react-helmet";
import YourReferrals from "../../components/Yours";
import StepsExplainer from "../../components/StepsExplainer";
import AddReferralCode from "../../Forms/Referral/addReferralCode";

import {ReactComponent as Copy} from "./no-documents.svg";
import {ReactComponent as Wait} from "./wait.svg";
import {ReactComponent as Validate} from "./validate.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import './style.scss';

const steps = [
  { label: 'Envoyez votre code de parrainage.' },
  { label: 'Attendez que votre ami(e) s’inscrive et effectue son premier dépôt' },
  { label: 'Gagnez un bonus pour chaque ami(e) qui ouvre son compte Livret P.' },
];

const Referrals = (navigation) => {
  const [code, setCode] = useState('');
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [added, setAdded] = useState(false);

  const fetchUser = async () => {
    setUser({})
    await authenticationStore.loadUserData().then(response => {
      setUser(response);
      setCode(response.user_invitation.code);
      setLoading(false)
    });
  };

  useEffect(() => { fetchUser() }, [added])

  const mailto = `mailto:?subject=Je t'invite à rejoindre Livret P.... &body= Voici mon code de parrainage a utiliser sur LivretP ${code}  `;

  const steps = [
    {
      title: "Partagez votre code",
      body: "Diffusez votre code de parrainage à vos proches.",
      illustration: <Copy />
    },
    {
      title: "Inscription de votre proche",
      body: "Assurez-vous que votre proche s'inscrive avec votre code et réalise son premier dépôt.",
      illustration: <Wait />
    },
    {
      title: "Profitez de 10€ offerts",
      body: "Recevez chachun une prime de 10€ à l'ouverture du Livret P. de votre proche.",
      illustration: <Validate />
    }
  ]

  function checkHasParrain() {
    if (!loading && !user.has_parrain && user.account_status !== "verified") {
      return (
        <>
          <h4 className="os-h4">Votre parrain</h4>
          <div className="os-flex search-invitation-container">
            <p className="os-subtitle1">Vous avez un code de parrainage ?</p>
            <AddReferralCode func={setAdded}/>
          </div>
        </>
      )
    } else if (!loading && !user.has_parrain && user.account_status === "verified") {
      return null;
    } else if (!loading) {
      return (
        <>
          <h4 className="os-h4">Votre parrain</h4>
          <p className="os-body1">Vous avez été parrainé par <strong>{user?.referred_by?.full_name}</strong></p>
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>Openstone | Parrainage</title>
      </Helmet>
      <div className="referral-container">
        <div className="os-flex referral-title">
          <h2>Parrainage</h2>
          <div className="referral-code-copy">
            <p>Mon code parrain : {code}</p>
            <button className="os-flex field-copy" onClick={() => {
              navigator.clipboard.writeText(code)
              toast.success('Code de parrainage copié', {
                toastId: `referral_Toast`
              });
            }}>Copier <ContentCopyIcon /></button>
          </div>
        </div>
        <StepsExplainer steps={steps}/>
        <h4 className="os-h4" style={{marginTop: 32, marginBottom: 8}}>Vos parrainages</h4>
        <YourReferrals navigation={navigation}/>
        {checkHasParrain()}
      </div>
    </>
  );
};

export default Referrals;
