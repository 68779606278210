import Network from "./Network.ts";

class QuestionNetwork extends Network {
  async active() {
    return await this.get('/questions/active', {});
  }

  async answerActive(answer_id: number, forType: string) {
    return await this.post('/questions/answer', { answer_id, for_type: forType });
  }

  async generateAnswer() {
    return await this.post('/questions/answer', {});
  }

  async upcoming() {
    return await this.get('/questions/upcoming', {});
  }
}

export default new QuestionNetwork();
