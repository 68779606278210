class NumberHelper {
	formatPercentage(percentage) {
		const number = Number(percentage);
		if (number % 1 === 0) {
			return `${number.toFixed(0).replace(".", ",")} %`;
		} else {
			return `${number.toFixed(2).replace(".", ",")} %`;
		}
	}
	numberWithSpaces(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
export default new NumberHelper()
