import Network from './Network.ts';

class PromotionsNetwork extends Network {
  async listPromotions(forType: string) {
    return await this.get('/user_coupons', {
      for_type: forType
    });
  }

  async addPromotion(couponName: string, forType: string) {
    return await this.post('/user_coupons', {
      coupon_name: couponName,
      for_type: forType
    });
  }

  async listAvailable(forType: string, productId: string, productType: string) {
    return await this.get('/user_coupons/available', {
      for_type: forType,
      product_id: productId,
      product_type: productType
    });
  }
}

export default new PromotionsNetwork();
