import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import '../style.scss'

const DropdownButton = ({ items, variant, size, fit, title, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button onClick={!disabled && toggleDropdown} className={`os-flex dropdown-button os-button ${size === '' ? `os-button-${variant}` : `os-button-${variant}__${size}`} ${fit && 'os-button-fit'}`}  disabled={disabled}>
      {title}
      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
      {isOpen && (
        <ul className="dropdown-menu">
          {items.map((item, index) => (
            <li key={index} onClick={item.onClick}>
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

export default DropdownButton;
