// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import Scrollbar from '../scrollbar';
import { TableHeadCustom } from './index.js';

import './myTable.scss';
import React from "react";
import Images from "../../assets/images/index";
import DateHelper from "../../Helpers/DateHelper";

// ----------------------------------------------------------------------
export default function BoostsTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map(row => (
                <BoostsTableRow key={`${row.id}-${row.boost.extra_percentage}`} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------


function BoostsTableRow({ row }) {
  const start = new Date().getTime();
  const end = Date.parse(row.should_end_at);
  const diff = end - start;
  const seconds = Math.floor(diff / 1000);
  const withSeconds = false;

  let statusImage;
  if (row.status === 'active') {
    statusImage = <p className="boosts-status">Actif <img src={Images.up} alt="Active"/></p>;
  }  else if (row.status === 'ended') {
    statusImage = <p className="boosts-status">Terminé<img src={Images.up} alt="Error" /></p>;
  } else {
    statusImage = <div>{row.status}</div>;
  }

  return (
      <>
        <TableRow className={row.status === 'ended' && 'disabled'}>
          <TableCell>
            <div>{row.boost.extra_percentage} %</div>
          </TableCell>
          <TableCell>
            <div>{Math.floor(row.boost.validity_value)} jours</div>
          </TableCell>
          <TableCell>
            <div>{DateHelper.formatSecondToTime(seconds, withSeconds )}</div>
          </TableCell>
          <TableCell>
            <div>{statusImage}</div>
          </TableCell>
        </TableRow>
      </>
  );
}


