import React, { useState, useEffect } from 'react';
import moment from 'moment';
import FundsCallEstimationTable from "../Table/FundsCallEstimationTable";

const FundCallCalculator = ({ balance, fees }) => {
    const [fundCalls, setFundCalls] = useState([]);

    useEffect(() => {
        if (balance >= 100000) {
            const calculatedFundCalls = calculateFundCalls(balance);
            setFundCalls(calculatedFundCalls);
        }
    }, [balance]);

    const calculateFundCalls = (totalAmount) => {
        const firstPayment = Math.round(totalAmount * 0.34);
        const remainingPayment = Math.round((totalAmount - firstPayment) / 2);

        return [
            createFundCall(1, firstPayment, new Date()), // First fund call due today
            createFundCall(2, remainingPayment, getNextYearFirstOfQuarter(1)), // Beginning of the current quarter next year (1 year ahead)
            createFundCall(3, remainingPayment, getNextYearFirstOfQuarter(2)), // Beginning of the current quarter in two years (2 years ahead)
        ];
    };

    const createFundCall = (callNumber, amount, dueDate) => {
        return {
            callNumber,
            amount,
            status: 'pending',
            dueDate: moment(dueDate).toISOString(), // Store date as ISO string
        };
    };

    const getNextYearFirstOfQuarter = (yearsAhead) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const nextYear = currentYear + yearsAhead;

        // Calculate the first month of the current quarter
        let firstMonthOfQuarter;
        const currentMonth = currentDate.getMonth() + 1; // Month is 1-indexed

        if (currentMonth >= 1 && currentMonth <= 3) {
            firstMonthOfQuarter = 1; // Q1: January 1st
        } else if (currentMonth >= 4 && currentMonth <= 6) {
            firstMonthOfQuarter = 4; // Q2: April 1st
        } else if (currentMonth >= 7 && currentMonth <= 9) {
            firstMonthOfQuarter = 7; // Q3: July 1st
        } else {
            firstMonthOfQuarter = 10; // Q4: October 1st
        }

        return new Date(nextYear, firstMonthOfQuarter - 1, 1); // Month is zero-indexed in JavaScript Date
    };

    return (
        <>
            <p className="os-subtitle2">Calcul de l'appel de fonds</p>
            <FundsCallEstimationTable tableData={fundCalls} tableLabels={[
                { id: 'callNumber', label: 'Numéro' },
                { id: 'amount', label: 'Montant dû' },
                { id: 'dueDate', label: 'Date de paiement' },
            ]} fees={fees} />
        </>
    );
};

export default FundCallCalculator;
