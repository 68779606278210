import React from 'react';
import Pdf from "../../../../components/Document/pdf";

import './style.scss';

const InvestmentDirectory = ({product, tab}) => {
  return (
    <>
      {tab !== 'synthesis' && <h5 className="os-h5">Documents publics</h5>}
      <div className={`${tab === 'synthesis' ? 'product-documents-container-synthesis' : 'product-documents-container'}`}>
        {product.documents.map(document => (
          <Pdf page="opportunity" document={document}/>
        ))}
      </div>
    </>
  );
};

export default InvestmentDirectory;
