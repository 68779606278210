import React from "react"
import ContentLoader from "react-content-loader"

const BannerLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={400}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="6" y="2" rx="10" ry="10" width="99%" height="100%" />
  </ContentLoader>
)

export default BannerLoader
