import React from 'react';

import './style.scss';

const Video = ({link}) => {
  function checkPage() {
    if (link === null || link === undefined) {
      return <iframe loading="lazy" frameBorder="0" className="iframe-video" src="https://player.gotolstoy.com/t1n7ei30jv1ef?playerType=bubble" title="abc" />
    } else {
      return <iframe width="100%" height="600" src={link} loading="lazy"
                     title="YouTube video player"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                     allowFullScreen frameBorder="0" />
    }
  }
  return (
    <div className="iframe-container">
      {checkPage()}
    </div>
  );
};

export default Video;
