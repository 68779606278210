import React from 'react';

import './Blocks.scss';


/**
 * A simple Block component.
 *
 * @param {string} children - The content to display inside the block.
 */
const OSBlocks = ({children}) => {
  return (
    <div className="os-block">
      {children}
    </div>
  );
};

export default OSBlocks;
