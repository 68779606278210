import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import './style.scss';

const ModalHeader = ({ cantClose, isWhite, title, hide }) => (
    <div className="modal-header">
        <h5 className="os-h5">{title}</h5>
        {!cantClose && (
            <button
                type="button"
                className={`modal-close ${!isWhite && 'modal-close'}`}
                onClick={hide}
            >
                <span>&times;</span>
            </button>
        )}
    </div>
);

const ModalBody = ({ children }) => <div className="modal-body">{children}</div>;

/**
 * A simple modal  component.
 *
 * @param {string} title - The content to display as the title inside the modal.
 * @param {string} content - The main content to display inside the modal.
 * @param {boolean} isWhite - The URL for a link where you'll be redirected.
 * @param {string} cantClose - The title for the link where you'll be redirected.
 */

const OsModal = ({ title, isShowing, hide, children, cantClose, isWhite }) => {
    const modalClasses = `${isWhite !== false && 'modal-white'} modal small-modal ${cantClose ? '' : 'modal-close-available'}`;

    return isShowing
        ? ReactDOM.createPortal(
            <div className="modal-overlay">
                <div className="modal-wrapper">
                    <div className={modalClasses}>
                        <ModalHeader cantClose={cantClose} isWhite={isWhite} title={title} hide={hide} />
                        <ModalBody>{children}</ModalBody>
                    </div>
                </div>
            </div>,
            document.body
        )
        : null;
};

OsModal.propTypes = {
    title: PropTypes.string.isRequired,
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    cantClose: PropTypes.bool,
    isWhite: PropTypes.bool,
};

export default OsModal;
