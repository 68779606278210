import React from 'react';
import {useNavigate} from "react-router-dom";

import StepsContainer from "../StepsContainer";
import EmptyContent from "../../../../../components/empty-content/EmptyContent";

import {ReactComponent as NoDocs} from "../../../../../assets/Illustrations/parrainage.svg";

const SentSuccess = ({product, clientId, clientType, cgpUser}) => {
  const navigate = useNavigate();
	
	const checkMessage = () => {
		let message;
		if (product.subscription_state === 'waiting_list_with_info') {
			message = `Félicitations pour votre souscription au fonds ${product?.name}.\n` +
				"Aucune action immédiate n'est requise de votre part.\n\n" +
				"Vous recevrez prochainement une communication détaillant les modalités de l'appel de fonds et les prochaines étapes de votre investissement.\n\n" +
				"Si vous avez des questions, n’hésitez pas à contacter votre conseiller qui se fera un plaisir de vous aider.\n\n"
		} else {
			message = "Les documents de souscriptions ont été envoyés avec succès au client pour signature éléctronique."
		}
		
		// Replace newline characters with <br /> tags for rendering in HTML
		return message.replace(/\n/g, "<br />");
	}
	
	return (
    <div>
      <StepsContainer children={
        <>
          <EmptyContent text={checkMessage()}
                        cta={{title: "Voir l'investissement", variant: "primary", size: "small", onClick: () => navigate(`/cgp/clients/${clientId}/investissement/${product.category_slug}/${product.slug}`, {state: {id: product.id, clientId: clientId, clientType: clientType}})}}
                        illustration={<NoDocs />}
												page="engagement"
												cgpUser={cgpUser}
          />
        </>
      } product={product} />
    </div>
  );
};

export default SentSuccess;
