import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import {useParams} from "react-router";

import DashboardNetwork from "../../config/Network/DashboardNetwork.ts";

import PartnerViewCard from "../../components/PartnerViewCard";
import PartnerViewLoader from "../../components/Loaders/Partners";
import OSButton from "../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./style.scss"

const PartnersView = () => {
  let { id } = useParams();
  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(true);
  async function fetchPartners() {
    const apiPartners = await DashboardNetwork.ShowPartners(id);
    setPartners(apiPartners);
    setTimeout(() => {
      setLoadingPartners(false);
    }, 200)
  }
  function goBackToAuth() {
    window.location.replace('/overview');
  }
  useEffect(() => { fetchPartners() }, []);
  const renderPartners = () => {
    if (loadingPartners) {
      return (
        <PartnerViewLoader/>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>Openstone | Partenaires</title>
          </Helmet>
          <OSButton variant="primary-full" size="medium" title="Revenir à la page précédente" onClick={() => goBackToAuth()} icon={<ArrowBackIcon />} />
          <PartnerViewCard partner={partners}/>
        </>
    )
    }
  }

  return (
    <div className="partner-view-container">
      {renderPartners()}
    </div>
  )

}

export default PartnersView;
