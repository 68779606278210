import React from 'react';

import OsTooltip from "../../../../../components/Tooltips";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import '../../style.scss';

const OpportunityCardInfos = ({label, value,content}) => {
  return (
    <div className="opportunity-card-infos">
      <span className="os-body1 info-label">{label}
          {content && (
              <OsTooltip title={<Typography color="inherit">{content}</Typography>}>
                  <div className="tooltip-btnOpportunityCardInfos"><InfoOutlinedIcon /></div>
              </OsTooltip>
          )}
      </span>
      <span className="os-body1 info-value">{value}</span>
    </div>
  );
};

export default OpportunityCardInfos;
