import Network from './Network.ts';

class ProductCategoriesNetwork extends Network {
  async listCategories() {
    return await this.get('/categories');
  }

  async getCategory(categoryId: number) {
    return await this.get(`/categories/${categoryId}`, {});
  }
}

export default new ProductCategoriesNetwork();
