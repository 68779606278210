import Network from './Network.ts';

class SurveyNetwork extends Network {
  async createSurvey(answer1: string) {
    return await this.post('/surveys', {
      answer_1: answer1,
      status: 'started',
    });
  }

  async updateSurveyAnswer2(survey_id: number, answer_2: string) {
    return await this.put('/surveys', {
      survey_id,
      answer_2,
    });
  }

  async updateOtherReasons(survey_id: number, reason: string) {
    return await this.put('/surveys', {
      survey_id,
      reason
    });
  }

  async updateFinalAction(survey_id: number, final_action: string) {
    return await this.put('/surveys', {
      survey_id,
      final_action
    });
  }
}

export default new SurveyNetwork();
