import APIs from './Network.ts';

class UserJumioScanNetwork extends APIs {
  async create(scan_type: string) {
    return await this.post('/user_jumio_scans', {
      scan_type,
    });
  }

  async markAsError(scanId: string) {
    return await this.put(`/user_jumio_scans/${scanId}/error`);
  }

  async validateDocument(scanId: string) {
    return await this.put(`/user_jumio_scans/${scanId}/user_validated`)
  }

  async unvalidateDocument(scanId: string) {
    return await this.put(`/user_jumio_scans/${scanId}/user_unvalidated`)
  }

  async uploadDocuments(documents: any[], documentType: string, userId: string) {
    let data = {
      files: documents,
      document_type: documentType,
      record_type: 'user_id',
      record_id: userId
    };

    return await this.formDataPost('/user_document_uploads', data);
  }

  async companyUploadDocuments(documents: any[], documentType: string, companyId: string) {
    let data = {
      files: documents,
      document_type: documentType,
      record_type: 'company_id',
      record_id: companyId
    };

    return await this.formDataPost('/company_document_uploads', data);
  }

  async cgpUploadDocuments(documents: any[], documentType: string) {
    let data = {
      files: documents,
      document_type: documentType
    };

    return await this.formDataPost('/user_document_uploads/cgp', data);
  }
}

export default new UserJumioScanNetwork();
