import Network from "./Network.ts";

class UserBoostNetwork extends Network {
  async list({ page = 1, perPage = 20 }) {
    return await this.get('/user_boosts', {
      page,
      per_page: perPage,
    });
  }

  async details(id: number) {
    return await this.get(`/user_boosts/${id}`, {});
  }

  async createWithdrawBoost() {
    return await this.post('/user_boosts/create_withdraw_boost', {});
  }
}

export default new UserBoostNetwork();
