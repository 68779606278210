import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import OpportunityHelper from "../../../../Helpers/OpportunityHelper";

import "../../smallSlider/style.scss";

// import required modules

const SmallSlider = ({func, categoryName, product}) => {

  const convertCategoryName = (categoryName) => {
    if (categoryName === 'Alternatifs') {
      return 'Private Equity';
    } else {
      return categoryName;
    }
  };

  return (
    <>
      <Swiper autoplay={{ delay: 3000, disableOnInteraction: false}}
              pagination={{clickable: true}}
              modules={[Autoplay, Pagination]}
              className="smallSwiper">
        <SwiperSlide style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0, 0.6) 100%), url(${product.images[2]?.attachment.url})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div className="slider-content">
            <p className="slider-content-label">Découvrez les {convertCategoryName(categoryName)}</p>
            <h4 className="slider-content-title">{OpportunityHelper.renderCategory(product).videoTitle}</h4>
            <button className="slider-btn" onClick={() => func('video')}>Voir la vidéo</button>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SmallSlider;
