const workCategories = [
  {label: 'Artisans', value: 'Artisans'},
  {label: 'Agriculteurs exploitants', value: 'Agriculteurs exploitants'},
  {label: "Chef d'entreprise", value: "Chef d'entreprise"},
  {label: 'Commerçants et assimilés', value: 'Commerçants et assimilés'},
  {label: 'Cadres et prof. intellectuelles du secteur privé', value: 'Cadres et prof. intellectuelles du secteur privé'},
  {label: 'Employé du secteur privé', value: 'Employé du secteur privé'},
  {label: 'Ouvrier, contremaîtres, agent de maîtrise du secteur privé', value: 'Ouvrier, contremaîtres, agent de maîtrise du secteur privé'},
  {label: 'Employés et agents de la fonction publique', value: 'Employés et agents de la fonction publique'},
  {label: 'Professions indépendantes', value: 'Professions indépendantes'},
  {label: 'Professions libérales', value: 'Professions libérales'},
  {label: 'Professions libérales médicales', value: 'Professions libérales médicales'},
  {label: 'Élèves, étudiants, apprentis', value: 'Élèves, étudiants, apprentis'},
  {label: 'Chômeurs', value: 'Chômeurs'},
  {label: 'Retraités', value: 'Retraités'},
  {label: 'Autres personnes sans activité professionnelle', value: 'Autres personnes sans activité professionnelle'},
  {label: "Métiers de l'industrie du pétrole", value: "Métiers de l'industrie du pétrole"},
  {label: 'Métiers en lien avec le commerce d\'objets culturels', value: 'Métiers en lien avec le commerce d\'objets culturels'},
  {label: 'Professions juridiques', value: 'Professions juridiques'},
];

const maritalStatuses = [
  {label: 'Célibataire', value: 'Célibataire'},
  {label: 'Pacsé(e)', value: 'Pacsé'},
  {label: 'Marié(e)', value: 'Marié'},
  {label: 'Divorcé(e)', value: 'Divorcé'},
  {label: 'Veuf / Veuve', value: 'Veuf'},
];

const pacsRegime = [
  {label: 'Indivision', value: 'Indivision'},
  {label: 'Séparation des biens', value: 'Séparation des biens'},
];
const marriedRegime = [
  {label: 'Communauté universelle', value: 'Communauté universelle'},
  {label: 'Séparation des biens', value: 'Séparation des biens'},
  {label: 'Communauté réduite aux acquêts', value: 'Communauté réduite aux acquêts'},
  {label: 'Participation aux acquêts', value: 'Participation aux acquêts'},
];

const ppeFunction = [
  {value: 'Ambassadeur'},
  {value: 'Consul général ou consul de carrière'},
  {value: "Chef d'état, chef de gouvernement, membre d'un gouvernment national ou de la Comission Européenne"},
  {value: "Dirigeant d'une institution générale publique"},
  {value: "Dirigieant ou membre de l'organe de direction d'une banque centrale"},
  {value: "Membre d'un organe d'administration, de direction ou de surveillance d'une entreprise publique"},
  {value: "Membre d'une assemblé parlementaire nationale"},
  {value: "Membre d'une assemblé du Parlement européen"},
  {value: "Membre d'une cour des comptes"},
  {value: "Membre d'une cour suprême, d'une cour constitutionelle ou d'une autre haute juridiction"},
  {value: "Officier général ou officier supérieur assurant le commandement d'une armée"},
];

const diplomas = [
  {value: 'Ecole de commerce, filière finance'},
  {value: 'Diplômé universitaire, ou Master en finance'},
  {value: 'Autre formation en finance, précisez:'},
  {value: 'Non, je n’ai jamais étudié dans le domaine financier'},
]

const workInFinance = [
  {value: 'Oui, actuellement'},
  {value: 'Oui, par le passé'},
  {value: 'Non'},
]

const investments = [
  {value: 'SCPI (Société Civile de Placement Immobilier)'},
  {value: 'OPCI (Organisme de Placement Collectif Immobilier)'},
  {value: 'Private Equity Immobilier (FPS, SLP, SICAR, FPCI,...)'},
  {value: 'OPPCI (Organisme Professionnel de Placement Collectif Immobilier)'},
  {value: 'Unité de compte immobilière dans un contrat d’assurance vie'},
  {value: 'Autres produits (Précisez)'},
  {value: 'Aucun'},
];

const investmentsSupports = [
  { value: 'Actions côtées' },
  { value: 'Actions non cotées' },
  { value: 'Obligations cotées' },
  { value: 'Obligations non cotées' },
  { value: 'Produits structurés' },
  { value: 'Options, dérivés' },
  { value: 'CFD (Contracts For Difference)' },
  { value: 'ETP (Exchange Traded Products)' },
  { value: 'Crypto Monnaies' },
  { value: 'Aucun' },
];

const within = [
  {value: "Très court terme (inférieur à 1 an)"},
  {value: "Court terme (entre 1 et 3 ans)"},
  {value: "Moyen terme (entre 3 et 5 ans)"},
  {value: "Long terme (supérieur à 5 ans)"},
];

const goals = [
  {value: "Constituer un capital"},
  {value: "Percevoir des revenus complémentaires"},
  {value: "Valoriser un capital"},
  {value: "Optimiser ma fiscalité"},
  {value: "Diversifier mes actifs"},
  {value: "Préparer ma retraite"},
  {value: "Transmettre mon capital"},
];

const amountOfTransactions = [
  {value: "Moins de 5"},
  {value: "Entre 5 et 10"},
  {value: "Plus de 10 opérations"},
];

const risks = [
	{value: "Vous ne pouvez financièrement supporter aucune perte"},
	{value: "Vous pouvez supporter des pertes financières limitées (moins de 10%)"},
	{value: "Vous pouvez supporter des pertes financières significatives (entre 10% et 25%)"},
	{value: "Vous pouvez supporter des pertes financières très significatives (entre 25% et 50%)"},
	{value: "Vous pouvez supporter des pertes financières jusqu’à concurrence du montant investi"},
	{value: "Vous pouvez supporter des pertes financières au-delà du montant investi"},
];

const walletTypes = [
  { value: 'Inférieure à 100.000 euros'},
  { value: 'Entre 100.000 et 200.000 euros' },
  { value: 'Entre 200.000 et 500.000 euros' },
  { value: 'Supérieure à 500.000 euros' }
]

const engagementType = [
  {value: 'Emprunt immoblier'},
  {value: 'Emprunt consommation'},
  {value: 'Autre emprunt'},
];

const sourceOfFunds = [
  {value: 'Salaire'},
  {value: 'Épargne'},
  {value: 'Intérêts ou dividendes'},
  {value: 'Gain aux jeux'},
  {value: 'Indemnisation'},
  {value: 'Héritage'},
  {value: "Vente d'actifs"},
  {value: 'Rachat de contrat'},
  {value: 'Donation'},
  {value: 'Crédit'},
  {value: "Remploi d'actifs financiers"},
  {value: "Remploi d'actifs immobiliers"},
  {value: 'Autre'},
];

const businessTypes = [
  {label: 'SA', value: 'SA'},
  {label: 'SAS', value: 'SAS'},
  {label: 'SASU', value: 'SASU'},
  {label: 'SARL', value: 'SARL'},
  {label: 'SCI', value: 'SCI'},
  {label: 'SC', value: 'SC'},
  {label: 'ME (Micro-Entreprise)', value: 'ME'},
  {label: 'EURL', value: 'EURL'},
  {label: 'SNC', value: 'SNC'},
  {label: 'Autre', value: 'Autre'},
]

const userFunction = [
  {value: 'Président'},
  {value: 'Directeur général'},
  {value: 'Directeur général délégué'},
  {value: 'Gérant'},
  {value: 'Autre'},
]

const environmentPercentage = [
	{value: '>5%'},
	{value: '>25%'},
	{value: '>50%'},
	{value: 'Aucun'},
]

export {
  maritalStatuses, workCategories, pacsRegime, diplomas, workInFinance,
  ppeFunction, marriedRegime, investments, investmentsSupports,
  within, goals, engagementType, amountOfTransactions,
  sourceOfFunds, risks, businessTypes, userFunction, walletTypes, environmentPercentage
};
