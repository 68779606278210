import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddHomeIcon from '@mui/icons-material/AddHome';
import TextsmsIcon from '@mui/icons-material/Textsms';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import authenticationStore from "../../stores/AuthenticationStore.ts";
import CompanyNetwork from "../../config/Network/CompanyNetwork.ts";
import ProductNework from "../../config/Network/ProductNework.ts";
import UserTransactionNetwork from "../../config/Network/UserTransactionNetwork.ts";

import DashboardCard from "../../components/card/DashboardCard";
import ValidateIdentityBanner from "../../components/Banner/ValidateIdentityBanner";
import BannerLoader from "../../components/Loaders/Banner";
import ContactBlock from "../../components/Contact";
import OpportunitiesSlider from "../../components/Sliders/Slider";
import OnBoarding from "../../components/OnBoarding";
import RenderTable from "../../components/User/renderTable";
import renderChart from "../../components/User/chart";
import OSButton from "../../components/Button";
import OpportunityCardLoader from "../../components/Loaders/Opportunities/OpportunityCardLoader";
import LargeOpportunityCard from "../../components/Product/OpportunityCard/large";

import Images from "../../assets/images";

import './style.scss';

const cardLinks = [
  {id: 1, title: "Découvrir les produits", content: "Découvrez notre large gamme de produits diversifiés, sélectionnés par nos experts.", buttonText: "Découvrir les produits", buttonLink: "/opportunites", icon: <AddHomeIcon />},
  {id: 2, title: "Demander une démonstration", content: "Découvrez notre plateforme avec un conseiller Openstone", buttonText: "Prendre rendez-vous", buttonLink: "https://meetings-eu1.hubspot.com/charles-antoine-lamoureux/openstone", icon: <CalendarTodayIcon />},
  {id: 3, title: "Besoin d'aide ?", content: "L’équipe d’Openstone vous accompagne pour tout besoin / aide", buttonText: "Nous contacter", buttonLink: "mailto:bonjour@openstone.com", icon: <TextsmsIcon />},
]
const Dashboard = () => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [user, setUser] = useState('');
  const [object, setObject] = useState('');
  const [loading, setLoading] = useState(true);
  const [opportunities, setOpportunities] = useState([]);
  const [loadingOpportunities, setLoadingOpportunities] = useState(true);
  const [myTransactions, setMyTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [isShowingCalendly, setIsShowingCalendly] = useState(false);
  const [refresh, setRefresh] = useState(false);

  async function fetchUser(profile) {
    await authenticationStore.loadUserData().then(response => {
      setObject(response);
      setUser(response);
      setLoading(false);
    });
  }

  async function fetchCompany() {
    await CompanyNetwork.getCompany(company.id).then(response => {
      setObject(response);
      setLoading(false);
    });
  }

  async function fetchData() {
    const apiProducts = await ProductNework.getProducts();
    setOpportunities(apiProducts);
    setTimeout(() => {
      setLoadingOpportunities(false);
    }, 200);
  }

  async function fetchMyTransactions() {
    const apiTransactions = await UserTransactionNetwork.myTransactions(defaultProfile);
    setMyTransactions(apiTransactions);
    setTimeout(() => {
      setLoadingTransactions(false);
    }, 200)
  }

  useEffect(() => {
    if (defaultProfile) {
      fetchUser().then(() =>
          fetchMyTransactions(),
        fetchData()
      )
    } else {
      fetchCompany().then(() =>
          fetchMyTransactions(),
        fetchData()
      )
    }
  }, [defaultProfile]);

  useEffect(() => {
    !loading && object.phone_number === null && window.location.replace('/sign-up/verify');
  }, [object])

  useEffect(() => {
    refresh && fetchData()
  }, [refresh])

  const toggle = type => {
    if (type === 'calendly') {
      setIsShowingCalendly(!isShowingCalendly);
    }
  }
	
	const renderLargeOpportunity = () => {
		if (loading) {
			return (
				<div className="opportunity-loading-card" key="loader-opportunity-1">
					<OpportunityCardLoader />
				</div>
			);
		} else if (!loading && opportunities.length > 0) {
			const openOpportunity = opportunities.find(opportunity => opportunity.position === 'top');
			if (openOpportunity) {
				return (
					<div className="dashboard-bottom-container">
						<div className="os-flex dashboard-slider-content">
							<div>
								<h4 className="opportunities-title opportunities-title-top os-h4">Openstone Prime</h4>
								<p className="opportunities-content">Pour la première fois, les meilleurs gérants immobiliers au monde réunis dans un même fonds</p>
							</div>
						</div>
						<LargeOpportunityCard category={openOpportunity} opportunity={openOpportunity} needRefresh={setRefresh} />
					</div>
				)
			}
		}
		return null;
	}

  const renderOpportunities = () => {
    if (!loadingOpportunities && opportunities.length > 0) {
			const nonTopOpportunities = opportunities.filter(o => o.position !== 'top');
			const sortedOpportunities = nonTopOpportunities.sort((a, b) => b.is_os - a.is_os)
      return <OpportunitiesSlider list={sortedOpportunities} needRefresh={setRefresh}/>
    } else if (loadingOpportunities || opportunities.length === 0) {
      return <BannerLoader />
    }
  }

  return (
    <div className="dashboard-page">
      <Helmet>
        <title>Openstone | Portefeuille</title>
      </Helmet>
      {(object && (defaultProfile ? object.kyc_verification_status !== 'verified' : object.kyc_status !== "verified")) && <ValidateIdentityBanner object={object} page="dashboard" defaultProfile={defaultProfile}/>}
      <div className="dashboard-container">
        <div className="dashboard-top-container" style={{flexWrap: "wrap"}}>
          <p className="os-subtitle1 dashboard-top-container-title">Besoin d'aide ? Voici quelques étapes pour vous aider !</p>
          <div className="os-flex gap-32 dashboard-cards">
            {cardLinks.map(cl =>
              <DashboardCard
                title={cl.title}
                content={cl.content}
                button={cl.buttonText}
                link={cl.buttonLink}
                logo={cl.icon}
                first={cl.id === 1}
              />
            )}
          </div>
        </div>
        {(!loading && defaultProfile) && <OnBoarding user={user}/>}
        {object?.fund_shares?.total !== 0 && (
          <>
            <div className="opportunities-title-container">
              <h4 className="opportunities-title os-h4">Synthèse de vos investissements</h4>
              <p className="opportunities-content">Retrouvez le détail de vos opérations en cours et terminées.</p>
            </div>
            <div className="dashboard-top-container user-datas-container">
              {RenderTable(loadingTransactions, myTransactions, 'default', null, null, 'default')}
              {renderChart(loading, object, 'default')}
            </div>
          </>
        )}
				{renderLargeOpportunity()}
        <div className="dashboard-bottom-container">
          <div className="os-flex dashboard-slider-content">
            <div>
							<h4 className="opportunities-title opportunities-title-opportunities os-h4">Fonds en direct</h4>
							<p className="opportunities-content">Découvrez notre large gamme de produits diversifiés, sélectionnés par nos experts.</p>
            </div>
            <div className="opportunities-slider-btn joyride1">
              <OSButton size="large" variant="no_border" title="Voir toutes les opportunités" link="/opportunites" icon={<RemoveRedEyeIcon />}/>
            </div>
          </div>
          {renderOpportunities()}
        </div>
        <ContactBlock toggle={toggle} image={Images.charles} name={"Charles-Antoine d'Openstone"} text={"Notre objectif chez Openstone est de vous aider à constituer un partrimoine diversifié"} mail={"bonjour@openstone.com"} number={"01 59 06 80 50"}/>
      </div>
    </div>
  )
};

export default Dashboard;
