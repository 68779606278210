import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {toast} from "react-toastify";
import UploadFileIcon from '@mui/icons-material/UploadFile';

import UserJumioScanNetwork from "../../config/Network/UserJumioScanNetwork.ts";
import {CircularProgress} from "@mui/material";
import OSButton from "../Button";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'dashed',
  backgroundColor: '#F8FBFC',
  color: '#C4CDD5',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};
const focusedStyle = { borderColor: '#2196f3'};
const acceptStyle = {borderColor: '#00e676'};
const rejectStyle = {borderColor: '#C4CDD5'};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 2,
  border: '1px solid #C4CDD5',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  minHeight: 200,
  maxHeight: 310,
  padding: 4,
  boxSizing: 'border-box',
  gap: 16,
  alignItems: 'center'
};
const thumbInner = {
  display: 'flex',
  justifyContent: 'spaceBetween',
  overflow: 'hidden'
};
const img = {
  display: 'block',
  width: 'auto',
  maxWidth: '100%',
  height: '100%',
};

function ImageUpload(props) {
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setFiles(previousFiles => [
        ...previousFiles,
        ...acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      ])
      setFilesToUpload(previousFiles => [
        ...previousFiles,
        ...acceptedFiles.map(file => Object.assign(file))
      ])
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles:2,
    accept: {'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.heic', '.pdf']},
    onDrop: onDrop
  });

  const removeFile = fileIndex => {
    setFiles(files => {
      const newFiles = [...files];
      newFiles.splice(fileIndex, 1);
      return newFiles;
    });

    setFilesToUpload(filesToUpload => {
      const newFilesToUpload = [...filesToUpload];
      newFilesToUpload.splice(fileIndex, 1);
      return newFilesToUpload;
    });
  };

  useEffect(() => {
    props.userSurveyCheck && props.userSurveyCheck(filesToUpload.length);
  }, [filesToUpload])

  useEffect(() => {
    props.upload && upload();
  }, [props.upload])

  const thumbs = files.map(file =>
    file.type === 'application/pdf' ? (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <iframe title={file.name} src={file.preview} className="document-frame" frameBorder="0"/>
        </div>
        <span style={{fontSize: 11}}>{file.name}</span>
        <OSButton size="medium" onClick={() => removeFile(file)} title="Supprimer" variant="secondary" fit />
      </div>
    ) : (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            alt=""
          />
        </div>
        <span>{file.name}</span>
        <OSButton size="medium" onClick={() => removeFile(file)} title="Supprimer" variant="secondary" fit />
      </div>
    )
  );

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const upload = async () => {
    props.setSubmitting(true);
    props.type !== 'identity' && props.setSubmitting(true);
    let resp;
    if (props.type === 'companyUpload') {
      resp = await UserJumioScanNetwork.companyUploadDocuments(filesToUpload, props.documentType, props.record.id)
    } else if (props.type === 'cgpUpload') {
      resp = await UserJumioScanNetwork.cgpUploadDocuments(filesToUpload, props.documentType, props.record.id)
    } else {
      resp = await UserJumioScanNetwork.uploadDocuments(filesToUpload, props.documentType, props.record.id)
    }
    if (resp && resp.status === 200) {
      toast.success("Nous avons bien reçu vos documents", {
        toastId: 'UploadSuccessToast'
      });
      props.type !== 'identity' && props.setSubmitting(false);
      props.func && props.func(true);
    }
  }

  function renderButton() {
    if (props.submitting) {
      return <button disabled={true} className="submit-btn" onClick={() => upload()}>
        <CircularProgress color="inherit" />
        Documents en cours d'envoi
      </button>
    } else {
      return <button disabled={filesToUpload.length < 1} className="submit-btn" onClick={() => upload()}>{filesToUpload.length > 1 ? 'Envoyer les documents' : 'Envoyer le document'}</button>
    }
  }

  return (
    <>
      <div className="upload-container">
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <UploadFileIcon  style={{width: 50, height:50}} />
          <p className="upload-title">Glissez ou sélectionnez votre document</p>
          <p className="os-body2 upload-content">Vous pouvez cliquer pour ouvrir l'explorateur de fichiers</p>
        </div>
        {filesToUpload.length < 2 && <span className="nb-upload-error">
          Si vous déposez un recto/verso, vous devez sélectionner les deux fichiers en même temps.
        </span>}
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>
        <div className="os-flex upload-files">
          {props.type === 'identity' && renderButton()}
        </div>
      </div>
    </>
  );
}

export default ImageUpload;
