import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// import required modules
// import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import OpportunityCard from "../../Product/OpportunityCard";

import "./style.scss";


const OpportunitiesSlider = ({list, needRefresh}) => {
  const [swiper, setSwiper] = React.useState(null);

  const nexto = () => { swiper.slideNext() };
  const prevTo = () => { swiper.slidePrev() };

  return (
    <>
      <Swiper
        slidesPerView={1}
        breakpoints={{
          600: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={16}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        onSwiper={(s) => {
          setSwiper(s);
        }}
      >
        {list.map(item =>
          <SwiperSlide key={item.name}>
            <OpportunityCard page="dashboard" opportunity={item} key={`${item.name}-${item.id}`} needRefresh={needRefresh} slider/>
          </SwiperSlide>
        )}
        {list.length > 2 && (
          <div className="opportunities-title-arrows">
            <div className="swiper-button-prev" onClick={prevTo}/>
            <div className="swiper-button-next" onClick={nexto} />
          </div>
        )}
      </Swiper>
    </>
  );
}

export default OpportunitiesSlider;
