import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const OsCheckbox = ({ formik, name, value, label }) => {
  // Handle checkbox change
  const handleChange = (event) => {
    // Ensure formik.values[name] is treated as an array
    const currentValues = Array.isArray(formik.values[name]) ? formik.values[name] : [];
    const set = new Set(currentValues);

    if (event.target.checked) {
      set.add(value);
    } else {
      set.delete(value);
    }

    formik.setFieldValue(name, Array.from(set));
  };

  // Determine if the checkbox is checked
  const isChecked = Array.isArray(formik.values[name]) && formik.values[name].includes(value);

  const labelStyle = {
    backgroundColor: '#F2F5F8',
    padding: "12px 4px",
    borderRadius: 2,
    width: '100%',
    border: isChecked ? "1px solid #5A9496" : "1px solid #EAEDF1",
    boxSizing: "border-box",
    cursor: 'pointer',
	  marginLeft: '-4px',
	  marginRight: 0
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleChange}
            value={value}
            name={name}
            style={{ color: '#5A9496', cursor: 'pointer' }}
          />
        }
        label={label}
        style={labelStyle}
      />
    </>
  );
};

export default OsCheckbox;
