import React, {useState} from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OutboxIcon from '@mui/icons-material/Outbox';

import ImageUpload from "../../../components/ImageUpload";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import '../style.scss';

const choices = [
  {
    key: 'manual',
    title: 'Vérification manuelle',
    content: "Transmettre vos pièces d’identité",
    icon: <UploadFileIcon />
  },
  {
    key: 'email',
    title: 'Envoi par mail',
    content: "Envoyez-nous une copie de votre document par email",
    icon: <OutboxIcon />
  }
]

const KycContainer = ({user, func}) => {
  const [active, setActive] = useState('default');
  const [submitting, setSubmitting] = useState(false);
  const mailto = `mailto:hello@livretp.fr?subject=Document d'identité ${user?.first_name + ' ' + user?.last_name}&body=Bonjour, vous trouverez ci-joint mon document d'identité`;
  const checkActiveContent = () => {
    switch(active) {
      case('manual'):
        return <ImageUpload func={func} type="identity" submitting={submitting} setSubmitting={setSubmitting} documentType="identity" record={user}/>;
      default:
        return (
          <div className="choices-container">
            {choices.map(k => (
              <div className="white-cards" onClick={() => k.key !== 'email' && setActive(k.key)} key={k.key}>
                <h5 className="os-h5">{k.icon} {k.title}</h5>
                <p>{k.content} {k.key === 'email' && <a href={mailto} className="email-link">hello@livretp.fr</a>}</p>
              </div>
            ))}
          </div>
        );
    }
  }
  return (
    <div className="kyc-container">
      <p className="rollback-btn" onClick={() => active === 'manual' ? setActive('default') : func(false)}><ArrowBackIcon /> Étape précédente</p>
      <h4 className="os-h4">Validez votre document d'identité pour activer votre compte</h4>
      <p>(Passeport, carte d’identité, permis de conduire, permis de séjour)</p>
      {checkActiveContent()}
    </div>
  );
};

export default KycContainer;
