import React from 'react';

import {ReactComponent as PrimaryLogo} from "./logoWhite.svg";
import {ReactComponent as MobileLogo} from "./os_mobile_logo.svg";

import './style.scss';

const PublicNav = () => {
  return (
    <>
      <div className="public-nav">
        <>
          <PrimaryLogo className="os-logo" onClick={() => window.location.replace('/')}/>
          <MobileLogo className="os-logo-phone" onClick={() => window.location.replace('/')}/>
        </>
      </div>
    </>
  );
};

export default PublicNav;
