import React from 'react';
import { addAdditionalClasses } from "../../utils/utils";
import PropTypes from "prop-types";
import './style.scss';

/**
 * A simple Info component.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.children - The content to display inside the info.
 * @param {string} props.color - Whether to display the Info with a certain colored background.
 * @param {string} props.logo - Whether to display a logo inside.
 */

const Info = ({ children, color, logo }) => {
    const classNames = addAdditionalClasses('callout-livretp', [
    ]);

    return (
      <div className={classNames}>
          {logo && <div className="info-logo">{logo}</div>}
          <p className="os-caption">{children}</p>
      </div>
    );
};

Info.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
};

Info.defaultProps = {
    color: '#58D3DD',
};

export default Info;
