import React from 'react';
import ContentLoader from 'react-content-loader';

const NotificationsLoader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={72}
    backgroundColor="#dedede"
    foregroundColor="#cacaca">
    <rect x="12" y="70" rx="2" ry="2" width="100%" height="1" />
    <rect x="12" y="30" rx="2" ry="2" width="60%" height="14" />
    <rect x="350" y="30" rx="2" ry="2" width="20%" height="14" />
    <rect x="14" y="50" rx="2" ry="2" width="100%" height="6" />
  </ContentLoader>
);

export default NotificationsLoader;
