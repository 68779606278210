import React from 'react';
import Images from "../../../assets/images";
import {ReactComponent as ChatIcon} from "../../../pages/Opportunities/View/icons/ic_chat.svg";
import {ReactComponent as PhoneIcon} from "../../../pages/Opportunities/View/icons/ic_call.svg";
import OSButton from "../../Button";

/**
 * A simple Button component.
 *
 * @param {Object} props - The component's props.
 * @param {string} text - The text to display in the component.
 * @param {string} title - the title do display in the component.
 */

const ProductContact = ({text, title}) => {

  return (
    <div className="product-view-contact-us">
      <div className="product-view-contact-us-left">
        <img className="contact-user__img" src={Images.charles} alt="" />
        <div className="product-view-contact-us-content">
            <p className="os-subtitle1">{title}</p>
            <p className="os-body1">{text}</p>
        </div>
      </div>
      <div className="os-flex product-view-contact-us-btns">
        <button id="open_os_intercom" className="write-us-btn">Nous écrire <ChatIcon /></button>
        <OSButton title="Être rappelé(e)" fit variant="primary-warning" size="small" link="https://meetings-eu1.hubspot.com/charles-antoine-lamoureux/openstone" icon={<PhoneIcon/>} target/>
      </div>
    </div>
  );
};

export default ProductContact;
