import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";

import Phone from "../../components/Phone";
import PublicNav from "../../components/Navbar/publicNav";
import OsCard from "../../components/card/basic";
import Badge from "../../components/Badge/Badge";

import images from "../../assets/images";

import './style.scss';

const LoginLp = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const authToken = localStorage.getItem('UserAuthToken');
	const [redirectUrl, setRedirectUrl] = useState('/overview');
	
	useEffect(() => {
		authToken && navigate('/sign-up/verify')
	}, [authToken]);
	
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const url = searchParams.get('redirectUrl');
		
		url && setRedirectUrl(url);
	}, [location, navigate]);
	
	return (
		<>
			<Helmet>
				<title>Connexion | Livret P. by Openstone</title>
			</Helmet>
			<PublicNav />
			<div className="container">
				<div className="auth-container livretp-bg">
					<div className="os-flex logo-container">
						<img src={images.brand.LpLogo} className="auth-os-logo"/>
					</div>
					<div className="auth-phone-container">
						<div className="os-flex top-container" style={{marginBottom: 32}}>
							<div className="os-flex badges-page-container">
								<h4 className="os-h4 auth-title">Connexion</h4>
								<div style={{marginTop: 8}}>
									<Badge color="white">Espace particuliers</Badge>
								</div>
							</div>
						</div>
						<OsCard>
							<p className="os-subtitle2">Connectez-vous avec votre numéro de téléphone</p>
							<Phone user={null} page="auth" redirectUrl={redirectUrl}/>
						</OsCard>
					</div>
					<div className="labels">
						<img src={images.brand.labels} alt="Labels Openstone" />
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginLp;
