// @mui
import React from "react";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import {toast} from "react-toastify";
import Scrollbar from '../scrollbar';
import { TableHeadCustom } from './index.js';
import Badge from '../Badge/Badge';
import LaunchIcon from "@mui/icons-material/Launch";
import MoneyHelper from "../../Helpers/MoneyHelper";

import './myTable.scss';

// ----------------------------------------------------------------------
export default function CompaniesTable({ title, subheader, tableData, tableLabels, ...other }) {
  const company = JSON.parse(localStorage.getItem('Company'));
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row) => (company?.legal_name !== row.legal_name) && (
                <CompaniesTableRow key={`${row.business_type}-${row.legal_name}`} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

const checkRowStatus = element => {
  let status; let color;
  if (element.kyc_status === 'verified') {
    status = 'Vérifiée';
    color = 'error'
  } else if (element.kyc_status === 'error') {
    status = 'Erreur';
    color = 'error';
  } else if (element.kyc_status === 'pending') {
    status = 'En cours de vérification';
    color = 'warning';
  }
  return {status, color};
};

function changeProfile(company) {
  localStorage.setItem('defaultProfile', 'false');
  localStorage.setItem('company', JSON.stringify(company));
  toast.success(`Vous êtes désormais sous le compte de la société ${company.legal_name}`, {
    toastId: `switched-${company.id}`
  });
  window.location.reload();
}

// ----------------------------------------------------------------------

function CompaniesTableRow({ row }) {
  return (
    <>
      <TableRow>
        <TableCell>
          <div>
            {row.business_type}
          </div>
        </TableCell>
        <TableCell>
          <div>
            {row.legal_name}
          </div>
        </TableCell>
        <TableCell>
          <div>
            <Badge color={checkRowStatus(row).color}>{checkRowStatus(row).status}</Badge>
          </div>
        </TableCell>
        <TableCell>
          <div>
            {MoneyHelper.formatEuro(row.total_invested)}
          </div>
        </TableCell>
        <TableCell>
          <span className="change-company-btn" onClick={() => changeProfile(row)}>
             Gérer <LaunchIcon/>
          </span>
        </TableCell>
      </TableRow>
    </>
  );
}
