import React from 'react';

import './style.scss';

const SectionDetails = ({title, number, content}) => {
  return (
    <div className="section-details">
      <p className="os-body2 section-details__title">{title}:
        <span className="section-details__number">{' '} {number}</span>
      </p>
      <p className="os-body2 section-details__content">{content}</p>
    </div>
  );
};

export default SectionDetails;
