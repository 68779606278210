import React, {useEffect, useState} from 'react';
import {Step, StepLabel, Stepper} from "@mui/material";

import KycContainer from "./identity";
import Success from "./success";
import InstructionsContainer from "../../components/instructionsContainer";
import PersonnalInfos from "../KycSurvey/Steps/PersonnalInfos";

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";

import './style.scss';
import Banner from "../Dashboard/Banner";
import {Helmet} from "react-helmet";

const steps = [
  {
    title: 'Informations',
  },
  {
    title: "Vérification d'identité",
  }
];

const KYC = () => {
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    async function fetchUser() {
      const response = await AuthenticationNetwork.loadUserData();
      response && setUser(response);
    }
    fetchUser();
  }, []);

  useEffect(() => {
    user.kyc_verification_status === 'verified' && setActiveStep(2);
  }, [user])

  const checkSuccess = state => {
    state === false ? setActiveStep(activeStep - 1) : setActiveStep(activeStep + 1);
  }

  const AddressContainer = () => (
    <div className="address-container">
      <InstructionsContainer title={"Veuillez renseigner vos informations"} />
      <PersonnalInfos user={user} handleStep={checkSuccess} page="kyc"/>
    </div>
  );

  const checkStep = () => {
    switch (activeStep) {
      case 0:
        return <AddressContainer />;
      case 1:
        return <KycContainer user={user} func={checkSuccess} />;
      case 2:
        return <Success />
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <div className="kyc-container">
      <Helmet>
        <title>Openstone | KYC</title>
      </Helmet>
      <Stepper activeStep={activeStep} alternativeLabel className="stepper-kyc os-scroll-shadows">
        {steps.map(step => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="white-container">
        {checkStep()}
      </div>
    </div>
  );
};

export default KYC;
