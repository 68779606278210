import Network from './Network.ts';

class CompanyNetwork extends Network {
  async myCompanies() {
    return await this.get('/companies');
  }

  async createCompanies(params) {
    return await this.post('/companies', params);
  }

  async getCompany(id: number) {
    return await this.get(`/companies/${id}`);
  }

  async updateCompany(id, params) {
    return await this.put(`/companies/${id}`, params);
  }
}

export default new CompanyNetwork();
