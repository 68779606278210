import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import {toast} from "react-toastify";
import * as yup from 'yup';

import WarningIcon from '@mui/icons-material/Warning';
import Divider from "@mui/material/Divider";
import {Slider} from "@mui/material";

import {investments, investmentsSupports, within, goals, amountOfTransactions, risks, environmentPercentage} from "../choices";
import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import OsCheckbox from "../../../../components/Form/OsCheckbox";
import OsSelect from "../../../../components/Form/OsSelect";
import TextField from "@material-ui/core/TextField";
import OsTooltip from "../../../../components/Tooltips";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OSButton from "../../../../components/Button";

const Knowledge = ({user, handleStep, page, isCgp, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const knowledge = page === 'companyForm' ? company?.knowledge : user?.knowledge;
  const [durabilityScore, setDurabilityScore] = useState(knowledge?.durability_score || 1);

  const validationSchema = yup.object({
    knownInvestment: yup.array().min(1, 'Au moins un choix est requis'),
    operationsSupports: yup.array().min(1, 'Au moins un choix est requis'),
	  numberOfOperations: yup.string()
		  .when('operationsSupports', {
			  is: (operationsSupports) => !operationsSupports.includes('Aucun') && operationsSupports.length > 0,
			  then: yup.string().required("Veuillez renseigner le nomnbre d'opérations réalisées au cours de l'année.")
		  }),
    within: yup.string().required('Requis'),
    goals: yup.array().min(1, 'Au moins un choix est requis'),
    risks: yup.string().required('Requis'),
	  otherKnownInvestment: yup.string()
		  .when('knownInvestment', {
			  is: (knownInvestment) => knownInvestment.includes('Autres produits (Précisez)'),
			  then: yup.string().required('Veuillez renseigner au moins un autre produits que ceux figurant dans la liste')
		  }),
	  environmentPercentage: yup.string().required('Requis'),
	  durabilityPercentage: yup.string().required('Requis')
  });

  const formik = useFormik({
    initialValues: {
      knownInvestment: knowledge?.knowledge || [],
      operationsSupports: knowledge?.operations || [],
      numberOfOperations: knowledge?.nb_operations || '',
      within: knowledge?.horizon || '',
      goals: knowledge?.goals || [],
      risks: knowledge?.risks || '',
	    otherKnownInvestment: knowledge?.other_known_investment || '',
	    environmentPercentage: knowledge?.environment_percentage || '',
	    durabilityPercentage: knowledge?.durability_percentage || ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const datasToSubmit = {
        "knowledge": values.knownInvestment,
        "operations": values.operationsSupports,
        "nb_operations": values.numberOfOperations,
        "horizon": values.within,
        "goals": values.goals,
        "risks": values.risks,
	      "other_known_investment": values.otherKnownInvestment,
	      "environment_percentage": values.environmentPercentage,
	      "durability_percentage": values.durabilityPercentage,
	      "durability_score": durabilityScore,
      };
      async function handleRequest() {
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord({ knowledge: datasToSubmit}, company.id, 'Company') : await CgpNetwork.updateRecord({ knowledge: datasToSubmit}, user.id, 'User');
        } else {
          page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id, { knowledge: datasToSubmit}) : await AuthenticationNetwork.editUser({ knowledge: datasToSubmit});
        }
      }
      const response = handleRequest();
      if (response) {
        setSubmitting(false);
        toast.success('Vos connaissances et objectifs ont bien été enregistrés', {
          toastId: 'completeInfosSuccessToast'
        });
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });

  useEffect(() => {
    if (knowledge) {
      formik.setValues({
        knownInvestment: knowledge?.knowledge || [],
        operationsSupports: knowledge?.operations || [],
        numberOfOperations: knowledge?.nb_operations || '',
        within: knowledge?.horizon || '',
        goals: knowledge?.goals || [],
        risks: knowledge?.risks || '',
	      otherKnownInvestment: knowledge?.other_known_investment || '',
	      environmentPercentage: knowledge?.environment_percentage || '',
	      durabilityPercentage: knowledge?.durability_percentage || ''
      });
    }
  }, [knowledge]);
	
	const checkRisk = () => {
		if(formik.values.risks === 'Vous ne pouvez financièrement supporter aucune perte' || formik.values.risks === 'Vous pouvez supporter des pertes financières limitées (moins de 10%)') {
			return <p className="os-flex warning gap-8"><WarningIcon /> L’investissement comporte des risques de perte en capital</p>
		}
	}
	
	const checkHorizon = () => {
		if(formik.values.within !== 'Long terme (supérieur à 5 ans)' && formik.values.within !== '' && formik.values.within !== null) {
			return <p className="os-flex warning gap-8"><WarningIcon /> La durée d'investissement est supérieure à votre horizon de placement</p>
		}
	}

  return (
    <>
      <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
        <h6 className="os-h6 user-survey-question">
          Connaissances
          <span className="error"> {formik.touched.knownInvestment && formik.errors.knownInvestment}</span>
        </h6>
        <p className="user-survey-question">Avez-vous déjà investi sur les produits suivants liés à l’immobilier :</p>
        <div className="user-survey-questions-container">
          {investments.map(i => (
            <OsCheckbox
              key={`knownInvestment-${i.value}`}
              formik={formik}
              name="knownInvestment"
              value={i.value}
              label={i.value}
            />
          ))}
        </div>
	      {formik.values.knownInvestment.includes('Autres produits (Précisez)') && (
					<div>
			      <p className="user-survey-question">Autres produits (Précisez et séparez par des virgules entre chaque produit) :</p>
			      <div className="user-survey-questions-container">
				      <TextField
					      fullWidth
					      id="otherKnownInvestment"
					      name="otherKnownInvestment"
					      placeholder="Autres produits"
					      value={formik.values.otherKnownInvestment}
					      onChange={formik.handleChange}
					      error={formik.touched.otherKnownInvestment && Boolean(formik.errors.otherKnownInvestment)}
					      helperText={formik.touched.otherKnownInvestment && formik.errors.otherKnownInvestment}
					      variant={"outlined"}
					      className="by-1"
				      />
			      </div>
					</div>
	      )}
        <Divider />
	      
        <h6 className="os-h6 user-survey-question">Opérations</h6>
        <p className="user-survey-question">Avez-vous déjà investi sur les supports suivants :</p>
        <span className="error"> {formik.touched.operationsSupports && formik.errors.operationsSupports}</span>
        <div className="user-survey-questions-container">
          {investmentsSupports.map(i => (
            <OsCheckbox
              key={`operationsSupports-${i.value}`}
              formik={formik}
              name="operationsSupports"
              value={i.value}
              label={i.value}
            />
          ))}
        </div>
	      {(formik.values.operationsSupports.length > 0 && !formik.values.operationsSupports.includes('Aucun')) && (
					<>
		        <p className="user-survey-question">Si oui, combien de transactions avez-vous réalisé au cours de la dernière année :</p>
		        <span className="error"> {formik.touched.numberOfOperations && formik.errors.numberOfOperations}</span>
		        <div className="user-survey-questions-container">
		          <OsSelect
		              formik={formik}
		              name="numberOfOperations"
		              options={amountOfTransactions.sort().map(amount => ({
		                value: amount.value,
		                label: amount.value,
		              }))}
		          />
		        </div>
					</>
	      )}
        <Divider />
	      <h6 className="os-h6 user-survey-question">Objectifs
		      <span className="error"> {formik.touched.goals && formik.errors.goals}</span>
	      </h6>
	      <p className="user-survey-question">Quels sont vos objectifs prioritaires en matière de placement (plusieurs choix
		      possibles): </p>
	      <div className="user-survey-questions-container">
		      {goals.map(goal => (
			      <OsCheckbox
				      key={`goals-${goal.value}`}
				      formik={formik}
				      name="goals"
				      value={goal.value}
				      label={goal.value}
			      />
		      ))}
	      </div>
	      <Divider />
        <h6 className="os-h6 user-survey-question">Horizon d'investissement <span className="error">{formik.touched.within && formik.errors.within}</span></h6>
        <p className="user-survey-question">Pour ces objectifs d’investissement, quel est votre horizon de
	        placement le plus long:</p>
        <div className="user-survey-questions-container">
          <OsSelect
              formik={formik}
              name="within"
              options={within.sort().map(choice => ({
                value: choice.value,
                label: choice.value,
              }))}
          />
        </div>
	      {checkHorizon()}
        <Divider />
        <h6 className="os-h6 user-survey-question">Risques
          <span className="error"> {formik.touched.risks && formik.errors.risks}</span>
        </h6>
        <p className="user-survey-question">Compte tenu de vos revenus et de votre situation patrimoniale, quel niveau de
	        pertes pouvez-vous supporter financièrement:</p>
        <div className="user-survey-questions-container">
          <OsSelect
              formik={formik}
              name="risks"
              options={risks.sort().map(risk => ({
                value: risk.value,
                label: risk.value,
              }))}
          />
        </div>
        {checkRisk()}
	      <Divider />
	      <h6 className="os-h6 user-survey-question">Durabilité & ESG
		      <span className="error"> {formik.touched.risks && formik.errors.risks}</span>
	      </h6>
	      <p className="user-survey-question">Sur une échelle de 1 à 10, où 1 représente le moins important et 10 le plus
		      important.<br /><br /> Comment évalueriez-vous l'importance des critères environnementaux, sociaux et de
		      gouvernance (ESG) dans vos décisions d'investissement:</p>
	      <p style={{textAlign: 'right'}}>{durabilityScore}/10</p>
	      <Slider defaultValue={durabilityScore} min={1} max={10} marks aria-label="Default" valueLabelDisplay="auto"  onChange={(e, val) => setDurabilityScore(val)}/>
	      <p className="user-survey-question" style={{marginTop: 40}}>Quel pourcentage de votre investissement souhaitez-vous consacrer dans des activités
		      considérées comme durables sur le plan environnemental par la classification européenne appelée « Taxonomie »:
				</p>
	      <div className="user-survey-questions-container">
		      <OsSelect
			      formik={formik}
			      name="environmentPercentage"
			      options={environmentPercentage.sort().map(e => ({
				      value: e.value,
				      label: e.value,
			      }))}
		      />
		      <OsTooltip title={<Typography color="inherit">
			      *Actuellement, peu de produits financiers ESG ont une part significative d’investissements durables alignée à la Taxonomie tels
			      que définis par la réglementation européenne. Ainsi, si votre souhait est de sélectionner les produits financiers selon ce critère,
			      il sera difficile de vous apporter satisfaction.
			      Néanmoins, l’amélioration de l’offre dans le futur et l’intérêt de Openstone pour ces thèmes importants, à travers la sélection
			      des instruments financiers, permettra de se rapprocher progressivement de vos souhaits.
		      </Typography>}>
			      <div className="tooltip-btn"><InfoOutlinedIcon /></div>
		      </OsTooltip>
	      </div>
	      
	      <p className="user-survey-question">Quel pourcentage de votre investissement souhaitez-vous consacrer dans des «
		      investissements durables » au sens du Règlement européen sur la publication d'informations en matière de durabilité dans le secteur des services financiers:
	      </p>
	      <div className="user-survey-questions-container">
		      <OsSelect
			      formik={formik}
			      name="durabilityPercentage"
			      options={environmentPercentage.sort().map(e => ({
				      value: e.value,
				      label: e.value,
			      }))}
		      />
	      </div>
        <div className="user-survey-btn-container">
	        <OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium"/>
          <button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
        </div>
	      <div className="user-survey-btn-container">
          {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
	      </div>
      </form>
    </>
  );
};

export default Knowledge;
