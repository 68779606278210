import React from "react"
import ContentLoader from "react-content-loader"

const YourReferralsLoader = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={80}
        backgroundColor="#dedede"
        foregroundColor="#cacaca">
        <circle cx="20" cy="35" r="15" />
        <rect x="53" y="31" rx="2" ry="2" width="140" height="10" />
        <rect x="53" y="56" rx="2" ry="2" width="275" height="8" />
    </ContentLoader>
);

export default YourReferralsLoader;
