import React, {useEffect, useState} from 'react';

import authenticationStore from "../../../stores/AuthenticationStore.ts";

import OSButton from "../../../components/Button";
import OsCard from "../../../components/card/basic";

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Images from "../../../assets/images";

import './style.scss';

const ressourceLinks = [
    {id: 1, image: Images.cgp.ressources.first, title: "Maîtrisez Openstone : Le Guide Essentiel pour les CGP", content: "Ce guide vous fournira toutes les informations nécessaires pour naviguer facilement sur la plateforme Openstone et tirer le meilleur parti de ses fonctionnalités pour optimiser la gestion de vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/maitrisez-openstone-le-guide-essentiel-pour-les-cgp"},
    {id: 2, image: Images.cgp.ressources.second,title: "Openstone : Votre Portail Vers la Gestion Client Simplifié", content: "Des fonds ultra-performants, généralement ouverts aux investisseurs institutionnels, sont désormais accessibles dès 10 000 euros pour vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/openstone-votre-portail-vers-la-gestion-client-simplifie"},
]
const CgpVerification = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  async function fetchUser() {
    await authenticationStore.loadCgpUserData().then(response => {
      setUser(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchUser();
  }, [refresh]);

  useEffect(() => {
    if (!loading && (user.has_uploaded && user?.kyc_verification_status === "verified")) {
      return window.location.replace('/cgp/dashboard');
    } else if (!loading && !user.has_uploaded && user.kyc_verification_status !== "verified") {
      return window.location.replace('/sign-up/cgp/documents');
    }
  }, [user]);

  return (
    <div className="os-flex__column auth-container verification-page">
      <VerifiedUserIcon />
      <h3 className="os-h3">Merci pour votre inscription</h3>
      <p className="os-body1 verification-content" style={{marginBottom: 30, textAlign: "center"}}>Votre compte est actuellement en cours de validation par nos équipes. Vous serez contacté très prochainement. Une fois validé, vous aurez un accès direct à votre espace CGP Openstone.</p>
      <OSButton title="Rafraichir la page" onClick={() => setRefresh(!refresh)} variant="primary-full" fit={true} size="large" />
      <div style={{marginTop: 24}}>
        <div className="os-flex gap-32 ressources-signup">
          {ressourceLinks.map((cl, index) =>
            <OsCard>
              <img className="ressources-img" src={cl.image} alt=""/>
              <p className="os-caption">{cl.content}</p>
              <div className="os-flex" style={{justifyContent: "flex-end"}}>
                <OSButton title={cl.buttonText} link={cl.buttonLink} fit size="small" variant="no_border" />
              </div>
            </OsCard>
          )}
        </div>
      </div>
    </div>
  );
};

export default CgpVerification;
