import Network from './Network.ts';

class CartNetwork extends Network {
  async initialiseTransaction(amount: number) {
    return await this.post('/lyra/create_paiement_order', {
      amount: amount.toString(),
    });
  }

  async initialiseFormToken(amount: number, page: string, productId: number, transactionId: number, forType: string) {
    return await this.post('/lyra/create_form_token', {
      amount: amount.toString(),
      page: page,
      product_id: productId,
      transaction_id: transactionId,
      for_type: forType
    });
  }
}

export default new CartNetwork();
