import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import ScrollToTop from "../../utils/scrollToTop";
import CloseIcon from '@mui/icons-material/Close';

import MobileNav from "../../components/Navbar/Mobile";
import MiniDrawer from "../../components/Navbar/Drawer";
import OSButton from "../../components/Button";

import {desktopLinks, generalLinks, mobileLinks} from "./Links/links";

import './style.scss';
import Profile from "../../components/Navbar/Profile";

const ProtectedRoute = ({children}) => {
  const authToken = localStorage.getItem('UserAuthToken');
  const cgpAuthToken = localStorage.getItem('CgpUserAuthToken');
  const product = JSON.parse(localStorage.getItem('product'));
  const productUrl = localStorage.getItem('productUrl');
  const currentPath = window.location.href;
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const customCloseToast = (close) => {
    localStorage.removeItem('product');
    localStorage.removeItem('productUrl');
    close();
  };

  const Msg = ({closeToast}) => (
    <>
      <div className="product-finalise-container">
        {/*<img className="product-finalise-img" src={product.cover_picture?.medium} alt=""/>*/}
        <div>
          <p><strong>{product.name}</strong>
            <br></br>
            Finalisez votre souscription
          </p>
          <OSButton variant="primary" size="small" title="Finaliser" link={productUrl} />
        </div>
      </div>
      <CloseIcon onClick={() => customCloseToast(closeToast)} className="product-finalise-close"/>
    </>
  );

  useEffect(() => {
    if (productUrl && product && (!window.location.href.includes('finalise') && !currentPath.includes('deposit')) && !isMobile) {
      toast(<Msg />, {
        position: "top-right",
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0.7,
        theme: "light",
        toastId: `productFinalise`,
      });
      localStorage.removeItem('promoCode');
    }
    if (!currentPath.includes('withdraw')) {
      localStorage.removeItem('@iban');
      localStorage.removeItem('@bic');
      localStorage.removeItem('@amount');
    }
  }, [currentPath, productUrl, product])

  useEffect(() => {
    if (!authToken || authToken === 'undefined') {
      return window.location.replace('/');
    } else {
			localStorage.removeItem('CgpUserAuthToken');
		}
  }, [authToken, cgpAuthToken, currentPath]);

  return (
    <>
      <ScrollToTop />
      <div className="layout-container">
        <div className="drawer">
          <MiniDrawer links={desktopLinks} generalLinks={generalLinks}/>
        </div>
        <div className="page-container">
          {children}
          <div className="mobile-nav">
            <MobileNav links={mobileLinks}/>
          </div>
        </div>
          {currentPath.endsWith('/more') && (
              <div className="profile-switch-container">
                  <Profile/>
              </div>
          )}
      </div>
    </>
  )
};

export default ProtectedRoute;
