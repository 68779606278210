import React from 'react';
import {ReactComponent as ChevronRight} from "../../pages/Opportunities/View/icons/ic_chevron_right.svg";
import CheckIcon from '@mui/icons-material/Check';
import OSButton from "../Button";
import DescriptionIcon from '@mui/icons-material/Description';

import './style.scss';

const Pdf = ({page, document, func, relatedDocuments, canClick, updateInfoMsg, cgp, cgpAction, submitting}) => {
  const checkPage = () => {
    if (page === 'opportunity') {
      return (
        <a className="product-document" href={document.attachment.url} target="_blank" rel="noreferrer">
	        <div className="pdf-logo-container">
	          <DescriptionIcon />
	        </div>
	        <div className="os-flex product-document-name-container">
	          <p className="product-document-name os-body2">{document.name}</p>
	          <ChevronRight/>
	        </div>
        </a>
      )
    } else if (page === 'myDocuments') {
        return (
          <a className="product-document" href={document?.file?.url} target="_blank" rel="noreferrer">
	          <div className="pdf-logo-container">
		          <DescriptionIcon />
	          </div>
	          <div className="os-flex product-document-name-container">
		          <div>
                      <p className="product-document-name os-body2">{document.title}</p>
                      <span className={document.status === 'signed' ? "success" : "warning"}>{document.status === "signed" ? `Signé le ${document.signed_at}` : "Signature en attente"}</span>
		          </div>
              <ChevronRight />
            </div>
          </a>
        )
    } else {
      function checkCanClick(rd) {
        if (canClick) {
          updateInfoMsg(false);
          func(rd.type, rd.title);
        } else {
          updateInfoMsg(true);
        }
      }

      return (
        <>
          <p className="os-subtitle1">Documents à signer</p>
          <p className="os-body2">{cgp ? "Afin de valider la souscription le client doit signer les documents suivant (à noter que seul les documents non-signés sont envoyés pour signature)" : "Pour pouvoir passer à l'étape suivante, veuillez vérifier et signer l'ensemble des documents ci-dessous."}</p>
          <div className="os-flex__column gap-16">
		        {relatedDocuments.map(rd =>
	            <div className="finalise-product-document" key={rd.title}>
	              <div className="os-flex gap-8" style={{alignItems: 'center'}}>
		              <div className="pdf-logo-container">
			              <DescriptionIcon />
		              </div>
		              <div className="os-flex product-document-name-container">
                          <div>
                              <p className="finalise-product-document-name os-body2">{rd.title}</p>
                              <span className="os-caption warning">
                                {rd.type === 'sepa_mandate' && 'Le RUM (Référence Unique du Mandat) est renseigné au moment de la signature'}
                              </span>
                          </div>
	                </div>
	              </div>
	              {rd.signed ?
	                (
	                  <div className="finalise-product-document-signed-container">
	                    <p className="finalise-product-document-signed os-body2">
	                      <CheckIcon /> Signé
	                    </p>
	                    <OSButton size="medium" variant="primary-full" onClick={() => checkCanClick(rd)} title="Consulter" fit />
	                  </div>
	                ) : cgp ? <OSButton size="medium" variant="primary-full" onClick={() => checkCanClick(rd)} title="Consulter" fit  /> : <OSButton size="medium" variant="primary" onClick={() => checkCanClick(rd)} title="Vérifier et signer" fit />
	              }
	            </div>
	          )}
          </div>
          {cgp && (
            <div className="os-flex" style={{justifyContent: 'center', marginTop: 16}}>
              <OSButton size="large" variant="primary" onClick={() => cgpAction()} title="Envoyer pour signature client" fit submitting={submitting}/>
            </div>
          )}
        </>
      )
    }
  };

  return checkPage();
};

export default Pdf;
