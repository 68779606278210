import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";

import StepsContainer from "../StepsContainer";
import ChoosePaymentType from "../../../../../components/ChoosePaimentType";
import Callback from "../../../../livretp/Deposit/Payment/BankPayment/callback";
import Card from "../../../../livretp/Deposit/Card";
import LpTransfer from "../../../../LpTransfer";

const Paiement = ({product, step, rollback, object, minimumInvestment}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paiementType, setPaiementType] = useState('');

  useEffect(() => {
    searchParams.get('paiementType') === 'card' && setPaiementType('card');
    searchParams.get('paiementType') === 'livretp' && setPaiementType('livretp');
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });

  function checkPaymentStep() {
    if (searchParams.get('status')) {
      return (
        <StepsContainer children={
          <div className="deposit-container">
            <Callback />
          </div>
        } product={product} />
      )
    } else if (paiementType === 'card') {
      return <Card product={product} />
    } else if (paiementType === 'livretp') {
      return <LpTransfer product={product} object={object}/>
    } else {
      return <ChoosePaymentType page={product.category_name} product={product} object={object}/>
    }
  }
  const checkStep = () => {
    let component;
    if (!step) {
      component = <StepsContainer children={checkPaymentStep()} product={product} />
    } else {
      component = <StepsContainer children={<Callback />} product={product} />
    }
    return component;
  }
  return checkStep();
};

export default Paiement;
