import React from 'react';

import InstructionsContainer from "../instructionsContainer";
import EmptyContent from "../empty-content/EmptyContent";
import {ReactComponent as NoData} from "../../assets/Illustrations/businessman-card.svg";

import './style.scss';

const user = {title: "Munissez-vous d'un document d'identité pour continuer", content: "Les pièces acceptables sont : passeport, titre de séjour, carte nationale d'identité ou permis de conduire", link: "/kyc"};
const company = {title: "Compléter votre société afin de continuer", content: "Veuillez compléter le questionnaire relatif à votre société", link: "/company/edit"};

const AccountVerification = ({profile}) => (
  <div className="modal-identity">
    <div className="modal-identity__img">
      <EmptyContent illustration={<NoData />} />
    </div>
    <div>
      <InstructionsContainer title={profile === 'user' ? user.title : company.title} content={profile === 'user' ? user.content : company.content} />
      <div className="modal-identity__buttons">
        <a href={profile === 'user' ? user.link : company.link}  className="blue-button">Continuer</a>
        <a href="/overview" className="cancel-button">Pas pour l'instant</a>
      </div>
    </div>
  </div>
);

export default AccountVerification;
