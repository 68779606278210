import React from 'react';

import Documents from "./Documents";
import BsTransactions from "./BsTransactions";
import BrTransactions from "./BrTransactions";

import './style.scss';

const MyDocuments = () => {
  return (
    <div className="my-documents-container">
      <Documents page="preview" />
	    {/*<BsTransactions page="preview" />*/}
	    {/*<BrTransactions page="preview" />*/}
    </div>
  );
};

export default MyDocuments;
