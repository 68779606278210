import React from 'react';
import PropTypes from 'prop-types';

import './Badge.scss';

import { addAdditionalClasses } from '../../utils/utils';

/**
 * A simple chip component.
 *
 * @param {string} children - The content to display inside the chip.
 * @param {boolean} color - Wich color the badge is.
 */
const Badge = ({ children, color}) => {
  const classNames = addAdditionalClasses('os-badge', [
    color && `os-badge__${color}`
  ]);

  return <div className={classNames}>{children}</div>;
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string
};

Badge.defaultProps = {
  colored: 'success',
};

export default Badge;
