import React from 'react';
import OSButton from "../../Button";

import './style.scss';


/**
 * A simple Button component.
 *
 * @param {string} link - The link to display in the component.
 * @param {string} title - the title do display in the component.
 * @param {string} logo - The logo to display in the component.
 * @param {string} content - the content do display in the component.
 * @param {string} button  - The title button to display in the component.
 * @param {string} onclick - the CTA to add to the button in the component.
 */

const DashboardCard = ({logo, title, content,button,link, onclick, first}) => {
  return (
    <div className="dashboard-card">
      <div className="os-flex gap-8 dashboard-card-top-container">
        {logo}
        <p className="os-subtitle1 dashboard-card-title">{title} </p>
      </div>
      <p className="os-body2 dashboard-card-content">{content} </p>
      {button === "Nous contacter" ? (
        <button id="open_os_intercom" className="write-us-btn-secondary">Nous contacter</button>
      ) : (
        <OSButton onClick={onclick} size="medium" variant={first ? "primary" : "primary-full"}  title={button} link={link} target={link === 'Être rappelé(e)'} />
      )}
    </div>
  );
};

export default DashboardCard;
