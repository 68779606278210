import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

const OsRadio = ({ formik, name, value, label }) => {
  // Check if the radio button should be checked
  const isChecked = formik.values[name] === value;

  // Handle change event
  const handleChange = (event) => {
    formik.setFieldValue(name, event.target.value);
  };

    const labelStyle = {
        backgroundColor: '#F8FBFC',
        padding: "8px 16px 8px 4px",
        borderRadius: 2,
        width: '100%',
        border: isChecked ? "1px solid #264C51" : "1px solid #F8FBFC",
        boxSizing: "border-box",
        cursor: 'pointer'
    };


    return (
    <>
      <FormControlLabel
        control={
          <Radio
            checked={isChecked}
            onChange={handleChange}
            value={value}
            name={name}
            style={{ color: '#264C51', cursor: 'pointer' }}
          />
        }
        label={label}
        style={labelStyle}
      />
    </>
  );
};

export default OsRadio;
