import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const OsSelect = ({ formik, name, label, options }) => {
    // Handle select change
    const handleChange = (event) => {
        formik.setFieldValue(name, event.target.value);
    };

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel>{label}</InputLabel>
            <Select
                value={formik.values[name]}
                onChange={handleChange}
                label={label}
                name={name}
                style={{ color: '#264C51' }}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default OsSelect;
