import React from "react"
import ContentLoader from "react-content-loader"

const PartnerViewLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 0 400 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="16" y="15" rx="2" ry="2" width="30%" height="8" />
    <rect x="16" y="40" rx="2" ry="2" width="20%" height="8" />
    <rect x="344" y="40" rx="2" ry="2" width="10%" height="8" />
    <rect x="16" y="60" rx="2" ry="2" width="50%" height="100" />
    <rect x="244" y="60" rx="2" ry="2" width="35%" height="8" />
    <rect x="244" y="80" rx="2" ry="2" width="35%" height="8" />
    <rect x="244" y="100" rx="2" ry="2" width="35%" height="8" />
    <rect x="244" y="140" rx="2" ry="2" width="35%" height="20" />
    <rect x="16" y="180" rx="2" ry="2" width="92%" height="30" />
    <rect x="16" y="220" rx="2" ry="2" width="20%" height="15" />
    <rect x="108" y="220" rx="2" ry="2" width="20%" height="15" />
    <rect x="208" y="220" rx="2" ry="2" width="20%" height="15" />
    <rect x="305" y="220" rx="2" ry="2" width="20%" height="15" />
    <rect x="16" y="260" rx="2" ry="2" width="92%" height="5" />
    <rect x="16" y="280" rx="2" ry="2" width="85%" height="5" />
    <rect x="16" y="320" rx="2" ry="2" width="90%" height="5" />
    <rect x="16" y="300" rx="2" ry="2" width="75%" height="5" />
    <rect x="16" y="320" rx="2" ry="2" width="85%" height="5" />
    <rect x="16" y="340" rx="2" ry="2" width="92%" height="200" />
  </ContentLoader>
)

export default PartnerViewLoader;

