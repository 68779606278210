import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

import ProductNework from "../../config/Network/ProductNework.ts";

import OpportunityCardLoader from "../../components/Loaders/Opportunities/OpportunityCardLoader";
import EmptyContent from "../../components/empty-content/EmptyContent";
import OpportunityCard from "../../components/Product/OpportunityCard";
import LargeOpportunityCard from "../../components/Product/OpportunityCard/large";

import {ReactComponent as NoData} from "../../assets/Illustrations/ic_tree_view.svg";

import './style.scss';

const Opportunities = () => {
  const lastPath = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
	const isCgp = useState(localStorage.getItem('CgpUserAuthToken') !== null)[0];
  const [selectedOpportunity, setSelectedOpportunity] = useState((lastPath !== 'opportunites' && lastPath) || null);
  //const [setSelectedDistribution] = useState((lastPath !== 'opportunites' && searchParams.get('distribution')) || null);
  //const [setSelectedMinimum] = useState((lastPath !== 'opportunites' && searchParams.get('minimum')) || null);
  const [refresh, setRefresh] = useState(false);
	
  const fetchOpportunities = async () => {
    setLoading(true);
    const response = await ProductNework.getProducts(selectedOpportunity);
    if (response) {
      setOpportunities(response);
      setLoading(false);
    }
  }

  useEffect(() => {
		setSearchParams({userType: isCgp ? 'cgp' : 'part'})
    selectedOpportunity !== 'livretp' ? fetchOpportunities() : setLoading(false);
    lastPath !== 'opportunites' && setSelectedOpportunity(lastPath);
  }, [selectedOpportunity, searchParams.get('category')])

  useEffect(() => {
    refresh && fetchOpportunities()
  }, [refresh])
	
	const renderLargeOpportunity = () => {
		if (loading) {
			return (
				<div className="opportunity-loading-card" key="loader-opportunity-1">
					<OpportunityCardLoader />
				</div>
			);
		} else if (!loading && opportunities.length > 0) {
			const openOpportunity = opportunities.find(opportunity => opportunity.position === 'top');
			if (openOpportunity) {
				return <LargeOpportunityCard category={openOpportunity} opportunity={openOpportunity} needRefresh={setRefresh} />;
			}
		}
		return null;
	}
	
	const renderOpportunities = () => {
		if (loading) {
			return [1, 2, 3, 4, 5, 6].map(i => (
				<div className="opportunity-loading-card" key={`loader-opportunity-${i}`}>
					<OpportunityCardLoader />
				</div>
			));
		} else if (!loading && opportunities.length === 0) {
			return (
				<EmptyContent
					illustration={<NoData />}
					text="Nous n'avons pas encore de produits dans cette catégorie"
				/>
			);
		} else if (!loading && opportunities.length > 0) {
			// Sort opportunities to place those with is_os === true first
			const nonTopOpportunities = opportunities.filter(o => o.position !== 'top');
			const sortedOpportunities = nonTopOpportunities.sort((a, b) => b.is_os - a.is_os);
			return sortedOpportunities.map(o => (
				<OpportunityCard
					category={o}
					opportunity={o}
					key={`${o.name}-${o.id}`}
					needRefresh={setRefresh}
				/>
			));
		}
	}
	
    return (
      <>
        <Helmet>
          <title>Opportunités | Openstone</title>
        </Helmet>
        <div className="opportunities-page-container">
          <h4 className="os-h4">Nos opportunités d'investissement</h4>
	        <div className="opportunities-container">
						{renderLargeOpportunity()}
	          {renderOpportunities()}
	        </div>
        </div>
      </>
    );
};

export default Opportunities;
