import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";

import CompanyInfos from "./Steps/CompanyInfos";
import CompanyUploads from "./Steps/CompanyUpload";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PersonnalInfos from "../../KycSurvey/Steps/PersonnalInfos";
import Knowledge from "../../KycSurvey/Steps/Knowledge";
import Revenus from "../../KycSurvey/Steps/Revenus";
import Statement from "../../KycSurvey/Steps/Statements";
import InstructionsContainer from "../../../components/instructionsContainer";
import OSButton from "../../../components/Button";

import '../../KycSurvey/style.scss';
import DownloadIcon from '@mui/icons-material/Download';

const CompanyForm = ({editing}) => {
  const {clientId} = useParams();
  const navigate = useNavigate();
  const CompanySurveyStep = localStorage.getItem('CompanySurveyStep');
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const isCgp = window.location.href.includes("cgp");
  const [loadingCompany, setLoadingCompany] = useState({});
  const localCompany = isCgp ? JSON.parse(localStorage.getItem('Company')) : {}
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(parseInt(CompanySurveyStep) || 0);
	const [stepName, setStepName] = useState('');

  const finalisationSteps = [
    { id: 0, title: "Informations de l'entreprise"},
    { id: 1, title: "Informations Personnelles" },
    { id: 2, title: "Connaissances et objectifs" },
    { id: 3, title: "Situation Financière" },
    { id: 4, title: "Justificatifs de votre entreprise"},
    { id: 5, title: "Déclarations"},
  ];
  async function fetchUser() {
    await authenticationStore.loadUserData().then(response => {
      setUser(response);
      setLoading(false);
      setLoadingCompany(false);
    });
  }

  async function fetchCompany() {
    const response = await CompanyNetwork.getCompany(localCompany?.id || clientId);
    if (response) {
      setCompany(response);
      setLoadingCompany(false);
      setUser(response);
      setLoading(false);
    }
  }

  const handleStep = state => {
    state === false ? setActiveStep(activeStep - 1) : setActiveStep(activeStep + 1)
  }

  useEffect(() => {
    if (!isCgp) {
      fetchUser();
    } else if (localCompany || clientId) {
      fetchCompany();
    } else {
      setLoading(false);
      setLoadingCompany(false);
    }
  }, [activeStep]);

  useEffect(() => {
    localStorage.setItem('CompanySurveyStep', activeStep.toString());
		activeStep === 0 && localStorage.removeItem('company');
	  if (activeStep >= 0 && activeStep < finalisationSteps.length) {
		  setStepName(finalisationSteps[activeStep].title);
	  }
  }, [activeStep])

  const checkStep = () => {
    switch (activeStep) {
      case 0:
        return <CompanyInfos handleStep={handleStep} cgpEditing={editing} isCgp={isCgp} company={company}/>;
      case 1:
        return <PersonnalInfos handleStep={handleStep} user={user} page="companyForm" isCgp={isCgp} company={company} />;
      case 2:
        return <Knowledge handleStep={handleStep} user={user} page="companyForm" isCgp={isCgp} company={company} />;
      case 3:
        return <Revenus handleStep={handleStep} user={user} page="companyForm"isCgp={isCgp} company={company} />;
      case 4:
        return <CompanyUploads handleStep={handleStep} isCgp={isCgp} company={company} />;
      case 5:
        return <Statement handleStep={handleStep} user={user} page="companyForm" isCgp={isCgp} company={company} />;
      case 6:
        if (isCgp) {
          localStorage.removeItem('CompanySurveyStep');
          return window.location.replace('/cgp/clients');
        } else {
          return window.location.replace('/overview');
        }
      default:
        return <div>Not Found</div>;
    }
  }
  return (
    <div className="user-survey-container">
      <Helmet>
        <title>Openstone | Informations client</title>
      </Helmet>
      {isCgp && (<OSButton title={editing ? `Revenir au profil de ${user?.first_name} ${user?.last_name}` : "Revenir à la liste des clients"} onClick={() => navigate(-1)} variant="primary-full" size="medium" fit icon={<ArrowBackIcon />}/>)}
	    
	    <div className="top-survey-container">
		    <div className="os-flex top-button-container">
			    <h4 className="os-h4">{editing ? "Édition d'une personne morale" : "Création d'une personne morale"}</h4>
			    {isCgp && (<OSButton icon={<DownloadIcon/>} fit variant="no_border" size="small" title="Télécharger le questionnaire" link="https://uploads-ssl.webflow.com/666832dd2d90ec3f37df07b1/66757cf903d23259fb8e19d0_FIC.pdf" />)}
		    </div>
		    <p className="os-body1" style={{marginTop: "-8px"}}>Ce questionnaire d'adéquation est à faire remplir à votre clientèle, vous pouvez aussi le faire remplir en PDF par celui-ci.</p>
	    </div>
	    
	    <div style={{margin: "40px 0"}}>
	      <Stepper activeStep={activeStep} alternativeLabel className="stepper-user-survey os-scroll-shadows" style={{cursor: "pointer"}}>
	        {finalisationSteps.map(step => (
	          <Step key={step.title} onClick={() => editing && setActiveStep(step.id)} style={{cursor: "pointer"}}>
	            <StepLabel>{step.title}</StepLabel>
	          </Step>
	        ))}
	      </Stepper>
	    </div>
	    <div className="user-survey-card">
		    {isCgp ? (
			    <div className="os-flex__column survey-top-container">
				    {activeStep > 0 && (<OSButton onClick={() => handleStep(false)} variant="secondary" title="Revenir à l'étape précédente" fit
				                                  size="small" icon={<ArrowBackIcon/>} />)}
				    <h4 className="os-h4" style={{marginTop: 24}}>{stepName}</h4>
			    </div>
		    ) : (
			    <InstructionsContainer
				    title={"Pourquoi toutes ces questions ?"}
				    content={"Openstone est un conseiller en investissement financier (CIF) et exerce son activité dans le cadre du règlement général de l’Autorité des Marchés Financiers (AMF). À ce titre nous avons pour devoir et pour objectif de vous proposer des placements qui correspondent à vos besoins. Ces questions nous aident à fournir un conseil personnalisé et à établir votre profil de risque pour vous orienter vers les placements et investissements les plus adaptés."}
			    />
		    )
		    }
	      {(loadingCompany || loading)  ? 'Chargement ...' : checkStep()}
	    </div>
	  </div>
  );
};

export default CompanyForm;
