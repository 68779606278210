import React, {useState} from 'react';
import {useFormik, FormikProvider, FieldArray, getIn, Field} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {InputAdornment, MenuItem} from "@mui/material";
import Divider from "@mui/material/Divider";
import {toast} from "react-toastify";

import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import {businessTypes, userFunction} from "../../../KycSurvey/Steps/choices";
import sortedCountries from "../../../../config/countries";
import OSButton from "../../../../components/Button";

const CompanyInfos = ({handleStep, cgpEditing, isCgp, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const [shareHoldersCount, setShareHoldersCount] = useState(1);
  const editing = (window.location.pathname === "/company/edit" || cgpEditing);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div>{error}</div> : null;
      }}
    />
  );

  const validationSchema = yup.object({
    legalName: yup.string().required('Requis').nullable('Requis'),
    businessType: yup.string().required('Requis').nullable('Requis'),
    activitySector: yup.string().required('Requis'),
    siren: yup.string().required('Requis').min(9, "Minimum 9 charactères").max(9, "Maximum 9 charactères"),
    rcs: yup.string().required('Requis'),
    capital: yup.string().required('Requis'),
    userFunction: yup.string().required('Requis').nullable('Requis'),
    address: yup.string().required('Requis'),
    zipCode: yup.string().required('Requis'),
    city: yup.string().required('Requis'),
    shareHolders: yup.array()
      .of(
        yup.object().shape({
          firstName: yup.string().required('Requis'),
          lastName: yup.string().required('Requis'),
          gender: yup.string().required('Requis').nullable('Requis'),
          birthDate: yup.string().required('Requis'),
          cityOfBirth: yup.string().required('Requis'),
          countryBirth: yup.string().required('Requis'),
          nationality: yup.string().required('Requis'),
          sharesPercentage: yup.string().required('Requis').max(100, "Ne peut pas excéder 100%"),
        })
      )
      .required('Veuillez renseigné les bénéficiaires de votre société')
      .min(1, '1 bénéficiaire au minimum'),
  });

  const formik = useFormik({
    initialValues: {
      legalName: company.legal_name || "",
      businessType: company.business_type || "",
      activitySector: company.activity_sector || "",
      siren: company.siren || "",
      rcs: company.rcs || "",
      capital: company.capital || "",
      address: company.address || "",
      zipCode: company.zip_code || "",
      city: company.city || "",
      userFunction: company.user_function || "",
      shareHolders: [
        {
          gender: null,
          firstName: '',
          lastName: '',
          birthDate: '',
          cityOfBirth: '',
          departmentOfBirth: '',
          countryBirth: '',
          nationality: '',
          sharesPercentage: '',
        },
      ]
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const params = {
        legal_name: values.legalName,
        business_type: values.businessType,
        activity_sector: values.activitySector,
        siren: values.siren,
        rcs: values.rcs,
        capital: values.capital,
        address: values.address,
        zip_code: values.zipCode,
        city: values.city,
        user_function: values.userFunction,
        share_holders: values.shareHolders,
        cgp: isCgp
      }
      let response;
      if (isCgp) {
        response = editing ? await CgpNetwork.updateRecord(params, company.id, 'Company') : await CompanyNetwork.createCompanies(params);
      } else {
        response = await CompanyNetwork.createCompanies(params);
      }
      if (response.errors|| response === false) {
	      setSubmitting(false);
        toast.error(response.errors.message, {
          toastId: 'completeInfosErrorToast'
        });
      } else if (!response.errors) {
        toast.success(editing ? 'La société a bien été mise à jour' : 'La société a bien été créée', {
          toastId: 'completeInfosSuccessToast'
        });
        localStorage.setItem('defaultProfile', 'false');
        localStorage.setItem('Company', JSON.stringify(response));
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error(editing ? 'Une erreur est survenue lors de la mise à jour de la société' : "Une erreur est survenue lors de la création de la société",{
          toastId: 'completeInfosErrorToast'
        });
      }
      setSubmitting(false);
    },
  });

  return <FormikProvider value={formik}>
    <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
      <h6 className="os-h6">Votre entreprise</h6>
      <div className="user-survey-questions-container">
        <TextField
          fullWidth
          id="legalName"
          name="legalName"
          label="Raison sociale"
          value={formik.values.legalName}
          onChange={formik.handleChange}
          error={formik.touched.legalName && Boolean(formik.errors.legalName)}
          helperText={formik.touched.legalName && formik.errors.legalName}
          variant={"outlined"}
        />
        <TextField
            fullWidth
            id="businessType"
            name="businessType"
            select
            label="Forme juridique"
            value={formik.values.businessType}
            onChange={formik.handleChange}
            error={formik.touched.businessType && Boolean(formik.errors.businessType)}
            helperText={formik.touched.businessType && formik.errors.businessType}
            variant={"outlined"}
        >
          {businessTypes.map(b => (
              <MenuItem key={`countryBirth-${b.label}`} value={b.value}>{b.label}</MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id="activitySector"
          name="activitySector"
          label="Secteur d'activité"
          value={formik.values.activitySector}
          onChange={formik.handleChange}
          error={formik.touched.activitySector && Boolean(formik.errors.activitySector)}
          helperText={formik.touched.activitySector && formik.errors.activitySector}
          variant={"outlined"}
        />
        <TextField
          fullWidth
          id="siren"
          name="siren"
          label="SIREN (ou numéro d'immatriculation)"
          value={formik.values.siren}
          onChange={formik.handleChange}
          error={formik.touched.siren && Boolean(formik.errors.siren)}
          helperText={formik.touched.siren && formik.errors.siren}
          variant={"outlined"}
        />
        <TextField
          fullWidth
          id="rcs"
          name="rcs"
          label="Ville RCS (ou lieu d'immatriculation)"
          value={formik.values.rcs}
          onChange={formik.handleChange}
          error={formik.touched.rcs && Boolean(formik.errors.rcs)}
          helperText={formik.touched.rcs && formik.errors.rcs}
          variant={"outlined"}
        />
        <TextField
          fullWidth
          id="capital"
          name="capital"
          type="number"
          label="Capital social (en euros)"
          value={formik.values.capital}
          onChange={formik.handleChange}
          error={formik.touched.capital && Boolean(formik.errors.capital)}
          helperText={formik.touched.capital && formik.errors.capital}
          variant={"outlined"}
        />
      </div>
      <Divider />
      <h6 className="os-h6">Coordonnées de l'entreprise</h6>
      <div className="user-survey-questions-container">
        <TextField
          fullWidth
          id="address"
          name="address"
          label="Adresse"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          variant={"outlined"}
          className="full-input"
        />
        <TextField
          fullWidth
          id="zipCode"
          name="zipCode"
          type="number"
          label="Code postal"
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          helperText={formik.touched.zipCode && formik.errors.zipCode}
          variant={"outlined"}
        />
        <TextField
          fullWidth
          id="city"
          name="city"
          label="Ville"
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          variant={"outlined"}
        />
      </div>
      <Divider />


      <h6 className="os-h6">Bénéficiaires effectifs (détenant au moins 25%)</h6>
      <FieldArray
        name="shareHolders"
        render={(arrayHelpers) => (
          <div>
            {formik.values.shareHolders.map((shareHolder, index) => (
              <div key={index}>
                {shareHoldersCount > 1 && <p>Bénéficiare {index + 1} :</p>}
                <div className="user-survey-questions-container">
                  <TextField
                    fullWidth
                    id="gender"
                    name={`shareHolders.${index}.gender`}
                    select
                    label="Civilité"
                    value={shareHolder.gender}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].gender)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].gender}
                    variant={"outlined"}
                  >
                    <MenuItem value={0}>Monsieur</MenuItem>
                    <MenuItem value={1}>Madame</MenuItem>
                  </TextField>
                  <ErrorMessage name={`shareholders.${index}.gender`} />
                  <TextField
                    fullWidth
                    id="firstName"
                    name={`shareHolders.${index}.firstName`}
                    label="Prénom"
                    value={shareHolder.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].firstName)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].firstName}
                    variant={"outlined"}
                  />
                  <TextField
                    fullWidth
                    id="lastName"
                    name={`shareHolders.${index}.lastName`}
                    label="Nom de famille"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].lastName)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].lastName}
                    variant={"outlined"}
                  />
                  <TextField
                    fullWidth
                    id="birthDate"
                    name={`shareHolders.${index}.birthDate`}
                    type="date"
                    label="Date de naissance"
                    value={formik.values.birthDate}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].birthDate)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].birthDate}
                    variant={"outlined"}
                    InputProps={{inputProps: { min: "1900-05-01", max: "2999-05-04"} }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    id="cityOfBirth"
                    name={`shareHolders.${index}.cityOfBirth`}
                    label="Ville de naissance"
                    value={formik.values.cityOfBirth}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].cityOfBirth)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].cityOfBirth}
                    variant={"outlined"}
                  />
                  <TextField
                      fullWidth
                      id="departmentOfBirth"
                      name="departmentOfBirth"
                      type="number"
                      label="Département de naissance"
                      value={formik.values.departmentOfBirth}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (/^\d{0,2}$/.test(value) && Number(value) <= 99)) {
                          formik.handleChange(e);
                        }
                      }}
                      error={formik.touched.departmentOfBirth && Boolean(formik.errors.departmentOfBirth)}
                      helperText={formik.touched.departmentOfBirth && formik.errors.departmentOfBirth}
                      variant={"outlined"}
                      inputProps={{ maxLength: 2 }}
                  />
                  <TextField
                    fullWidth
                    id="countryBirth"
                    name={`shareHolders.${index}.countryBirth`}
                    select
                    label="Pays de naissance"
                    value={formik.values.countryBirth}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].countryBirth)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].countryBirth}
                    variant={"outlined"}
                  >
                    {sortedCountries.sort().map(country => (
                      <MenuItem key={`countryBirth-${country.name}`} value={country.name}>{country.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id="nationality"
                    name={`shareHolders.${index}.nationality`}
                    select
                    label="Nationalité"
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].nationality)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].nationality}
                    variant={"outlined"}
                  >
                    {sortedCountries.map(country => (
                      <MenuItem  key={`nationality-${country.name}`} value={country.name}>{country.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    id="sharesPercentage"
                    type="number"
                    name={`shareHolders.${index}.sharesPercentage`}
                    label="Détention en %"
                    value={formik.values.shareHolders[index].sharesPercentage}
                    onChange={formik.handleChange}
                      onBlur={(e) => {
                        const value = e.target.value === '' ? '' : Number(e.target.value);
                        if (value < 25) {
                          formik.setFieldValue(`shareHolders.${index}.sharesPercentage`, 25);
                        } else if (value > 100) {
                          formik.setFieldValue(`shareHolders.${index}.sharesPercentage`, 100);
                        } else {
                          formik.setFieldTouched(`shareHolders.${index}.sharesPercentage`, true);
                        }
                      }}
                    error={formik.touched.shareHolders && Boolean(formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].sharesPercentage)}
                    helperText={formik.touched.shareHolders && formik.errors.shareHolders && formik.errors.shareHolders[index] && formik.errors.shareHolders[index].sharesPercentage}
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 25, max: 100 },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />

                </div>
                <Divider />
                {index > 0 && (
                  <button
                    className="user-survey-btn-back"
                    style={{margin: "16px 0"}}
                    type="button"
                    onClick={() => {
                      arrayHelpers.remove(index)
                      setShareHoldersCount(shareHoldersCount - 1)
                    }}
                  >
                    Retirer ce bénéficiaire
                  </button>
                )}
              </div>
            ))}

            {shareHoldersCount < 4 && (
              <div className="os-flex" style={{justifyContent: 'center', marginTop: 16, marginBottom: 24}}>
	              <OSButton
		              variant="primary-full"
		              onClick={() => {
			              arrayHelpers.push({
				              gender: 0,
				              firstName: '',
				              lastName: '',
				              birthDate: '',
				              cityOfBirth: '',
				              departmentOfBirth: '',
				              countryBirth: '',
				              nationality: '',
				              sharesPercentage: '',
			              })
			              setShareHoldersCount(shareHoldersCount + 1)
		              }}
		              size="medium"
		              title="Ajouter un bénéficiaire"
		              fit
	              />
              </div>
            )}
          </div>
        )}
      />

      <h6 className="os-h6">Quelle est votre fonction au sein de l'entreprise ?</h6>
      <div className="user-survey-questions-container">
        <TextField
          fullWidth
          id="userFunction"
          name="userFunction"
          select
          label="Votre poste"
          value={formik.values.userFunction}
          onChange={formik.handleChange}
          error={formik.touched.userFunction && Boolean(formik.errors.userFunction)}
          helperText={formik.touched.userFunction && formik.errors.userFunction}
          variant={"outlined"}
        >
          {userFunction.map(f => (
            <MenuItem  key={`function-${f.value}`} value={f.value}>{f.value}</MenuItem>
          ))}
        </TextField>
      </div>

      <div className="user-survey-btn-container">
        {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
        <button type="submit" className="user-survey-btn">Continuer</button>
      </div>
    </form>
  </FormikProvider>;
};

export default CompanyInfos;
