import React from 'react';
import {toast} from "react-toastify";

import OSButton from "../../Button";
import {addAdditionalClasses} from "../../../utils/utils";
import Images from "../../../assets/images";

import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import './style.scss';

const PromoCard = ({promotion, used, isAd}) => {
  const classNames = addAdditionalClasses('promo-card', [
    used && 'promo-card--used',
    isAd && 'promo-card--ad'
  ]);

  return (
    <div className={classNames}>
      <div className="os-flex promo-card-top">
        <p className="os-subtitle1">{promotion.coupon.value.toUpperCase()}</p>
        {!used && (isAd ? <LoyaltyIcon /> : <img src={Images.coupon} alt=""/>)}
      </div>
      <p className="os-body1 promo-description">{promotion.coupon.description}</p>
      <div className="promo-card-copy">
        {used ? promotion.coupon.key : (
          <button className="os-flex gap-8 field-copy" onClick={() => {
            navigator.clipboard.writeText(promotion.coupon.key)
            toast.success('Code de promotion copié !', {
              toastId: `referral_Toast`
            });
          }}>{promotion.coupon.key} <ContentCopyIcon /> </button>
        )}
      </div>
      {isAd ? ('') : (
        <p className={`os-body2 os-flex gap-8 ${used ? 'promo-used' : 'promo-expire'}`}>
          <WatchLaterIcon/> {used ? `Utilisée le ${promotion?.used_at}` : `Expire le ${promotion?.coupon?.deactivation_day} ${promotion?.coupon?.remaining_days}`}
        </p>
      )}
      {!used && <OSButton variant="secondary" size="large" title={isAd ? "Inviter des amis" : "Investir"} link={promotion.related_product} /> }
    </div>
  );
};

export default PromoCard;
