import {ReactComponent as LivretP} from "../config/routes/icons/ic_Livret_p.svg";
import {ReactComponent as ClubDeals} from "../config/routes/icons/ic_Club_deals.svg";
import {ReactComponent as FIA} from "../config/routes/icons/ic_Private_equity.svg";
import {ReactComponent as SCPI} from "../config/routes/icons/ic_SCPI.svg";
import {ReactComponent as DettePrivee} from "../config/routes/icons/ic_Dette_privee.svg";
import {ReactComponent as International} from "../config/routes/icons/ic_international.svg";
import React from "react";

class OpportunityHelper {
  livretPOpportunity = {
    name: 'Livret P.',
    subscription_state: "open",
    category_name: 'Épargne dynamique',
    content: 'Le Livret d\'Epargne Pierre, c\'est l\'alliance d\'un rendement fixe de 4% / an et d\'une forte liquidité. Le tout sans jamais aucun frais. Vous pouvez même booster votre rendement jusqu\'à 6%.',
    rate: 4,
    horizon: 'Court terme',
    minimum_investment: '100',
    cover_picture: {
      original: 'https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    offer_label: '10€ offerts'
  }

  renderCategory = (product) => {
    let logo;
    let url;
    let videoTitle;
    if (product?.category_name === 'Livret P.') {
      logo = <LivretP />
      url = '/opportunites/livret-p';
    } else if (product?.category_name === 'Club Deals') {
      logo = <ClubDeals />
      videoTitle = `Caroline vous dit tout sur le ${product?.name} !`;
    } else if (product?.category_name === 'Alternatifs') {
      logo = <FIA />
      videoTitle = `Caroline vous dit tout sur le fonds Burger King !`;
    } else if (product?.category_name === 'SCPI') {
      logo = <SCPI />
      videoTitle = `${product?.id === 3 ? 'Dimitri' : 'Caroline'} vous dit tout sur la SCPI ${product.name}`;
    } else if (product?.category_name === 'Dette privée') {
      logo = <DettePrivee />
    } else if (product?.category_name === 'International') {
      logo = <International />;
    }
    return {logo, url, videoTitle}
  };
}

export default new OpportunityHelper()
