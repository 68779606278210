import React from 'react';

import {desktopLinks as links} from "../../config/routes/Links/links";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './style.scss';


const More = () => {
  return (
    <div className="more-container">
      <h4 className="os-h3 more-title">Menu</h4>
      <h4 className="os-h4 more-title">Nos Opportunités d'investissement</h4>
      <div className="more-links">
        {links.map(l => (
          <a className="more-links__link" href={l.link} key={`${l.title}-${l.link}`}>
            {l.title}
              <ArrowForwardIosIcon />
          </a>
        ))}
      </div>
        <h4 className="os-h4 more-title">Gestion du compte</h4>
        <div className="more-links">
            <a className="more-links__link" href="/documents" key={`parrainnage_key`}>
                Documents
                <ArrowForwardIosIcon />
            </a>
            <a className="more-links__link" href="/parrainage" key={`parrainnage_key`}>
                Parrainage
                <ArrowForwardIosIcon />
            </a>
        </div>
    </div>
  );
};

export default More;
