import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import {useLocation} from "react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";
import CgpNetwork from "../../config/Network/CgpNetwork.ts";

import CreateUser from "./Steps/PersonnalInfos/creation/createUser";
import PersonnalInfos from "./Steps/PersonnalInfos";
import Knowledge from "./Steps/Knowledge";
import Revenus from "./Steps/Revenus";
import Uploads from "./Steps/Uploads";
import Statement from "./Steps/Statements";
import InstructionsContainer from "../../components/instructionsContainer";

import OSButton from "../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";

import './style.scss';

const UserSurvey = ({editing}) => {
  const [searchPrams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectionPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const UserSurveyStep = localStorage.getItem('UserSurveyStep');
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(parseInt(UserSurveyStep) || 0);
  const isCgp = window.location.href.includes("cgp");
  const localUser = isCgp ? JSON.parse(localStorage.getItem('User')) : {}
	const [stepName, setStepName] = useState('');

  const finalisationSteps = [
    { id: 0, title: "Informations Personnelles" },
    { id: 1, title: "Connaissances et objectifs" },
    { id: 2, title: "Situation Financière" },
    { id: 3, title: "Justificatifs personnels"},
    { id: 4, title: "Vérification"},
  ];
  async function fetchUser() {
    try {
      const response = await AuthenticationNetwork.getUserForSurvey();
      setUser(response);
    } catch (error) {
      toast.error("Une erreur est survenue, veuillez nous contacter")
    } finally {
      setLoading(false);
    }
  }
  async function editUser() {
    const response = await CgpNetwork.editClient(localUser, 'User')
    if (response) {
      setUser(response)
      setLoading(false);
    }
  }

  const handleStep = useCallback((state) => {
    state === false ? setActiveStep(activeStep - 1) : setActiveStep(activeStep + 1);
  }, [activeStep]);

  useEffect(() => {
    setLoading(true);
    setSearchParams('');
    if (isCgp && (editing || activeStep > 0)) {
      editUser()
    } else if (!isCgp) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [isCgp, editing, activeStep]);

  useEffect(() => {
    localStorage.setItem('UserSurveyStep', activeStep.toString());
	  if (activeStep >= 0 && activeStep < finalisationSteps.length) {
		  setStepName(finalisationSteps[activeStep].title);
	  }
  }, [activeStep])

  function checkStep() {
    if (loading) {
      return 'Chargement ...'
    } else if (!loading && user !== {}) {
			activeStep < 0 && setActiveStep(0);
      switch (activeStep) {
        case 0:
          return !editing && isCgp ? <CreateUser handleStep={handleStep} /> : <PersonnalInfos handleStep={handleStep} user={user} isCgp={isCgp} page="userForm" step={activeStep} />;
        case 1:
          return <Knowledge handleStep={handleStep} user={user} isCgp={isCgp} page="userForm"/>;
        case 2:
          return <Revenus handleStep={handleStep} user={user} isCgp={isCgp} page="userForm"/>;
        case 3:
          return <Uploads handleStep={handleStep} user={user} isCgp={isCgp} page="userForm"/>;
        case 4:
          return <Statement handleStep={handleStep} user={user} isCgp={isCgp} page="userForm"/>;
        case 5:
          if (isCgp) {
            navigate('/cgp/clients');
          } else {
            navigate(redirectionPath === '' ? '/overview' : `${redirectionPath}${location.search}?user_survey=completed`);
          }
          break
        default:
          return <div>Une erreur est survenue, veuillez nous contacter</div>;
      }
    }
  }
  const goBackAction = () => {
    if (editing) {
      localStorage.setItem('User', JSON.stringify(user?.id))
      navigate(`${window.location.pathname.split('/').slice(0, -3).join('/')}/${user?.id}`, {state: {clientId: user?.id, listableType: 'User'}})
    } else {
      navigate("/cgp/clients")
    }
  };

  const handleDirectStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="user-survey-container">
      <Helmet>
        <title>Openstone | Questionnaire client</title>
      </Helmet>
      {isCgp && (
        <OSButton title={editing ? `Revenir au profil de ${user?.first_name} ${user?.last_name}` : "Revenir à la liste des clients"} onClick={() => goBackAction()} variant="primary-full" size="medium" fit icon={<ArrowBackIcon />}/>
      )}
	    <div className="top-survey-container">
	      <div className="os-flex top-button-container">
	        <h4 className="os-h4">{isCgp ? 'Questionnaire client' : 'Questionnaire'}</h4>
		      {isCgp && (<OSButton icon={<DownloadIcon/>} fit variant="no_border" size="small" title="Télécharger le questionnaire" link="https://uploads-ssl.webflow.com/666832dd2d90ec3f37df07b1/66757cf69c39867f6315dfe4_FIC%20PM.pdf"/>)}
	      </div>
		    <p className="os-body1" style={{marginTop: "-8px"}}>Ce questionnaire d'adéquation est à faire remplir à votre clientèle, vous pouvez aussi le faire remplir en PDF par celui-ci.</p>
	    </div>
			
	    <div style={{margin: "40px 0"}}>
	      <Stepper activeStep={activeStep} alternativeLabel className="stepper-user-survey os-scroll-shadows" style={{cursor: "pointer"}}>
	        {finalisationSteps.map(step => (
	          <Step key={step.title} onClick={() => editing && handleDirectStepChange(step.id)} style={{cursor: "pointer"}}>
	            <StepLabel>{step.title}</StepLabel>
	          </Step>
	        ))}
	      </Stepper>
	    </div>
      <div className="user-survey-card">
        {isCgp ? (
					<div className="os-flex__column survey-top-container">
						{activeStep > 0 && (<OSButton onClick={() => handleStep(false)} variant="secondary" title="Revenir à l'étape précédente" fit
						           size="small" icon={<ArrowBackIcon/>} />)}
						<h4 className="os-h4" style={{marginTop: 24}}>{stepName}</h4>
					</div>
        ) : (
          <InstructionsContainer
            title={"Pourquoi toutes ces questions ?"}
            content={"Openstone est un conseiller en investissement financier (CIF) et exerce son activité dans le cadre du règlement général de l’Autorité des Marchés Financiers (AMF). À ce titre nous avons pour devoir et pour objectif de vous proposer des placements qui correspondent à vos besoins. Ces questions nous aident à fournir un conseil personnalisé et à établir votre profil de risque pour vous orienter vers les placements et investissements les plus adaptés."}
          />
        )}
        <Suspense fallback={<div>Chargement...</div>}>
          {checkStep()}
        </Suspense>
      </div>
    </div>
  );
};

export default UserSurvey;
