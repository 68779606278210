import React from 'react';

import ProductCardRecap from "../ProductCardRecap";
// import PromotionsNetwork from "../../../../config/Network/PromotionsNetwork.ts";

// import BankLoader from "../../../../components/Loaders/Bank";
// import SmallPromoCard from "../../../../components/card/PromoCard/smallPromoCard";
// import AddCoupon from "../../../../Forms/Promotions/addCoupon";

import './stepsStyle.scss';

const StepsContainer = ({children, children1, product, step, minimumInvestment, object}) => {
  //const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  //const cgp = useState(localStorage.getItem('CgpUserAuthToken') !== null)[0];
  //const [loading, setLoading] = useState(true);
  //const [promotions, setPromotions] = useState([]);
  //const localStoragePromoCode = localStorage.getItem('promoCode');
  //const [selectedPromotion, setSelectedPromotion] = useState(null);
  //const [couponAdded, setCouponAdded] = useState(false);

  {/*const getAvailablePromotion = async () => {
    setLoading(true)
    const availablePromotions = await PromotionsNetwork.listAvailable(defaultProfile ? 'User' : 'Company', product.id, product.id == 9 ? 'livret_p' : 'product');
    if (availablePromotions) {
      setPromotions(availablePromotions);
    }
    setLoading(false);
  }

  const renderPromotions = () => {
    if (loading || promotions === []) {
      return [1, 2].map((e, index) => <BankLoader key={index}/>);
    } else if (!loading && promotions.length > 0) {
      return promotions.map(p => <SmallPromoCard promotion={p} key={p.value} selectedPromotion={selectedPromotion} setSelectedPromotion={setSelectedPromotion}/>)
    }
  }

  useEffect(() => {
    promotions.some(arrayElement => (arrayElement.coupon.value === localStoragePromoCode) && setSelectedPromotion(arrayElement));
  }, [localStoragePromoCode, promotions]);

  useEffect(() => {
    !cgp && getAvailablePromotion();
  }, [couponAdded]);*/}

  return (
    <div className="investment-arrangement-container gap-16">
      <div className="os-flex__column gap-24 investment-arrangement-left">
        <div className="investment-card-recap">
          {children}
        </div>
        {children1 && (
          <div className="investment-card-recap">
            {children1}
          </div>
        )}
      </div>
      <div className="information-recap">
        <div className="investment-card-recap">
          <ProductCardRecap product={product} minimumInvestment={minimumInvestment} object={object} />
        </div>
        {/*{(step === 0 || step === 1 || step === 2) && (
          <div className="investment-card-recap">
            <div className="os-flex investment-coupon-top">
              <p className="os-subtitle1">Ajouter un code promo</p>
            </div>
            <AddCoupon func={setCouponAdded} />
            <Divider style={{margin: "24px 0"}}/>
            <p className="os-subtitle1">Vos codes promo disponibles</p>
            <p className="os-body2">Séléctionnez le code promo dont vous souhaitez bénéficier</p>
            <div>
              {renderPromotions()}
            </div>
          </div>
        )}*/}
      </div>
    </div>
  );
};

export default StepsContainer;
