// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import Scrollbar from '../scrollbar';
import { TableHeadCustom } from './index.js';

import './myTable.scss';
import React from "react";
import moment from "moment";
import Label from "../label";

// ----------------------------------------------------------------------
export default function ReferralsTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map(row => (
                <ReferralsTableRow key={`${row.id}-${row.full_name}`} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

function ReferralsTableRow({ row }) {
	const checkRowStatus = (row) => {
		let status;
		if (row.account_status === 'verified') {
			status = 'Compte validé';
		} else if (row.account_status === 'unverified') {
			status = 'Inscrit';
		}
		return status;
	};
  return (
    <>
      <TableRow>
        <TableCell>
          <div>{row.full_name}</div>
        </TableCell>
        <TableCell>
	        <div>{moment(row.created_at).locale('fr').format('DD/MM/YYYY')}</div>
        </TableCell>
	      <TableCell>
		      <Label
			      variant="soft"
			      color={
				      ((row.account_status === 'unverified') && 'warning') ||
				      (row.account_status === 'verified' && 'success') || 'info'
			      }
		      >
			      {checkRowStatus(row)}
		      </Label>
	      </TableCell>
	      <TableCell>
		      <div>{row.account_verified_at ? moment(row.account_verified_at).locale('FR').format('DD/MM/YYYY') : 'Aucun dépôt effectué'}</div>
	      </TableCell>
      </TableRow>
    </>
  );
}
