import { makeAutoObservable } from 'mobx';

import QuestionNetwork from "../config/Network/QuestionNetwork.ts";

class BoostStore {
  question = {};
  upcoming = {};
  answeredRecently = false;

  constructor() {
    makeAutoObservable(this);
  }

  async loadQuestion() {
    const question = await QuestionNetwork.active();
    if (question) {
      this.question = question;
      if (question.user_answer_id !== null) {
        await this.getUpcomingQuestion();
      }
    }
  }

  async answerActive(id: number) {
    const updatedQuestion = await QuestionNetwork.answerActive(id);

    if (updatedQuestion) {
      await this.loadQuestion();
      this.question = updatedQuestion;
      this.answeredRecently = true;
    }
  }

  async generateAnswer() {
    return await QuestionNetwork.generateAnswer();
  }

  async getUpcomingQuestion() {
    const upcoming = await QuestionNetwork.upcoming();
    if (upcoming) {
      this.upcoming = upcoming;
    }
  }
}

const boostStore = new BoostStore();
export default boostStore;
