import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import './style.scss';

import { addAdditionalClasses } from '../../utils/utils';

/**
 * A simple tab component.
 * @param {boolean} clickable - Whether to display the tab with a clickable behavior.
 * @param {boolean} selected - Whether to display the tab with the selected behavior.
 * @param {boolean} disabled - Whether to display the tab with the disbaled behavior.
 */
const CustomTab = ({ tab, clickable, onTabClick, selected, disabled , full}) => {
  const classNames = addAdditionalClasses('os-flex os-tab', [
    clickable && 'os-tab--clickable',
    selected && 'os-tab--selected',
    disabled && 'os-tab--disabled',
    full && 'os-tab--full'
  ]);

  function handleTabClick(id) {
    onTabClick(id)
  }

  return (
    <div className={classNames} onClick={() => clickable && handleTabClick(tab.id)}>
      <div className="os_tab__infos">
        <div className="os-tab__title">
            <div className="os-flex title-container">
                {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon/>}
                <p className="os-subtitle1">{tab?.title}</p>
            </div>
          <p className="os-body2" style={{margin: 0}}>{tab?.description}</p>
        </div>
      </div>
    </div>
  );
};

CustomTab.propTypes = {
  clickable: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  full: PropTypes.bool
};

CustomTab.defaultProps = {
  clickable: false,
  selected: false,
  disabled: false,
  full: false,
};

export default CustomTab;
