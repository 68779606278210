import React from 'react';

import './style.scss';

const ColoredCard = ({productInfos, product, children}) => {
  const convertProductInfoName = (product) => {
    if (product.name === 'Alternatifs') {
      return 'Private Equity';
    } else {
      return `${product.category_name} ${product.name}`;
    }
  };

  return (
    <div className="colored-card-container">
      <div className="colored-card-container-img">
        <div className="os-flex colored-card-infos">
          <p className="os-subtitle1 investment-sold-card">{productInfos.logo} {convertProductInfoName(product)}</p>
          <div className="colored-card-keyinfos-container">
            <div className="os-flex colored-card-infos-container">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoredCard;
