import EmptyContent from "../empty-content/EmptyContent";
import ChartDonut from "../charts/donut";
import React from "react";
import BannerLoader from "../Loaders/Banner";
import {ReactComponent as NoData} from "../../assets/Illustrations/no-transactions.svg";

const renderChartDonut = (object, type) => {
  if (object?.fund_shares?.total === 0) {
    if (type !== 'cgp') {
      return null;
    } else {
      return (
        <div className="no-investments">
          <EmptyContent text={type === 'cgp' ? "Le client n'a pas encore d'investissements" : "Vous n'avez pas encore d'invesitssements"}
                        illustration={<NoData />}
                        cta={{title: "Voir les opportunités", link: "/opportunites", variant: "primary-full", size: "large"}}
          />
        </div>
      )
    }
  } else {
    return <ChartDonut data={object?.fund_shares?.shares} type={type}/>
  }
}

const renderChart = (loading, object, type) => {
  const chartDonut = renderChartDonut(object, type);
  if (loading || Object.keys(object !== null && object).length === 0) {
    return <BannerLoader />
  } else if (!loading && Object.keys(object).length > 0) {
    if (chartDonut === null) {
      return null;
    } else {
      return (
        <div className="chart-card">
          <h5 className="chart-card-title os-h5">{type === 'cgp' ? "Répartition des fonds" : "Répartition de vos fonds"}</h5>
          {object !== null && renderChartDonut(object, type)}
        </div>
      );
    }
  }
}

export default renderChart;
