import React from "react"
import ContentLoader from "react-content-loader"

const ProductsLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={210}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="5" y="-17" rx="10" ry="2" width="100%" height="100%" />
  </ContentLoader>
)

export default ProductsLoader;
