import React from "react";
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// components
import Label from '../label';
import Scrollbar from '../scrollbar';
import { TableHeadCustom } from './index.js';
import moment from "moment/moment";
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import MoneyHelper from "../../Helpers/MoneyHelper";

import './myTable.scss';
import LaunchIcon from "@mui/icons-material/Launch";

// ----------------------------------------------------------------------

DocumentTable.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function DocumentTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row) => (
                <DocumentTableRow key={`tableCell-${row.id}`} row={row} />
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

DocumentTableRow.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
  }),
};

const checkRowType = transaction => {
  let title;
  let growth;
  if (transaction.transaction_type === 'top_up') {
    title = 'Dépôt';
    growth = <div className="arrow-container arrow-container-green"><NorthEastRoundedIcon /></div>;
  }
  return {title, growth};
};

const checkRowStatus = transaction => {
  let status;
  if (transaction?.status === 'success') {
    status = 'Validé';
  } else if (transaction?.status === 'error') {
    status = 'Erreur';
  } else if (transaction?.status === 'pending') {
    status = 'En cours';
  } else if (transaction?.status === 'pending_scpi') {
    status = 'En attente';
  } else if (transaction?.status === 'potential') {
    status = 'En cours';
  }
  return status;
};

function DocumentTableRow({ row }) {
  return (
    <>
      <TableRow>
        {/*<TableCell>
          <div style={{display: "flex", alignItems: 'center', gap: 10}}>
            {checkRowType(row.associated_transaction).growth}
            {checkRowType(row.associated_transaction).title}
          </div>
        </TableCell>*/}

        <TableCell>
          <span className="ds_category_name">{row?.associated_product?.name}</span>
        </TableCell>


        <TableCell>
          <span className="ds_category_name">
            {moment(row?.associated_transaction?.created_at).format('DD/MM/yyyy')}
          </span>
        </TableCell>

        <TableCell>
          <span className="ds_category_name">
            {MoneyHelper.formatEuro(row.type === "products" ? row?.associated_transaction?.balance : row?.associated_transaction?.amount, false, 2, 2)}
          </span>
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              ((row.associated_transaction?.status === 'pending' || row.associated_transaction?.status === 'pending_scpi' || row.associated_transaction?.status === 'potential') && 'warning') ||
              (row.associated_transaction?.status === 'error' && 'error') ||
              'success'}>
            {checkRowStatus(row.associated_transaction)}
          </Label>
        </TableCell>

        <TableCell>
          {row?.file?.url !== null && (
            <a className="see-doc-btn" href={row?.file?.url} target="_blank" rel="noreferrer">
              Voir le bulletin <LaunchIcon/>
            </a>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
