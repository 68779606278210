// @ts-ignore
import Network from './Network.ts';

class CgpNetwork extends Network {

  async getMe() {
    try {
      return await this.get('cgp_users/me', {});
    } catch (err) {
      return false;
    }
  }
  async getDefaultList(page: string, perPage: string, filters: any = []) {
    const filterParams = filters
      .filter((filter: any) => filter.value)
      .map((filter: any) =>
        `filters[][field]=${encodeURIComponent(filter.field)}&filters[][value]=${encodeURIComponent(filter.value)}`
      )
      .join("&");

    return await this.get(`/lists/default_list?${filterParams}&page=${page}&per_page=${perPage}`);
  }

  async getClient(clientId: string, recordType: string) {
    return await this.get(`/cgp_users/show_record/${clientId}`, {record_type: recordType});
  }

  async createRecord(params) {
    return await this.post('/cgp_users/create_record', params)
  }

  async updateRecord(params, recordId, recordType) {
    return await this.put(`/cgp_users/update_record/${recordId}?record_type=${recordType}`, params)
  }

  async editClient(clientId: string, recordType: string) {
    return await this.get(`/cgp_users/edit_record/${clientId}`, {record_type: recordType});
  }

  async sendDocumentToClient(clientId: string, recordType: string, unsignedDocuments: [], values: object, productId: string, transactionType: string) {
    return await this.post(`/cgp_users/${clientId}/send_document_to_client`, {
      id: clientId,
      record_type: recordType,
      documents: unsignedDocuments,
      values: values,
      product_id: productId,
      transaction_type: transactionType
    });
  }

  async getClientDocuments(contractType: string, clientId: string, recordType: string) {
    return await this.get(`/cgp_users/${clientId}/get_client_documents`, {
      contract_type: contractType,
      id: clientId,
      record_type: recordType
    });
  }

  async getProductRelatedDocuments(productId: string, clientId: string, recordType: string) {
    return await this.get(`/cgp_users/${clientId}/get_product_related_documents`, {
      product_id: productId,
      record_id: clientId,
      record_type: recordType
    });
  }

  async generateProductAnnexe(productId: number, productName: string) {
    return await this.post('/cgp_users/generate_product_annexe_doc', {
      product_id: productId,
      product_name: productName,
    });
  }

  async reSendSignature(signatureRequestId: string) {
    return await this.post(`/cgp_users/send_signature_reminder`, {
      signature_request_id: signatureRequestId,
    });
  }
}

export default new CgpNetwork();
