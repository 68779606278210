import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import UserBoostNetwork from "../../config/Network/UserBoostNetwork.ts";
import CompanyBoostNetwork from "../../config/Network/CompanyBoostNetwork.ts";
import BoostStore from '../../stores/BoostStore.ts'

import BoostOfTheWeek from "./BoostsOfTheWeek";
import {Helmet} from "react-helmet";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StepsExplainer from "../../components/StepsExplainer";
import BoostsTable from "../../components/Table/BoostsTable";
import EmptyContent from "../../components/empty-content/EmptyContent";

import {ReactComponent as NoData} from "../../assets/Illustrations/no_investments.svg";
import {ReactComponent as Wait} from "./wait.svg";
import {ReactComponent as Question} from "./question.svg";
import {ReactComponent as Stock} from "./rocket.svg";

import './style.scss';
import OSButton from "../../components/Button";

const steps = [
  {
    title: "Étape 1",
    body: "Tentez votre chance à la question de la semaine\n",
    illustration: <Question />,
  },
  {
    title: "Étape 2",
    body: "Bonne réponse ? Votre boost sera automatiquement activé !",
    illustration: <Stock />
  },
  {
    title: "Étape 3",
    body: "Mauvaise réponse ? Pas de soucis retentez votre chance la semaine prochaine",
    illustration: <Wait />
  }
]

const Boosts = () => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());
  const [boosts, setBoosts] = useState([]);
  const [loadingBoosts, setLoadingBoosts] = useState(true);

  const loadQuestion = async () => {
    await BoostStore.loadQuestion();
    if (!defaultProfile ? (BoostStore.question.company_answer_id !== null) : (BoostStore.question.user_answer_id !== null)) {
      await BoostStore.getUpcomingQuestion();
    }

    if (BoostStore.answeredRecently) {
      BoostStore.answeredRecently = true;
      setLastUpdatedAt(new Date());
    }
  };

  const loadBoost = async () => {
    if (!defaultProfile) {
      const result = await CompanyBoostNetwork.list({
        page: 1,
        perPage: 20
      });
      if (result) {
        setBoosts(boosts.concat((result.list)));
        setLoadingBoosts(false);
      }
    } else {
      const result = await UserBoostNetwork.list({
        page: 1,
        perPage: 20
      });
      if (result) {
        setBoosts(boosts.concat((result.list)));
        setLoadingBoosts(false);
      }
    }
  }
  const tableLabels= [{ id: 'full_name', label: 'Pourcentage' }, { id: 'created_at', label: 'Durée' }, {id: 'should_end_at', label: 'restant'}, {id: 'status',label:'statut'}]

  useEffect(() => {
    loadQuestion();
    loadBoost();
  }, []);

  return (
    <>
      <Helmet>
        <title> Livret P. | Boosts</title>
      </Helmet>
      <div className="top-boosts-container">
        <OSButton variant="primary-full" size="large" onClick={() => window.location.replace('/opportunites/livret-p/my-investment')} icon={<ArrowBackIcon />} title="Revenir à mon Livret P." fit/>
        <div className="os-flex" style={{justifyContent: "space-between", alignItems: "center"}}>
          <h4 className="os-h4">Boostez votre Livret P. jusqu’à 6% par an</h4>
          <div className="os-flex gap-32 promotions-list">
            <BoostOfTheWeek defaultProfile={defaultProfile}/>
          </div>
        </div>
        <StepsExplainer steps={steps} />
      </div>
      <div>
        <div className="promotions-container">
          <h4 className="os-h4">Vos boosts</h4>
          {boosts.length > 0 ? (
              <div className="os-flex gap-32 boosts-list promotions-list">
                <BoostsTable tableLabels={tableLabels} tableData={boosts} />
              </div>
          ) : <EmptyContent text="Vous n'avez pas encore de boost" illustration={<NoData />}/>}
        </div>
      </div>
    </>
  );
};

export default observer(Boosts);
