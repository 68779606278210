import React from 'react';

function NavItem({ children, ...rest }) {
  return (
    <a href={rest?.to}
      className={`links-container__link ${window.location.pathname.split("/").pop() === (rest?.to?.split('/')[3] || rest?.to?.split('/')[2] || rest?.to?.split('/')[1]) && ' links-container__link__active'}`} {...rest} >
      {children}
    </a>
  );
}

export default NavItem;
