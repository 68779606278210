import Network from './Network.ts';

class ReferralNetwork extends Network {
  async addCode(code: string) {
    return await this.put('/user_invitations/check', {
      code: code,
    });
  }
}

export default new ReferralNetwork();
