import React, {useEffect} from 'react';
import {useSearchParams} from "react-router-dom";

import {Helmet} from "react-helmet";
import MoneyHelper from "../../../../../Helpers/MoneyHelper";

import OSButton from "../../../../../components/Button";

import {ReactComponent as PaymentDeclined} from "../../../../../assets/Illustrations/payment-declined.svg";
import {ReactComponent as PaymentSuccess} from "../../../../../assets/Illustrations/payment-success.svg";

import './style.scss';

const Callback = ({page}) => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const amount = localStorage.getItem('InvestmentAmount');

  useEffect(() => {
    if (status === 'success' || status === 'pending') {
      localStorage.removeItem('signedDocs');
      localStorage.removeItem('product');
      localStorage.removeItem('productUrl');
    }
    localStorage.setItem('transactionStatus', status);
  }, [status]);

  const title = () => {
    let title;
    let content;
    let link;
    if (status === 'success') {
      title = `Votre paiement a bien été pris en compte`
      content = `Notre prestataire de paiement a bien pris en compte votre paiement de ${MoneyHelper.formatEuro(amount)}, celui-ci apparaitra sur votre portefeuille sous 3 jours maximum.`
      link = "/overview"
    } else if (status === 'error') {
      title = 'Votre tentative de paiement à échoué'
      content = `Votre tentative de paiement de ${MoneyHelper.formatEuro(amount)} n'a pas pu aboutir, suite à un problème de connection avec votre banque.\n Nous vous invitons à re-essayer via un autre moyen de paiement.`
      link = "/overview"
    } else if (searchParams.get('transactionType') === 'livretp') {
      title = 'Votre Transfert a bien été validé'
      content = `Votre transfert de votre Livret P. d'un montant de ${MoneyHelper.formatEuro(amount)} a bien été validé, vous retrouverez vos opérations sur votre tableau de bord.`
      link = "/overview"
    } else {
      title = 'En attente de la réception des fonds'
      content = `Nous vous remercions pour votre investissement de ${MoneyHelper.formatEuro(amount)}. Une fois réceptionné, celui-ci apparaîtra sur votre portefeuille. \n Assurez-vous de nous avoir communiqué par e-mail les informations du virement.`
      link = "/overview"
    }
    return {title, content, link}
  }

  return (
    <>
      <Helmet>
        <title>Status du paiement</title>
      </Helmet>
      <div className="status-page">
        <div className="status-container">
          {status !== 'error' ? <PaymentSuccess/> : <PaymentDeclined />}
          <h5 className={`status-container__title os-h5 ${status !== 'error' ? 'status-container__title__green' : 'status-container__title__red'}`}>{title().title}</h5>
          <p className="status-container__content os-body2">{title().content}</p>
          <div className="status-container__buttons">
            {status === 'error' && <button className="status-container__link" onClick={() => window.location.replace(localStorage.getItem('productUrl'))}>Nouvelle tentative</button>}
            <OSButton title="Revenir à mon portefeuille" size="large" variant={status === 'error' ? 'secondary' : 'primary'} link={title().link} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Callback;
