import React from 'react';
import Badge from './Badge';

const getBadge = (element, type, badgeText, colorBadge) => {
  let color = colorBadge;
  let text = badgeText;

  if (type === 'typologie') {
    if (element === 'Company') {
      color = 'info';
      text = 'Personne Morale';
    } else if (element === 'User') {
      color = 'grey';
      text = 'Personne Physique';
    }
  } else if (type === 'kyc_status') {
    if (element === 'verified') {
      color = 'success';
      text = 'KYC validé';
    } else if (element === 'pending' || element === 'processing' || element === 'unverified') {
      color = 'warning';
      text = 'KYC en cours de vérification';
    } else if (element === 'error') {
      color = 'error';
      text = 'KYC non complété';
    } else {
      color = 'white'
    }
  } else if (type === 'signable') {
	  if (element === 'signed') {
		  color = 'success';
		  text = 'Document signé';
	  } else if (element === 'pending') {
		  color = 'warning';
		  text = 'En attente de signature';
	  } else if (element === 'declined') {
		  color = 'error';
		  text = 'Signature annulée par le client';
	  } else if (element === 'expired') {
		  color = 'expired';
		  text = 'La demande de signature a expiré'
	  } else {
		  color = 'white'
	  }
  } else if (type === 'score') {
		if (element < 4) {
			color = 'error';
			text = `Score du profil: ${element} / 7`;
		} else {
			color = 'expired';
			text = `Score du profil: ${element} / 7`;
		}
  }
	if (type !== 'score') {
    badgeText && (text = badgeText);
	}
  colorBadge && (color = colorBadge);

  return <Badge color={color} key={text}>{text}</Badge>;
};

export default getBadge;
