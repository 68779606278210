import React, {useState} from 'react';
import {toast} from "react-toastify";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import AuthenticationNetwork from "../../../config/Network/AuthenticationNetwork.ts";
import AuthenticationStore from "../../../stores/AuthenticationStore.ts";

import {MyTextInput} from "../../Inputs";

import './style.scss';

const SignupForm = ({user, closeModal}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <p>Pour pouvoir profiter pleinement de la plateforme nous vous invitons à compléter les informations suivantes :</p>
      <Formik
        initialValues={{firstName: user.first_name, lastName: user.last_name}}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('Le Prénom doit être rempli'),
          lastName: Yup.string()
            .required('Le Nom doit être rempli')
        })}
        onSubmit={ async (values ) => {
          setIsLoading(true);
          const response = await AuthenticationNetwork.editUser({
            first_name: values.firstName,
            last_name: values.lastName,
            //referral_code: values.referrareferalCode.replace(/ /g, '').toUpperCase(),
          });
          closeModal('completeInfos');

          if (!response) {
            toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
              toastId: 'completeInfosErrorToast'
            });
          }
          if (response) {
            await AuthenticationStore.loadUserData(); // to reload the user's data
            setIsLoading(false);
            toast.success('Vos informations ont bien été enregistré', {
              toastId: 'completeInfosSuccessToast'
            });
          }
        }}
      >
        <Form className="complete-infos__container">
          <div className="complete-infos__inputs">
            <div>
              <MyTextInput
                label="Prénom"
                name="firstName"
                type="text"
                placeholder="Jane"
              />
            </div>

            <div>
              <MyTextInput
                label="Nom de famille"
                name="lastName"
                type="text"
                placeholder="Doe"
              />
            </div>
          </div>

          <div className="submit-container">
            <button className="submit-btn" type="submit" disabled={isLoading}>Enregistrer</button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
export default SignupForm;
