import React from "react"
import ContentLoader from "react-content-loader"

const FinaliseLoaderMobile = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 0 400 760"
    backgroundColor="#dddedf"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="10" y="68" rx="10" ry="10" width="95%" height="39%" />
    <rect x="10" y="400" rx="10" ry="10" width="95%" height="29%" />

  </ContentLoader>
)

export default FinaliseLoaderMobile