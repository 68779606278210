import React, {useState} from 'react';
import moment from "moment";
import {toast} from "react-toastify";
import {useSearchParams} from "react-router-dom";

import InstructionsContainer from "../../../../../components/instructionsContainer";
import MoneyHelper from "../../../../../Helpers/MoneyHelper";
import MixedTransactionNetwork from "../../../../../config/Network/MixedTransactionNetwork.ts";

import {livretpWiredatas} from "./datas";
import {CircularProgress} from "@mui/material";

import './style.scss';

const WireTransfer = ({wireRef, page, product, amount, notes}) => {
  const today = new Date();
  const [searchParams] = useSearchParams();
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [loading, setLoading] = useState(false);
  const transactionId = searchParams.get('transaction_id');

  const updateTransactionStatus = async () => {
    setLoading(true);
    const transaction = await MixedTransactionNetwork.updateTransaction(transactionId, page === 'livretp' ? 'livretp_transaction' : 'product', defaultProfile ? 'user' : 'company', notes);
    if (transaction.status === 'pending' || transaction.status === 'pending_scpi') {
      localStorage.removeItem('signedDocs');
      localStorage.removeItem('product');
      localStorage.removeItem('productUrl');
      page === 'livretp' ? window.location.replace(`/opportunites/livret-p/${transactionId}/finalise/paiement-status`) : window.location.replace(`/opportunites/${product.category_slug}/${product.slug}/finalise/paiement-status?status=pending&investmentAmount=${amount}`);
    }
    setLoading(false);
  }

  function wireDatas() {
    let data;
    let email;
    if (page === 'livretp') {
      data = livretpWiredatas;
      email = 'hello@livretp.fr'
    } else {
      data = product.rib;
      email = 'bonjour@openstone.com'
    }
    return {data, email};
  }
  const mailto = `mailto:${wireDatas().email}?subject=Virement Openstone pour ...&body=J'ai effectué un virement ce jour le ${moment(today).locale('FR').format('L')} d'un montant de ${MoneyHelper.formatEuro(amount)} ${page !== 'livretp' && `pour le produit ${product.name}`}.`;

  function renderClientRef() {
    if (!defaultProfile) {
      return `${company?.legal_name}`
    } else {
      return `${user?.first_name} ${user?.last_name}`
    }
  }

  return (
    <div ref={wireRef}>
      <InstructionsContainer title={"Instructions pour virement bancaire"} content={"Envoyez-nous votre confirmation de virement par email à"} link={mailto} linkTitle={wireDatas().email} />
      <div className="fields">
        {wireDatas().data.map(d => (
            <div className="field" key={d.title}>
              <div>
                <p className="field-name os-body2">{d.title}</p>
                {
                  d.title === 'Référence client' ? <p className="os-body2 field-content">{renderClientRef()}</p> : <p className="field-content os-body2">{d.content}</p>
                }
              </div>
              <button className="field-copy" onClick={() => {
                navigator.clipboard.writeText(d.title === 'Référence client' ? renderClientRef() : d.content);
                toast.success(`${d.title} copié`, {
                  toastId: `${d.title}_Toast`
                });
              }}>Copier</button>
            </div>
          )
        )}
      </div>
      <p className="os-body2">Envoyez-nous votre confirmation de virement par email à <a className="field-mail" href={mailto}>{wireDatas().email}</a></p>
      <button className="submit-btn" onClick={() => updateTransactionStatus()}>
        {loading ? (<>
          <CircularProgress color="inherit" />
          Envoi en cours...
        </> ) : 'J\'ai réalisé mon virement' }
      </button>
    </div>
  );
};

export default WireTransfer;
