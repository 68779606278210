import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Divider from "@mui/material/Divider";
import {toast} from "react-toastify";
import {parse} from "querystring";
import moment from "moment";

import OsCheckbox from "../../../../components/Form/OsCheckbox";
import OsSelect from "../../../../components/Form/OsSelect";

import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import {
  maritalStatuses,
  workCategories,
  pacsRegime,
  marriedRegime,
  ppeFunction,
} from "../choices";
import sortedCountries from "../../../../config/countries";
import OSButton from "../../../../components/Button";

const PersonnalInfos = ({user, handleStep, page, isCgp, company, step}) => {
  const [submitting, setSubmitting] = useState(false);

  const validationSchema = yup.object({
    email: yup.string().email('Format invalide').required('Requis'),
    firstName: yup.string().required('Requis'),
    lastName: yup.string().required('Requis'),
    gender: yup.string().required('Requis').nullable('Requis'),
    nationality: yup.string().required('Requis'),
    countryBirth: yup.string().required('Requis'),
	  cityOfBirth: yup.string().required('Requis'),
	  departmentOfBirth: yup.string().required('Requis').max(99, "Le département doit être composé de 2 chiffres"),
	  work: yup.string().required('Requis'),
	  workCategory: yup.string().required('Requis'),
	  workSector: yup.string().required('Requis'),
	  address: yup.string().required('Requis'),
	  zipCode: yup.string().required('Requis'),
	  city: yup.string().required('Requis'),
    birthDate: yup.date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());
          return result;
        })
        .max(new Date(Date.now() - 567648000000), "Vous devez être majeur")
        .typeError("Requis")
        .required(),
    peopleOnCharge: yup.number().required("Requis").nullable("Requis").min(0, "Si vous n'avez personne à votre charge, saisir 0"),
    maritalStatus: yup.string().required('Requis'),
    pacsRegime: yup.string()
        .when('maritalStatus', {
          is: (maritalStatus) => maritalStatus === 'Pacsé',
          then: yup.string()
              .required('Requis')
        }),
    marriedRegime: yup.string()
        .when('maritalStatus', {
          is: (maritalStatus) => maritalStatus === 'Marié',
          then: yup.string()
              .required('Requis')
        }),
    investorProfile: yup.string().required('Requis'),
    ppeFunction: yup.string()
        .when('PPE', {
          is: (PPE) => PPE === 'true',
          then: yup.string()
              .required('Requis')
        }),
    ppeCountry: yup.string()
        .when('PPE', {
          is: (PPE) => PPE === 'true',
          then: yup.string()
              .required('Requis')
        }),
  });
  const kycValidationSchema = yup.object({
    email: yup.string().email('Format invalide').required('Requis'),
    firstName: yup.string().required('Requis'),
    lastName: yup.string().required('Requis'),
    gender: yup.string().required('Requis').nullable('Requis'),
    nationality: yup.string().required('Requis'),
    countryBirth: yup.string().required('Requis'),
    cityOfBirth: yup.string().required('Requis'),
    departmentOfBirth: yup.string().required('Requis'),
    address: yup.string().required('Requis'),
    zipCode: yup.string().required('Requis'),
    city: yup.string().required('Requis'),
    birthDate: yup.date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());
          return result;
        })
        .typeError("Requis")
        .required(),
  });

  const formik= useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      gender: '',
      nationality: '',
      countryBirth: '',
      birthDate: '',
      cityOfBirth: '',
      departmentOfBirth: '',
      work: '',
      workCategory: '',
      workSector: '',
      address: '',
      zipCode: '',
      city: '',
      peopleOnCharge: '',
      maritalStatus: '',
      pacsRegime: '',
      marriedRegime: '',
      investorProfile: '',
      usPerson: [],
      PPE: '',
      ppeFunction: '',
      ppeCountry: '',
    },
    validationSchema: page === 'kyc' ? kycValidationSchema : validationSchema,
    onSubmit: async (values) => {
      const checkMaritalStatusType = () => {
        let maritalStatusType = '';
        if (values.pacsRegime === '' && values.marriedRegime === '') {
          maritalStatusType = '';
        } else if (values.pacsRegime !== '' && values.marriedRegime === '') {
          maritalStatusType = values.pacsRegime;
        } else if (values.pacsRegime === '' && values.marriedRegime !== '') {
          maritalStatusType = values.marriedRegime;
        }
        return maritalStatusType
      };
      setSubmitting(true);
      const valuesToSubmit = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        gender: values.gender,
        address_country: values.nationality,
        country_of_birth: values.countryBirth,
        date_of_birth: values.birthDate,
        city_of_birth: values.cityOfBirth,
        departement_of_birth: values.departmentOfBirth.toString(),
        work: values.work,
        work_category: values.workCategory,
        work_sector: values.workSector,
        address_line_1: values.address,
        address_zip_code: values.zipCode.toString(),
        address_city: values.city,
        people_on_charge: values.peopleOnCharge.toString(),
        marital_status: values.maritalStatus,
        marital_status_type: checkMaritalStatusType() ,
        investment_profile: values.investorProfile,
        us_person: values.usPerson[0],
        ppe: values.PPE[0],
        ppe_function: values.ppeFunction,
        ppe_country: values.ppeCountry
      }

      async function handleRequest() {
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord(valuesToSubmit, company.id, 'Company') : await CgpNetwork.updateRecord(valuesToSubmit, user.id, 'User');
        } else {
          page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id, valuesToSubmit) : await AuthenticationNetwork.editUser(valuesToSubmit);
        }
      }
      const response = handleRequest();
      if (response) {
        setSubmitting(false);
        toast.success('Vos informations personnelles ont bien été enregistrés', {
          toastId: 'completeInfosSuccessToast'
        });
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });

  useEffect(() => {
    const initialValues = {
      email: user.email || '',
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      gender: user.gender === "male" ? 0 : 1,
      nationality: user.address_country || '',
      countryBirth: user.country_of_birth || '',
      birthDate: user.date_of_birth ? moment(user.date_of_birth).format('yyyy-MM-DD') : '',
      cityOfBirth: user.city_of_birth || '',
      departmentOfBirth: user.departement_of_birth ||'',
      work: user.work || '',
      workCategory: user.work_category || '',
      workSector: user.work_sector || '',
      address: user.address_line_1 || '',
      zipCode: user.address_zip_code || '',
      city: user.address_city || '',
      peopleOnCharge: user.people_on_charge || '',
      maritalStatus: user.marital_status || '',
      pacsRegime: user.marital_status_type || '',
      marriedRegime: user.marital_status_type || '',
      investorProfile: user.investment_profile || '',
      usPerson: user.us_person || [],
      PPE: [user.ppe] || [],
      ppeFunction: user.ppe_function || '',
      ppeCountry: user.ppe_country || ''
    };

    formik.setValues(initialValues);
  }, [user]);

  return (
    <div>
      <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
        <h6 className="os-h6">Informations civiles</h6>
        <div className="user-survey-questions-container">
          <OsSelect
              formik={formik}
              name="gender"
              label="Civilité"
              options={[
                { value: 0, label: 'Monsieur' },
                { value: 1, label: 'Madame' },
              ]}
          />
          <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="Prénom"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant={"outlined"}
          />
          <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Nom de famille"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant={"outlined"}
          />
          <TextField
              fullWidth
              id="birthDate"
              name="birthDate"
              type="date"
              label="Date de naissance"
              value={formik.values.birthDate}
              onChange={formik.handleChange}
              error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
              helperText={formik.touched.birthDate && formik.errors.birthDate}
              variant={"outlined"}
              InputProps={{inputProps: { min: "1900-05-01", max: "2999-05-04"} }}
              InputLabelProps={{ shrink: true }}
          />
          <TextField
              fullWidth
              id="cityOfBirth"
              name="cityOfBirth"
              label="Ville de naissance"
              value={formik.values.cityOfBirth}
              onChange={formik.handleChange}
              error={formik.touched.cityOfBirth && Boolean(formik.errors.cityOfBirth)}
              helperText={formik.touched.cityOfBirth && formik.errors.cityOfBirth}
              variant={"outlined"}
          />
          <TextField
              fullWidth
              id="departmentOfBirth"
              name="departmentOfBirth"
              type="number"
              label="Département de naissance"
              value={formik.values.departmentOfBirth}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || (/^\d{0,2}$/.test(value) && Number(value) <= 99)) {
                  formik.handleChange(e);
                }
              }}
              error={formik.touched.departmentOfBirth && Boolean(formik.errors.departmentOfBirth)}
              helperText={formik.touched.departmentOfBirth && formik.errors.departmentOfBirth}
              variant={"outlined"}
              inputProps={{ maxLength: 2 }}
          />
          <div>
            <OsSelect
                formik={formik}
                name="countryBirth"
                label="Pays de naissance"
                options={sortedCountries.sort().map(country => ({
                  value: country.name,
                  label: country.name,
                }))}
            />
            <span className="error">{formik.touched.countryBirth && formik.errors.countryBirth}</span>
          </div>
          <div>
            <OsSelect
                formik={formik}
                name="nationality"
                label="Nationalité"
                options={sortedCountries.map(country => ({
                  value: country.name,
                  label: country.name,
                }))}
            />
            <span className="error">{formik.touched.nationality && formik.errors.nationality}</span>
          </div>
          <TextField
              fullWidth
              id="email"
              name="email"
              label="E-mail"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant={"outlined"}
          />

        </div>
        <Divider />

        {/* INFOS PROFESSIONNELLES */}
        {page !== 'kyc' && (
            <>
              <h6 className="os-h6">Informations professionnelles</h6>
              <div className="user-survey-questions-container">
                <TextField
                    fullWidth
                    id="work"
                    name="work"
                    label="Dernière profession"
                    value={formik.values.work}
                    onChange={formik.handleChange}
                    error={formik.touched.work && Boolean(formik.errors.work)}
                    helperText={formik.touched.work && formik.errors.work}
                    variant={"outlined"}
                />
                <div>
                  <OsSelect
                      formik={formik}
                      name="workCategory"
                      label="Catégorie"
                      options={workCategories.map(wc => ({
                        value: wc.value,
                        label: wc.label,
                      }))}
                  />
                  <span className="error">{formik.touched.workCategory && formik.errors.workCategory}</span>
                </div>
                <TextField
                    fullWidth
                    id="workSector"
                    name="workSector"
                    label="Secteur d'activité"
                    value={formik.values.workSector}
                    onChange={formik.handleChange}
                    error={formik.touched.workSector && Boolean(formik.errors.workSector)}
                    helperText={formik.touched.workSector && formik.errors.workSector}
                    variant={"outlined"}
                />
              </div>
              <Divider />

              {/* SITUATION FAMILIALE */}

              <h6 className="os-h6">Situation familiale</h6>
              <div className="user-survey-questions-container">
                <TextField
                    fullWidth
                    id="peopleOnCharge"
                    name="peopleOnCharge"
                    type="number"
                    label="Personnes à charge"
                    value={formik.values.peopleOnCharge}
                    onChange={formik.handleChange}
                    error={formik.touched.peopleOnCharge && Boolean(formik.errors.peopleOnCharge)}
                    helperText={formik.touched.peopleOnCharge && formik.errors.peopleOnCharge}
                    variant={"outlined"}
                />
                <div>
                  <OsSelect
                      formik={formik}
                      name="maritalStatus"
                      label="Situation maritale"
                      options={maritalStatuses.map(ms => ({
                        value: ms.value,
                        label: ms.label,
                      }))}
                  />
                  <span className="error">{formik.touched.maritalStatus && formik.errors.maritalStatus}</span>
                </div>
                {formik.values.maritalStatus === 'Pacsé' && (
                    <div>
                      <OsSelect
                          formik={formik}
                          name="pacsRegime"
                          label="Régime du PACS"
                          options={pacsRegime.map(pr => ({
                            value: pr.value,
                            label: pr.label,
                          }))}
                      />
                      <span className="error">{formik.touched.pacsRegime && formik.errors.pacsRegime}</span>
                    </div>
                )}
                {formik.values.maritalStatus === 'Marié' && (
                    <div>
                      <OsSelect
                          formik={formik}
                          name="marriedRegime"
                          label="Régime du marriage"
                          options={marriedRegime.map(mr => ({
                            value: mr.value,
                            label: mr.label,
                          }))}
                      />
                      <span className="error">{formik.touched.marriedRegime && formik.errors.marriedRegime}</span>
                    </div>
                )}
              </div>
              <Divider />
            </>
        )}

        {/* SITUATION FAMILIALE */}

        <h6 className="os-h6">Adresse fiscale</h6>
        <div className="user-survey-questions-container">
          <TextField
              fullWidth
              id="address"
              name="address"
              label="Adresse"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              variant={"outlined"}
              className="full-input"
          />
          <TextField
              fullWidth
              id="zipCode"
              name="zipCode"
              type="number"
              label="Code postal"
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
              variant={"outlined"}
          />
          <TextField
              fullWidth
              id="city"
              name="city"
              label="Ville"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              variant={"outlined"}
          />
        </div>


        {/* Profil invest */}
        {page !== 'kyc' && (
            <>
              <Divider />
              <div className="os-flex gap-8" style={{alignItems: 'center'}}>
                <h6 className="os-h6">Profil investisseur</h6>
              </div>
              <div className="user-survey-questions-container" style={{marginBottom: 24}}>
                <div>
                  <p className="os-body1">Quel type d'investisseur êtes-vous ?</p>
                  <OsSelect
                      formik={formik}
                      name="investorProfile"
                      options={[
                        { value: 'non-professional', label: 'Investisseur non-professionnel' },
                        { value: 'professional', label: 'Investisseur professionnel' },
                      ]}
                  />
                  <span className="error">{formik.touched.investorProfile && formik.errors.investorProfile}</span>
                </div>
              </div>
              <div style={{marginBottom: 24}}>
	              {formik.values.investorProfile === 'professional' && (
		              <div className="os-flex__column gap-16">
			              <div className="investor-profile-card">
				              <p className="info">
					              <strong>
						              Pour avoir la qualité de client professionnel au sens de l’article L. 533-16, pour tous les
						              services d&#39;investissement et tous les instruments financiers, vous devez être dans l’une
						              des catégories suivantes:
					              </strong>
				              </p>
				              <ul>
					              <li>Les établissements de crédit mentionnés à l'article L. 511-9;</li>
					              <li>Les entreprises d'investissement mentionnées à l'article L. 531-4;</li>
					              <li>Les autres établissements financiers agréés ou réglementés;</li>
					              <li>Les entreprises d'assurance et de réassurance mentionnées respectivement au premier alinéa de l'article L. 310-1 et à l'article L. 310-1-1 du code des assurances, les sociétés de groupe d'assurance mentionnées à l'article L. 322-1-2 du même code, les mutuelles et unions de mutuelles relevant du livre II du code de la mutualité, les unions mutualistes de groupe mentionnés à l'article L. 111-4-2 du même code, les institutions de prévoyance et leurs unions régies par le titre III du livre IX du code de la sécurité sociale ainsi que les sociétés de groupe assurantiel de protection sociale mentionnées à l'article L. 931-2-2 du même code;</li>
					              <li>Les placements collectifs mentionnés au I de l'article L. 214-1 ainsi que les sociétés de gestion de placements collectifs mentionnées à l'article L. 543-1;</li>
					              <li>Le fonds de réserve pour les retraites mentionné à l'article L. 135-6 du code de la sécurité sociale, les institutions de retraites professionnelles mentionnées à l'article L. 370-1 du code des assurances pour leurs opérations mentionnées à l'article L. 370-2 du même code, ainsi que les personnes morales administrant une institution de retraite professionnelle mentionnée à l'article 8 de l'ordonnance n° 2006-344 du 23 mars 2006 relative aux retraites professionnelles supplémentaires</li>
					              <li>Les personnes dont l'activité principale consiste à négocier pour compte propre des matières premières ou des instruments dérivés sur matières premières, mentionnés au j du 2° de l'article L. 531-2;</li>
					              <li>La Caisse des dépôts et consignations et les autres investisseurs institutionnels agréés ou réglementés.</li>
					              <li>L'Etat, la Caisse de la dette publique, la Caisse d'amortissement de la dette sociale, la Banque de France, l'Institut d'émission des départements d'outre-mer, l'Institut d'émission d'outre-mer;</li>
					              <li>Les autres investisseurs institutionnels dont l'activité principale consiste à investir dans des instruments financiers, et notamment les sociétés d'investissement mentionnées à l'article 6 de l'ordonnance du 2 novembre 1945, les sociétés de capital-risque mentionnées à l'article 1er de la loi n° 85-695 du 11 juillet 1985 et les sociétés financières d'innovation mentionnées au III de l'article 4 de la loi n° 72-650 du 11 juillet 1972;</li>
					              <li>Les entités de droit étranger qui sont équivalentes à celles mentionnées aux 1 à 4 ou qui ont un statut de client professionnel dans un autre Etat membre de l'Union européenne ou un autre Etat partie à l'accord sur l'Espace économique européen;</li>
					              <li>Les organismes financiers internationaux à caractère public auxquels la France ou tout autre Etat membre de l'Organisation de coopération et de développement économiques adhère;</li>
				              </ul>
			              </div>
			              <div className="investor-profile-card">
				              <p className="info">
					              <strong>
						              Ou vous devez remplir au moins deux des trois critères suivants, sur la base des états comptables individuels :
					              </strong>
				              </p>
				              <ul>
					              <li>Total du bilan égal ou supérieur à 20 millions d&#39;euros;</li>
					              <li>Chiffre d&#39;affaires net ou recettes nettes égaux ou supérieurs à 40 millions d&#39;euros;</li>
					              <li>Capitaux propres égaux ou supérieurs à 2 millions d&#39;euros;</li>
				              </ul>
			              </div>
		              </div>
	              )}
              </div>
              
              <Divider />

              {/* Profil invest */}

              <h6 className="os-h6">Statuts particuliers</h6>
              <div className="os-flex__column gap-16" style={{marginBottom: 24}}>
                <div className="radio-inputs">
                  <div style={{marginTop: 5}}>
                    <OsCheckbox
                        key="usPerson"
                        formik={formik}
                        name="usPerson"
                        value={true}
                        label="Cochez cette case si vous êtes un contribuable américain et que vous déclarez vos impôts aux USA."
                    />
                  </div>
                  <div>
                    {formik.values.usPerson[0] && (<p className="alert-text">Attention en déclarant être US Person, vous ne pouvez pas continuer la souscription</p>)}
                  </div>
                </div>
                <div className="radio-inputs">
                  <OsCheckbox
                      key="PPE"
                      formik={formik}
                      name="PPE"
                      value={true}
                      label="Cochez cette case si vous êtes une personne politiquement exposée."
                  />
                </div>
	              {
		              formik.values.PPE[1] && (
			              <>
				              <OsSelect
					              formik={formik}
					              name="ppeFunction"
					              label="Fonction"
					              options={ppeFunction.map(ppef => ({
						              value: ppef.value,
						              label: ppef.value,
					              }))}
				              />
				              <OsSelect
					              formik={formik}
					              name="ppeCountry"
					              label="Pays"
					              options={sortedCountries.map(country => ({
						              value: country.name,
						              label: country.name,
					              }))}
				              />
			              </>
		              )
	              }
              </div>
            </>
        )}
        <div className="user-survey-btn-container">
	        {step > 0 && (<OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium"/>)}
          {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
          <button disabled={submitting || formik.values.usPerson[0]} type="submit" className="user-survey-btn">Continuer</button>
        </div>
      </form>
    </div>
  );
};

export default PersonnalInfos;
