import React, {useEffect, useState} from 'react';

import PromotionsNetwork from "../../config/Network/PromotionsNetwork.ts";

import PromoCard from "../../components/card/PromoCard";
import AddCoupon from "../../Forms/Promotions/addCoupon";
import {ReactComponent as BusinessMan} from "./businessman.svg";
import {ReactComponent as Card} from "./card.svg";
import {ReactComponent as Gift} from "./gift.svg";

import EmptyContent from "../../components/empty-content/EmptyContent";
import ProductsLoader from "../../components/Loaders/Products";
import StepsExplainer from "../../components/StepsExplainer";

import './style.scss';

const Promotions = () => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [promotions, setPromotions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newCouponAdded, setNewCouponAdded] = useState(false);
  const user = useState(JSON.parse(localStorage.getItem('user')))[0];

  async function fetchPromotions() {
    const apiPromotions = await PromotionsNetwork.listPromotions(defaultProfile ? 'User' : 'Company');
    setPromotions(apiPromotions);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setNewCouponAdded(false);
  }

  const promotionsLoader = (
    <div className="os-flex gap-16 promotions-list">
      {[1, 2, 3, 4, 5, 6].map(e => (
        <div className="half-loader-element">
          <ProductsLoader/>
        </div>
        ))}
    </div>
  )

  const renderNotUsedPromotions = () => {
    if (loading || promotions === null) {
      return promotionsLoader;
    } else if (!loading && promotions.not_used_coupons.length > 0) {
      return promotions.not_used_coupons.map((p, index) => <PromoCard promotion={p} />)
    }
  }

  const renderUsedPromotions = () => {
    if (loading || promotions === null) {
      return promotionsLoader;
    } else if (!loading && promotions.used_coupons.length > 0) {
      return promotions.used_coupons.map(p => <PromoCard promotion={p} used={true}/>)
    } else if (!loading && promotions.used_coupons.length === 0) {
      return <EmptyContent cta="Voir les opportunites" text="Vous n'avez pas encore utilisé de promotions" />
    }
  }

  function checkIfCouponAdded(isAdded) {
    isAdded && setNewCouponAdded(true);
  }

  useEffect(() => {
    fetchPromotions();
  }, [newCouponAdded]);

  const customPromotion = {
    coupon: {
      key: user?.user_invitation?.code,
      value: "Invitez et gagnez 10€",
      description: "Gagnez un bonus pour chaque ami(e) qui ouvre son compte sur Openstone.",
    },
    related_product: "/parrainage"
  }

  const steps = [
    {
      title: "Étape 1",
      body: "Je copie le code promo",
      illustration: <BusinessMan />
    },
    {
      title: "Étape 2",
      body: "J'investis sur une opportunité Openstone",
      illustration: <Card />
    },
    {
      title: "Étape 3",
      body: "Une fois que la transaction est validée, la promotion est ajoutée à mon Livret P.",
      illustration: <Gift />
    }
  ]

  return (
    <>
      <div className="top-promotions-container">
        <h1>Promotions</h1>
        <StepsExplainer steps={steps}/>
        <div className="os-flex search-promotion-container">
          <p className="os-subtitle1">Vous avez un code promo qui n'est pas dans la liste ?</p>
          <AddCoupon func={checkIfCouponAdded}/>
        </div>
      </div>
      <div className="promotions-container">
        <h4 className="os-h4">Promotions du moment</h4>
        <div className="os-flex gap-32 promotions-list">
          {renderNotUsedPromotions()}
          <PromoCard promotion={customPromotion} isAd={true} />
        </div>
        <h4 className="os-h4">Mes promotions utilisées</h4>
        <div className="os-flex gap-32 promotions-list">
          {renderUsedPromotions()}
        </div>
      </div>
    </>
  );
};

export default Promotions;
