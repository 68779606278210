import React, {useEffect, useState} from 'react';
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import {useLocation, useParams} from "react-router";
import OsCard from "../../../../components/card/basic";
import {useNavigate} from "react-router-dom";

import {ChevronRight} from "@mui/icons-material";
import {FormControl , MenuItem, Select} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import UserTransactionNetwork from "../../../../config/Network/UserTransactionNetwork.ts";
import getBadge from "../../../../components/Badge/getBadge";
import renderChart from "../../../../components/User/chart";
import RenderTable from "../../../../components/User/renderTable";
import {ReactComponent as NoDocs} from "../../../../assets/Illustrations/no-documents.svg";
import EmptyContent from "../../../../components/empty-content/EmptyContent";
import DropdownButton from "../../../../components/Button/Dropdown";
import ProductInvestment from "../../../../components/Product/Investment";
import OSButton from "../../../../components/Button";
import DescriptionIcon from "@mui/icons-material/Description";

import "./style.scss";

const ClientView = () => {
  const {clientId} = useParams();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedOpportunity, setSelectedOpportunity] = useState('all');

  async function fetchClient() {
    const response = await CgpNetwork.getClient(clientId, state.listableType);
    setClient(response);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  }
  async function fetchMyTransactions() {
    const apiTransactions = await UserTransactionNetwork.myTransactions(state.listableType === 'User', clientId);
    setTransactions(apiTransactions);
    setTimeout(() => {
      setLoadingTransactions(false);
    }, 200)
  }

  useEffect(() => {
    if (state) {
      fetchClient();
      fetchMyTransactions();
    } else {
      window.location.replace('/cgp/clients');
    }
  }, []);

  const renderClient = () => {
    if (loading || client === null) {
      return 'Chargement...'
    } else if (!loading) {
      return client
    }
  }

	// eslint-disable-next-line no-lone-blocks
	{/*const checkProfileScore = (score) => {
    if ([1, 2, 3].includes(score)) {
        return 'error';
    } else if ([4, 5].includes(score)) {
        return 'warning';
    } else if ([6, 7].includes(score)) {
        return 'success';
    }
    return 'warning'; // Or some default class
  }*/}

  const clientInfos = () => {
    if (loading || client === null) {
      return 'Chargement...'
    } else if (!loading && client) {
      if (state.listableType === 'User') {
        return (
        <div className="os-flex">
          <div className="os-flex__column client-infos">
            <p className="os-body2"><strong>Nom:</strong> {client.name}</p>
            <p className="os-body2"><strong>Date de naissance:</strong> {client.date_of_birth}</p>
            <p className="os-body2"><strong>Adresse:</strong> {client.address_line_1}</p>
            <p className="os-body2"><strong>Ville:</strong> {client.address_city}</p>
            <p className="os-body2"><strong>Code postal:</strong> {client.address_zip_code}</p>
          </div>
        </div>
        )
      } else {
        return (
          <div className="os-flex">
            <div className="os-flex client-infos">
              <p className="os-body2"><strong>Dénomination légale:</strong> {client.name}</p>
              <p className="os-body2"><strong>Statut juridique:</strong> {client.business_type}</p>
              <p className="os-body2"><strong>Siren:</strong> {client.siren}</p>
              <p className="os-body2"><strong>Adresse:</strong> {client.address}</p>
              <p className="os-body2"><strong>Ville:</strong> {client.city}</p>
              <p className="os-body2"><strong>Code postal:</strong> {client.zip_code}</p>
            </div>
          </div>
        )
      }
    }
  }

  function checkDocumentType(documentType) {
    if (documentType === 'kyc' || documentType === 'identity') {
      return "Document d'identité"
    } else if (documentType === 'residency') {
      return "Justificatif de domicile"
    } else if (documentType === 'rib') {
      return  "Relevé d'identité bancaire"
    } else if (documentType === 'status') {
      return "Status"
    } else if (documentType === 'kbis') {
      return "KBIS"
    }
  }

  const clientUploads = () => {
    if (loading || client === null) {
      return 'Chargement...'
    } else if (!loading && renderClient().document_uploads?.length > 0) {
      return (
        <div className="os-flex client-uploads gap-16">
          {client?.document_uploads.map((up, index) => (
            <a className="product-document" href={up.url} target="_blank" rel="noreferrer" key={`${up.name}-${up.index}`}>
	            <div className="pdf-logo-container">
		            <DescriptionIcon />
	            </div>
	            <div className="os-flex product-document-name-container">
		            <div>
	                <p className="product-document-name os-body2">{checkDocumentType(up.document_type)}</p>
	                <span className={up.processed ? "success" : "warning"}>{up.processed ? "Vérifié" : "En cours de vérification"}</span>
		            </div>
                <ChevronRight/>
              </div>
            </a>
          ) )}
        </div>
      )
    } else if (!loading && renderClient().document_uploads?.length === 0) {
      return <EmptyContent illustration={<NoDocs />} text="Pas de justificatifs pour ce client" />
    }
  }

  const clientDocuments = () => {
    if (loading || client === null) {
      return 'Chargement...'
    } else if (!loading && renderClient()?.documents?.length > 0) {
      return (
        <>
          <OsCard title="Documents de souscription"
                  onClick={() => navigate(`${window.location.pathname}/documents`, {state: {clientId: clientId, clientType: state.listableType}})}
                  linkTitle="Voir tous les documents"
                  conditionForLink={renderClient()?.documents?.length > 6}
                  badges={[
                    {number: renderClient()?.documents.filter(doc => doc.status === 'pending').length, text: "En attente de signature", status: "pending"},
                    {number: renderClient()?.documents.filter(doc => doc.status === 'signed').length, text: "Signés", status: "signed"},
                    {number: renderClient()?.documents.filter(doc => doc.status === 'expired').length, text: "Expirés", status: "expired"}
                  ]}
          >
            <div className="os-flex client-documents">
              {client?.documents.slice(0, 6).map((doc) => (
                <a className="product-document" href={doc?.file?.url} target="_blank" rel="noreferrer" key={doc.title}>
	                <div className="pdf-logo-container">
		                <DescriptionIcon />
	                </div>
	                <div className="os-flex product-document-name-container">
		                <div>
	                    <p className="product-document-name os-body2">{doc.title}</p>
	                    <span className={doc.status === 'signed' ? "success" : "warning"}>{doc.status === "signed" ? `Signé le ${doc.signed_at}` : "Signature en attente"}</span>
		                </div>
                    <ChevronRight/>
                  </div>
                </a>
              ))}
            </div>
          </OsCard>
        </>
      )
    } else if (!loading && renderClient().documents.length === 0) {
      return <OsCard title="Documents"
                     onClick={() => navigate(`${window.location.pathname}/documents`, {state: {clientId: clientId, clientType: state.listableType}})}
                     linkTitle="Voir tous les documents"
                     conditionForLink={renderClient()?.documents?.length > 6}>
          <EmptyContent illustration={<NoDocs/>} text="Aucun documents à afficher" />
        </OsCard>
    }
  }

  const checkOpportunity = (event) => {
    const value = event.target.value;
    setSelectedOpportunity(value);
  }

  const renderInvestmentTable = () => {
		if (selectedOpportunity === 'all') {
			return RenderTable(loadingTransactions, transactions, 'cgp', renderClient(), state?.listableType, 'cgpClient')
		} else {
			return (
				<div className="cgp-investment-table">
					<ProductInvestment clientViewProduct={selectedOpportunity} clientType={state?.listableType}
														 page="clientView"/>
				</div>
			)
		}
	}

  return (
    <div className="clients-container">
      <OSButton title="Revenir à la liste des clients" fit size="medium" variant="primary-full" link="/cgp/clients" icon={<ArrowBackIcon />}/>
      <div className="os-flex clients-top-container">
        <div className="os-flex clients-top-container-btns">
          <h5 className="os-h5">Profil de {renderClient().name}</h5>
            <div className="os-flex gap-8 profile-badges-container">
                {getBadge(renderClient().kyc_status, 'kyc_status')}
                {client && client.profile_score > 0 && (
                    <div className="os-flex gap-8">
                        {getBadge(`${client.profile_score}`, 'score')}
                    </div>
                )}
            </div>
        </div>
        <div className="os-flex">
          <DropdownButton
            title="Ajouter un investissement"
            variant="primary"
            size="large"
            items={renderClient()?.available_products?.map(product => ({
              label: product.name,
              onClick: () => navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.slug}/finalise`, {state: {clientId: clientId, clientType: state.listableType}})
            }))}
						disabled={loading || client === null}
          />
        </div>
      </div>
      <div className="os-flex client-flex-container">
        <div className="client-infos-container">
          <OsCard title="Informations principales"
                  link={state?.listableType !== 'Company' ? `${window.location.pathname}/personne-physique/edition` : `${window.location.pathname}/personne-morale/edition`}
                  linkTitle="Éditer"
                  conditionForLink={true}
          >
            {clientInfos()}
          </OsCard>
        </div>
        <div className="clients-uploads-container">
	        {renderChart(loading, client, 'cgp')}
        </div>
      </div>
	    <div style={{marginBottom: 32}}>
		    <OsCard title="Justificatifs"
		            onClick={() => {
			            if (state?.listableType === 'Company') {
				            localStorage.setItem('CompanySurveyStep', '4');
				            navigate(`${window.location.pathname}/personne-morale/edition`)
			            } else {
				            localStorage.setItem('UserSurveyStep', '3');
				            navigate(`${window.location.pathname}/personne-physique/edition`)
			            }
		            }}
		            linkTitle="Ajouter"
		            conditionForLink={true}>
			    {clientUploads()}
		    </OsCard>
	    </div>

        <h4 className="os-subtitle1">Synthèse des investissements</h4>
        {/*<div>
		    <h4 className="os-subtitle1">Synthèse des investissements</h4>
		    {(renderClient()?.fund_shares?.total > 0) && (
			    <div className="investments-form">
				    <FormControl>
					    <Select
						    labelId="demo-simple-select-label"
						    id="demo-simple-select"
						    value={selectedOpportunity || ''}
						    onChange={event => checkOpportunity(event)}>
						    <MenuItem value={'all'}>Tous les investissements</MenuItem>
						    {renderClient()?.available_products?.map(p =>
							    <MenuItem value={p}>{p.name}</MenuItem>
						    )}
					    </Select>
				    </FormControl>
			    </div>
		    )}
	    </div>*/}
      <div className="os-flex gap-24" style={{marginBottom: 32}}>
        {!loading && client && renderInvestmentTable()}
      </div>
      {clientDocuments()}
    </div>
  );
};

export default ClientView;
