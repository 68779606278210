import React, { useEffect } from "react";
import { useSetState } from "react-use";
import Joyride, { STATUS } from "react-joyride";
import CloseIcon from '@mui/icons-material/Close';

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";

import './style.scss';

const OnBoarding = ({user}) => {
    useEffect( () => {
        if (user.home_tour === "complete" || user.home_tour === 'skipped') {
            setState({
                run: false,
            });
        } else {
            setState({
                run: true,
            });
        }
    }, [user])

    interface State {
        run: boolean;
        steps: Step[];
    }
    const [{ run, steps }, setState] = useSetState({
        run: false,
        steps: [
            {
                content: <><h3 className="os-h3">Bienvenue sur Openstone</h3><p className="os-body1">Nous sommes là pour vous guider sur votre plateforme d’investissement dédiée à l’immobilier. Cela ne prendra que quelques secondes. On y va ?</p></>,
                locale: { skip: <strong aria-label="skip">Je découvre seul.</strong>, back: 'Précédent', next: 'C’est parti !'} ,
                placement: 'center',
                target: 'body',
                styles: {
                    options: {
                        width: 700,
                    },
                },
            },
            {
                content: <><h4 className="os-h4">Suivi des investissements</h4><p className="os-body1">Vous trouverez ici le suivi des différents produits dans lesquels vous avez investi. </p></>,
                styles: {
                    options: {
                        width: 350,
                    },
                },
                locale: { skip: <CloseIcon className="tour-close"/>,back: 'Précédent', next:'Suivant'},
                target: '.table-card',
                placement: 'right',
                tittle:'La répartition',
                // Condition pour afficher la step uniquement si la classe 'table-card' est présente dans le DOM
                disable: document.querySelector('.table-card') === null,
            },
            {
                content: <p className="os-body1">Ici, vous trouverez quelques unes des opportunités sélectionnées pour vous.</p>,
                placement: 'auto',
                styles: {
                    options: {
                        width: 500,
                    },
                },
                target: '.mySwiper',
                locale: { skip: <CloseIcon className="tour-close"/>,back: 'Précédent', next:'Suivant'},
                title: <h4 className="os-h4" style={{marginBottom: 0}}>Nos opportunités</h4>
            },
            {
                content: <p className="os-body1">En cliquant sur ce bouton, vous accédez à l’ensemble des opportunités disponibles.</p>,
                placement: 'auto',
                styles: {
                    options: {
                        width: 500,
                    },
                },
                target: '.joyride1',
                locale: { skip: <CloseIcon className="tour-close"/>,back: 'Précédent', next:'Suivant'},
                title:  <h4 className="os-h4" style={{marginBottom: 0}}>Un clic pour toutes les voir</h4>
            },
            {
                content: <p className="os-body1">Vous pouvez également accéder à l’ensemble des opportunités, par catégories, en cliquant ici</p>,
                placement: 'right',
                target: '.joyride',
                locale: { skip: <CloseIcon className="tour-close"/>,back: 'Précédent', next:'Suivant'},
                title: <h4 className="os-h4" style={{marginBottom: 0}}>Des opportunités au menu !</h4>,
            },
            {
                content: <p className="os-body1">Pour toutes remarques ou questions sur nos produits. N’hésitez pas à nous contacter via notre chat ou par email !</p>,
                placement: 'center',
                target: 'body',
                title: <h4 className="os-h4" style={{marginBottom: 0}}>À vous de jouer !</h4>,
                locale: { skip: <CloseIcon className="tour-close"/>,back: 'Précédent', last: 'Fin'},
                styles: {
                    options: {
                        width: 600,
                    },
                },
            }
        ],
    });

    const handleJoyrideCallback = async (data) => {
        const { status, action } = data;
        if (STATUS.FINISHED === status) {
            await AuthenticationNetwork.editUser({ home_tour: "complete" });
        } else if (STATUS.SKIPPED === status || action === 'close') {
            await AuthenticationNetwork.editUser({ home_tour: "skipped" });
        }
    };

    return (
        <Joyride
            steps={steps}
            debug={false}
            continuous
            showSkipButton
            hideCloseButton
            disableOverlayClose={true}
            scrollOffset={0}
            callback={data => handleJoyrideCallback(data)}
            run={run}
            styles={{
                options: {
                    primaryColor: '#FFAB00'
                }
            }}>
        </Joyride>
    )
};

export default OnBoarding;
