const livretpWiredatas = [
  {
    title: 'IBAN',
    content: 'FR76 4414 9000 0100 0017 1322 564'
  },
  {
    title: 'BIC',
    content: 'ESCBFRPPXXX'
  },
  {
    title: 'Référence client',
    content: 'Nom / Prénom ou Dénomiation Sociale - SIREN'
  },
  {
    title: 'Banque',
    content: 'Banque Wormser Frères'
  },
  {
    title: 'Adresse banque',
    content: '13 Boulevard Haussmann, 75009 Paris'
  },
  {
    title: 'Bénéficiaire',
    content: 'Livret P.'
  },
  {
    title: 'Adresse Livret P.',
    content: "6 rue d'Armaillé 75017 Paris"
  },
];

export {livretpWiredatas};
