import Network from './Network.ts';

class InvestmentNetwork extends Network {
  async list(recordId: string, for_type: string, product_id: string, product_type: string,transaction_status: string, transaction_type: string, date: string, start_date: string, end_date: string, page: string, per_page: string) {
    let extraFilters = {};
    if (transaction_status) {
      extraFilters = { ...extraFilters, transaction_status };
    }
    if (transaction_type) {
      extraFilters = { ...extraFilters, transaction_type };
    }
    if (date) {
      extraFilters = { ...extraFilters, date };
      if (date === 'custom') {
        extraFilters = { ...extraFilters, start_date, end_date };
      }
    }
    return await this.get('/investments', {
      for_type,
      product_id: product_id,
      product_type: product_type,
      page: page,
      per_page: per_page,
      record_id: recordId,
      ...extraFilters,
    });
  }

  async transfertlp(transaction_id: string, amount: string, transaction_type: string) {
    return await this.post('/investments/lp_transfer', {
      transaction_id: transaction_id,
      amount: amount,
      transaction_type: transaction_type,
    });
  }
}

export default new InvestmentNetwork();
