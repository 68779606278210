import React from 'react';

import OSButton from "../Button";

import Images from "../../assets/images";

import './style.scss';

const Footer = () => {
	return (
		<div className="os-flex__column gap-32 os-footer">
			<div className="os-flex os-footer-top">
				<div className="os-flex os-footer-left gap-40">
					<div className="company-infos">
						<img className="os-footer-logo" src={Images.brand.squareLogo} alt="openstone square Logo"/>
						<br />
						<a className="footer-email" href="mailto:bonjour@openstone.com">bonjour@openstone.com</a>
						<div className="company-location">
							<p>Station F - 5 parvis Alain Turing</p>
							<p>75013 Paris</p>
						</div>
					</div>
					<div className="legal-infos">
						<p className="legal-title os-subtitle2">Informations légales</p>
						<p>Politique de confidentialité</p>
						<p>Mentions légales</p>
					</div>
				</div>
				<div className="os-footer-right">
					<div className="contact-card">
						<img className="contact-card-img" src={Images.charles} alt="" />
						<p className="os-subtitle2" style={{margin: '4px 0 -10px 0'}}>Contacter un conseiller</p>
						<p className="os-body2">Disponible du lundi au vendredi de 9h à 19h</p>
						<OSButton title="Prendre rendez-vous" size="medium" fit variant="primary-full" link="https://meetings-eu1.hubspot.com/charles-antoine-lamoureux/openstone" target />
					</div>
				</div>
			</div>
			<div className="os-flex os-footer-bottom">
				<p className="os-body2">Openstone est une marque d'Innovative Finance SAS, société de droit français immatriculée sous le numéro 90154976600011 et domiciliée 6 rue d'Armaillé, 75017 Paris. Innovative Finance est un Conseiller en Investissements Financiers (CIF) enregistré auprès de l'Organisme pour le Registre Unique des Intermédiaires en Assurance, Banque et Finance (ORIAS) sous le numéro 23002459. Vous pouvez vérifier cette inscription sur le site de l'ORIAS, https://orias.fr. Innovative Finance est membre de la Chambre Nationale du Conseil en Gestion de Patrimoine (CNCGP), association agréée par l'Autorité des Marchés Financiers (AMF) dont l'adresse postale est : 17, Place de la Bourse 75082 Paris cedex 02 et adresse internet : www.amf-france.org
					
					Les performances passées ne garantissent pas les résultats futurs. Les rendements historiques, les rendements attendus ou les projections de probabilité peuvent ne pas refléter les performances futures réelles. Tous les titres comportent des risques et peuvent entraîner des pertes importantes.
					
					Les investissements alternatifs dans les placements privés sont très peu liquides, spéculatifs et comportent un degré élevé de risque. Les performances passées ne préjugent pas des résultats futurs. Les investisseurs peuvent ne pas récupérer l'argent initialement investi et ceux qui ne peuvent pas se permettre de perdre la totalité de leur investissement ne devraient pas investir. Avant d'investir, il convient d'examiner attentivement la documentation relative à chaque fonds pour obtenir des informations détaillées sur les risques, les frais et les dépenses potentiels. La valeur d'un investissement peut varier à la hausse comme à la baisse. Un investissement dans un fonds de private equity ("PE") ou un véhicule d'investissement tel qu'Openstone SA, enregistré sous le numéro 98307779300013 et situé au 61 rue de Lyon, 75012 Paris, n'est pas la même chose qu'un dépôt auprès d'une institution bancaire. Les investisseurs reçoivent des participations illiquides et/ou restreintes qui peuvent être soumises à des exigences de période de détention et/ou à des problèmes de liquidité. Les investisseurs qui ne peuvent pas conserver un investissement à long terme (au moins 10 ans) ne devraient pas investir. Dans le cadre de la stratégie d'investissement la plus judicieuse, le capital-investissement ne doit constituer qu'une partie de votre portefeuille d'investissement global.
				</p>
			</div>
		</div>
	);
};

export default Footer;
