import React from 'react';
import {toast} from "react-toastify";

import "./style.scss";

function changeProfile(company, type) {
  if (type === 'company') {
    localStorage.setItem('defaultProfile', 'false');
    localStorage.setItem('company', JSON.stringify(company));
    toast.success(`Vous êtes désormais sous le compte de la société ${company.legal_name}`, {
      toastId: `signedDocument-${company.id}`
    });
  } else {
    localStorage.setItem('defaultProfile', 'true');
    localStorage.removeItem('company');
    toast.success("Vous êtes désormais sous votre compte personnel", {
      toastId: 'signedDocument-user'
    });
  }
  localStorage.removeItem('product');
  localStorage.removeItem('productUrl');
  setTimeout(() => {
    window.location.replace('/overview');
  }, 700)
}

const ProfileAvatar = ({profile, main, menu}) => {
  return (
    <div className={`os-profile-avatar ${(main && menu) && 'os-profile-avatar__border'}`} onClick={() => !main && changeProfile(profile, 'user')}>
      <div className={`os-current-profile os-current-profile__yellow ${(main && menu) && 'os-current-profile__larger'}`}>
        {`${profile.first_name?.charAt(0)}${profile.last_name?.charAt(0)}`}
      </div>
      {menu && <div className="os-flex os-current-profile-infos">
        <span className="os-subtitle2">{profile.full_name}</span>
        {!main ? <span className="os-current-profile__link" onClick={() => !main && changeProfile(profile, 'user')}>Gérer</span> : <a href="/profile" className="os-current-profile__link">Voir mon profil</a>}
      </div>}
    </div>
  );
};

const OtherProfileAvatar = ({profile, index, menu, main}) => {
  function handleColors() {
    let color;
    switch (index) {
      case 0:
        color = 'blue';
        break;
      case 1:
        color = 'green';
        break;
      case 2:
        color = 'red';
        break;
      default:
        color = 'secondary';
        break;
    }
    return color;
  }

  return (
    <div className={`os-profile-avatar ${(main && menu) && 'os-profile-avatar__border'} ${(menu) && 'os-profile-avatar__menu'}`} onClick={() => !main && changeProfile(profile, 'company')}>
      <div className={`os-current-profile os-current-profile__${handleColors()} ${(main && menu) && 'os-current-profile__larger'}`}>
        {profile?.legal_name?.charAt(0).toUpperCase()}
      </div>
      {menu &&
        <div className="os-flex os-current-profile-infos">
          <span className="os-body2" style={{fontWeight: 600}}>{profile?.legal_name}</span>
          {!main ? <span className="os-current-profile__link" onClick={() => !main && changeProfile(profile, 'company')}>Gérer</span> : <a href="/profile" className="os-current-profile__link">Voir mon profil</a>}
        </div>}
    </div>
  );
};

export {ProfileAvatar, OtherProfileAvatar};
