import React, {useEffect, useState} from 'react';
import ImageUpload from "../../../../components/ImageUpload";
import {CircularProgress} from "@mui/material";
import OSButton from "../../../../components/Button";

const Uploads = ({user, handleStep}) => {
  const [submitting, setSubmitting] = useState(false);
  const [upload, setUpload] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [iDCount, setIDCount] = useState(0);
  const [residentialCount, setResidentialCount] = useState(0);
  const [ribCount, setRibCount] = useState(0);

  useEffect(() => {
    if (nextStep) {
      handleStep(true);
      window.scrollTo(0, 0);
    }
  }, [nextStep])

  useEffect(() => {
    setIDCount(user.kyc_verification_status === 'verified' && 1);
  }, [user])

  function validUpload() {
    (iDCount > 0 && residentialCount > 0 && ribCount > 0) && setUpload(true);
  }

  function renderButton() {
    if (iDCount === 0 && residentialCount === 0) {
      return <button disabled={true} className="submit-btn">Envoyer les documents</button>
    } else if (submitting) {
      return <button disabled={submitting} className="submit-btn">
        <CircularProgress color="inherit" />
        Documents en cours d'envoi
      </button>
    } else {
      return <button disabled={!(iDCount > 0 && residentialCount > 0 && ribCount > 0)} className="submit-btn" onClick={() => validUpload()}>Envoyer les documents</button>
    }
  }

  return (
    <div>
      {user.kyc_verification_status !== 'verified' && (
        <>
          <h6 className="os-h6">Document d'identité</h6>
          <ImageUpload userSurveyCheck={setIDCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="userSurveyIdentity" documentType="identity" record={user}/>
        </>
      )}
      <>
        <h6 className="os-h6">Justificatif de domicile de moins de 3 mois</h6>
        <ImageUpload userSurveyCheck={setResidentialCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="residency" record={user}/>
      </>
      <>
        <h6 className="os-h6">RIB - Relevé d'identité bancaire</h6>
        <ImageUpload userSurveyCheck={setRibCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="rib" record={user}/>
      </>
	    <div className="user-survey-btn-container">
		    <OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium"/>
		    {renderButton()}
	    </div>
    </div>
  );
};

export default Uploads;
