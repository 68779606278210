import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
// components
import Scrollbar from '../scrollbar';
import { TableHeadCustom } from './index.js';
import MoneyHelper from "../../Helpers/MoneyHelper";
import React from "react";
import {useNavigate} from "react-router-dom";
import OSButton from "../Button";

import './myTable.scss';

// ----------------------------------------------------------------------

DashboardTable.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function DashboardTable({ title, subheader, tableData, tableLabels, page, client, clientType, ...other}) {
  return (
    <Card {...other} >
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />
            <TableBody>
              {tableData.map((row) =>
                row.name !== 'Livret P.' && (<DashboardTableRow key={`tableCell-${row.id}`} row={row} page={page} client={client} clientType={clientType}/>)
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

DashboardTableRow.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
  }),
};

function DashboardTableRow({ row, page, client, clientType}) {
  const navigate = useNavigate();
  function goToInvestments(navigate, row) {
    if (page === 'cgpClient') {
      row.name === 'Livret P' ? navigate(`${window.location.pathname}/investissement/${row.category_slug}/${row.category_slug}`, {state: {id: row.id, clientId: client?.id.toString(), clientType: clientType}}) :
        navigate(`${window.location.pathname}/investissement/${row.category_slug}/${row.slug}`, {state: {id: row.id, clientId: client.id.toString(), clientType: clientType}})
    } else {
      row.name === 'Livret P' ? navigate(`/opportunites/livret-p/my-investment`, {state: {id: row.id}})
        : navigate(`/opportunites/${row.category_slug}/${row.slug}/my-investment`, {state: {id: row.id}});
    }
  }

  return (row.balance > 0 || row.has_invested) && (
    <>
      <TableRow>
        <TableCell>
          <span className="ds_category_name">{row.name}</span>
        </TableCell>
        <TableCell>
          <span className="ds_category_name">{MoneyHelper.formatEuro(row.balance)}</span>
        </TableCell>
          <TableCell>
              <span className="ds_category_name">{MoneyHelper.formatEuro(row.pending_balance)}</span>
          </TableCell>
        <TableCell>
          <OSButton onClick={() => goToInvestments(navigate, row)} variant="no_border-info" fit size="small" title="Voir le détail" />
        </TableCell>
      </TableRow>
    </>
  );
}
