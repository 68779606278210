/**
 * Move an item in array from an index to another
 * @api
 * @param {int} fromYear
 * @param {int} toYear
 * @returns {array} range of years in an array
 */
export function generateYearsRange(fromYear, toYear) {
  const range = Math.abs(toYear - fromYear) + 1;
  return Array.from(new Array(range), (val, index) => index + fromYear);
}

/**
 * Concat 2 strings
 * @api
 * @param {string} defaultClasses
 * @param {string} additionalClasses
 * @returns {string}
 */
export function addAdditionalClasses(defaultClasses, additionalClasses) {
  return defaultClasses
    .split(' ')
    .concat(additionalClasses)
    .filter(Boolean)
    .join(' ');
}

/**
 * Build array of years
 * @api
 * @param {array} years
 * @returns {array}
 */
export function buildYearsObject(years) {
  return years.map((year) => ({ id: year, displayedText: year }));
}

/**
 * Move an item in array from an index to another
 * @api
 * @param {array} array
 * @param {int} fromIndex
 * @param {int} toIndex
 */
export function arrayMove(array, fromIndex, toIndex) {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);
}

/**
 * Test if the given obj is type of string.
 *
 * @api private
 * @param {Object} item
 * @return {boolean}
 */
export function isString(item) {
  return typeof item === 'string';
}
