class MoneyHelper {
    formatCurrency(amount, options = {}) {
        const {
            locale = 'fr-FR',
            currency = 'EUR',
            removeDecimals = true, // Default to removing decimals
            maximumFractionDigits = 0,
            minimumFractionDigits = 0,
        } = options;

        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: removeDecimals ? 0 : minimumFractionDigits,
            maximumFractionDigits: removeDecimals ? 0 : maximumFractionDigits,
        });

        let result = formatter.format(amount || 0);

        // Replace the period with a comma for European-style formatting
        if (locale.startsWith('fr')) {
            result = result.replace('.', ',');
        }

        return result;
    }

    formatEuro(amount, removeDecimals = true, maximumFractionDigits = 0, minimumFractionDigits = 0) {
        return this.formatCurrency(amount, {
            locale: 'fr-FR',
            currency: 'EUR',
            removeDecimals,
            maximumFractionDigits,
            minimumFractionDigits,
        });
    }
}

export default new MoneyHelper();
