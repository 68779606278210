import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const OsTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#637381',
    maxWidth: 620,
    fontSize: 12,
    padding: '10px 12px 14px',
    filter: 'drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.24))',
    borderRadius: 4,
    lineHeight: 1.8,
	  cursor: 'pointer'
  },
}));

export default OsTooltip;

