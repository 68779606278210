import React from 'react';

const StepsExplainer = ({steps}) => {
  return (
    <div className="os-flex gap-32 promotions-explanations-container">
      {steps.map(s => (
        <div className="promotions-explanations-step os-flex gap-16" key={s.title}>
          {s.illustration}
          <div className="step">
            <p className="os-subtitle1">{s.title}</p>
            <p className="os-body2">{s.body}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepsExplainer;
