import Network from './Network.ts';

class DocumentNetwork extends Network {
  async get_documents(productId: string, recordId: string) {
    return await this.get(`/user_documents`, {
      product_id: productId,
      record_id: recordId
    });
  }

  async get_document(id:string) {
    return await this.get(`/user_documents/${id}`);
  }

  async my_documents(contractType: string) {
    return await this.get('/user_documents/my_documents', {
      contract_type: contractType
    });
  }

  async my_bs(productId, recordId) {
    return await this.get(`/user_documents/my_bs${productId !== null ? `?product_id=${productId}` : ''}`, {record_id: recordId});
  }

  async my_br(productId) {
    return await this.get(`/user_documents/my_br`, {
      product_id: productId
    });
  }

  // Company documents
  async get_company_documents(productId: string, recordId: string) {
    return await this.get(`/company_documents`, {
      product_id: productId,
      record_id: recordId
    });
  }

  async my_company_documents(contractType: string) {
    return await this.get('/company_documents/my_documents', {
      contract_type: contractType
    });
  }

  async my_company_bs(productId, recordId) {
    return await this.get(`/company_documents/my_bs${productId !== null ? `?product_id=${productId}` : ''}`, {record_id: recordId});
  }

  async my_company_br(produtId) {
    return await this.get(`/company_documents/my_br`, {
      product_id: produtId
    });
  }

  async get_company_document(id:string) {
    return await this.get(`/company_documents/${id}`);
  }
}

export default new DocumentNetwork();
