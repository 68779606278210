import React from 'react';
import {useLocation} from "react-router";
import {Helmet} from "react-helmet";
import Images from "../../../../assets/images";

import './style.scss';

const Card = ({product}) => {
  const {state} = useLocation();
	
  function checkCategory() {
    if (state?.page === 'livretp') {
      return '47164378:publickey_jdOPgDC7QtQUy3dU0EwUGsixw008rRd2ZtrYErBcL0ibB'
    } else if (state?.page === 'Alternatifs') {
      if (product?.id === 10) {
        return '70374178:publickey_9xnIOYzrmwmMNkRkXjLcNZ8GUeJdNC5OyeyX7526c5Xbz'
      } else if (product?.id === 5) {
        return '46427234:publickey_gRy0C0ZaXYvXOQB5l0AUcupF6d3SwroDyGRC546hKKjna'
      } else if (product?.id === 81) {
	      return '47164378:publickey_jdOPgDC7QtQUy3dU0EwUGsixw008rRd2ZtrYErBcL0ibB'
      } else {
				return '61563776:publickey_sgFsyrYD5C8gLKNoJCvknHC9R3tgF7c3q51Pq3XJBHolr'
      }
    } else {
			return '61563776:publickey_sgFsyrYD5C8gLKNoJCvknHC9R3tgF7c3q51Pq3XJBHolr'
    }
  }
  return (
    <>
      <Helmet>
        <title>Opportunités | Openstone</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <script type="text/javascript"
                src="https://static.lyra.com/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"
                kr-public-key={checkCategory()}
                kr-post-url-success={state?.page === 'livretp' ? "https://api-v2.livretp.fr/lyra/callback_livretp" : "https://api-v2.livretp.fr/lyra/callback_products"} />

        <link rel="stylesheet" href="https://static.lyra.com/static/js/krypton-client/V4.0/ext/neon-reset.css" />
        <script type="text/javascript" src="https://static.lyra.com/static/js/krypton-client/V4.0/ext/neon.js" />
      </Helmet>
      <div className="card-container">
        <div className="card-title-container">
          <h3 className="os-h3">Paiement par carte</h3>
          <img className="secured-img" src={Images.secured} alt=""/>
        </div>
        <div className="kr-embedded" kr-form-token={state?.token.toString()} />
      </div>
    </>
  );
};

export default Card;
