import React from 'react';
import PropTypes from 'prop-types';

import './Chips.scss';

import { addAdditionalClasses } from '../../utils/utils';

/**
 * A simple chip component.
 *
 * @param {string} children - The content to display inside the chip.
 * @param {boolean} colored - Whether to display the chip with a colored background or not.
 * @param {boolean} transparent - Whether to display the chip with a transparent background or not.
 * @param {boolean} clickable - Whether to display the chip with a clickable behavior.
 * @param {boolean} selected - Whether to display the chip with the selected behavior.
 */
const Chips = ({ children, colored, transparent, element, clickable, selected }) => {
  const classNames = addAdditionalClasses('os-chips os-body2', [
    colored && 'os-chips--colored',
    transparent && 'os-chips--transparent',
    element === 'slider' && 'os-chips--slider',
    clickable && 'os-chips--clickable',
    selected && 'os-chips--selected'
  ]);

  return <div className={classNames}>{children}</div>;
};

Chips.propTypes = {
  children: PropTypes.node.isRequired,
  colored: PropTypes.bool,
  transparent: PropTypes.bool,
  clickable: PropTypes.bool,
  selected: PropTypes.bool
};

Chips.defaultProps = {
  colored: false,
  transparent: false,
  clickable: false,
  selected: false,
};

export default Chips;
