import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import fr from 'react-phone-input-2/lang/fr.json';
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

import authenticationStore from "../../stores/AuthenticationStore.ts";

import {countries, preferredCountries} from './countries';
import Otp from "../Otp";
import OSButton from "../Button";
import {CircularProgress} from "@mui/material";

import './style.scss';

const Phone = ({user, page, recordType, redirectUrl}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showError, setShowError] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const CustomToast = ({error, closeToast}) => (
    <>
      <p>{error}</p>
      <OSButton variant="primary" size="small" title="Inscription" link="/sign-up" />
      <CloseIcon onClick={() => closeToast()} className="product-finalise-close"/>
    </>
  );

  const requestOtp = async () => {
    setLoading(true);
    const response = await authenticationStore.requestOTP(
      phoneNumber,
      (page !== 'auth' && user?.id) && user?.id,
    );

    if (response) {
      toast.success("Un code de validation vient d'être envoyé", {
        toastId: 'OtpSuccessToast'
      });
      setShowOtp(true);
      setLoading(false);
    } else {
      toast.error('Ce numéro de téléphone est déjà associé à un compte, veuillez vous connecter via téléphone.', {
        toastId: 'OtpErrorToast'
      });
      setLoading(false);
    }
  };

  const requestOtpWeb = async () => {
    setLoading(true);
    const response = await authenticationStore.requestOTPWeb(
      phoneNumber,
      recordType
    );
    if (response.error) {
      setShowError(false);
      setLoading(false);
      toast.error(<CustomToast error={response.error}/>, {
        toastId: 'OtpErrorToast'
      });
    } else if (!response.error) {
      setShowOtp(true);
      setLoading(false);
      toast.success("Un code de validation vient d'être envoyé", {
        toastId: 'OtpSuccessToast'
      });
    }
  };

  const handleFormSubmit = async () => {
    page === 'auth' || 'signUp' ? await requestOtpWeb() : await requestOtp();
  };

  return (
    <>
      <div className="phone-input-container">
        <div className="phone-form">
          <>
            <PhoneInput
              inputProps={{
                name: 'N. de Téléphone',
                required: true,
                autoFocus: true
              }}
              specialLabel="Numéro de téléphone"
              defaultErrorMessage="Veuillez, entrer un numéro valide"
              localization={fr}
              country={'fr'}
              onlyCountries={countries}
              preferredCountries={preferredCountries}
              placeholder='N de téléphone'
              value={phoneNumber}
              countryCodeEditable={false}
              onChange={(value, country: any, e, formattedValue) => {
                setShowOtp(false);
                const {format, dialCode} = country;
                if (dialCode === '590' || dialCode === '596' || dialCode === '262') {
                  if (16 === formattedValue?.length && (value.startsWith(dialCode) || dialCode.startsWith(value))) {
                    setShowError(false);
                    setPhoneNumber('+' + value);
                  } else {
                    setShowError(true);
                  }
                } else if (dialCode === '689') {
                  if (15 === formattedValue?.length && (value.startsWith(dialCode) || dialCode.startsWith(value))) {
                    setShowError(false);
                    setPhoneNumber('+' + value);
                  } else {
                    setShowError(true);
                  }
                } else {
                  if (format?.length === formattedValue?.length && (value.startsWith(dialCode) || dialCode.startsWith(value))) {
                    setShowError(false);
                    setPhoneNumber('+' + value);
                  } else {
                    setShowError(true);
                  }
                }
              }}
              onEnterKeyPress={() => (!showError && !showOtp) && handleFormSubmit()}
            />
          </>
          {!showOtp && <button className="phone-submit" disabled={showError || showOtp} onClick={() => handleFormSubmit()}>
            {loading ? (<>
              <CircularProgress color="inherit" />
              Envoi en cours...
            </> ) : 'Continuer' }
          </button>}
        </div>
        {showOtp && <Otp phoneNumber={phoneNumber} page={page} recordType={recordType} redirectUrl={redirectUrl}/>}
      </div>
    </>
  );
};

export default Phone;
