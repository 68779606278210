import React, {useEffect, useState} from 'react';
import ImageUpload from "../../../../components/ImageUpload";
import {CircularProgress} from "@mui/material";

const CompanyUploads = ({handleStep, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const [upload, setUpload] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [iDCount, setIDCount] = useState(0);
  const [kbisCount, setKbisCount] = useState(0);
  const [statusCount, setStatusCount] = useState(0);
  const [ribCount, setRibCount] = useState(0);

  useEffect(() => {
    if (nextStep) {
      window.scrollTo(0, 0);
      handleStep(true);
    }
  }, [nextStep])

  function validUpload() {
    (iDCount > 0 && kbisCount > 0 && ribCount > 0 && statusCount > 0) && setUpload(true);
  }

  function renderButton() {
    if (iDCount === 0 || kbisCount === 0 || ribCount === 0 || statusCount === 0) {
      return <button disabled={true} className="submit-btn">Envoyer les documents</button>
    } else if (submitting) {
      return <button disabled={submitting} className="submit-btn">
        <CircularProgress color="inherit" />
        Documents en cours d'envoi
      </button>
    } else {
      return <button disabled={!(iDCount > 0 && kbisCount > 0 && ribCount > 0 && statusCount > 0)} className="submit-btn" onClick={() => validUpload()}>Envoyer les documents</button>
    }
  }

  return (
    <>
      <h6 className="os-h6">Votre pièce d'identité</h6>
      <ImageUpload userSurveyCheck={setIDCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="kyc"  record={company}/>

      <h6 className="os-h6">Kbis de moins de 3 mois</h6>
      <ImageUpload userSurveyCheck={setKbisCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="kbis"  record={company}/>

      <h6 className="os-h6">Statuts certifiés conformes signés et datés</h6>
      <ImageUpload userSurveyCheck={setStatusCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="status" record={company}/>

      <h6 className="os-h6">RIB de l'entreprise - Relevé d'identité bancaire</h6>
      <ImageUpload userSurveyCheck={setRibCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="rib" record={company}/>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {renderButton()}
      </div>
    </>
  );
};

export default CompanyUploads;
