import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import EditIcon from '@mui/icons-material/Edit';

import {ReactComponent as ChatIcon} from "../../Opportunities/View/icons/ic_chat.svg";
import InstructionsContainer from "../../../components/instructionsContainer";
import authenticationStore from "../../../stores/AuthenticationStore.ts";

import './style.scss';

const CGPProfile = ({cgpUser}) => {
  const [isEditing, setIsEditing] = useState(false);

  const toggle = type => {
    (type === 'completeInfos') && setIsEditing(!isEditing);
  }

  useEffect(() => {
    cgpUser?.phone_number === null && window.location.replace('/sign-up/verify');
  }, [cgpUser])

  return (
    <>
      <Helmet>
        <title>Openstone | Mon profil</title>
      </Helmet>
      <div className="account-container">
        <div className="profile-container-content">
          <div className="os-flex section-title light-border" style={{ marginBottom: 24 }}>
            <h4 className="os-h4">Mon profil</h4>
            <div className="user-infos-top-container">
              {cgpUser?.kyc_verification_status !== 'verified' && (
                <button className="user-infos-top-container__button" onClick={() => toggle('completeInfos')}>
                  <EditIcon style={{width: 16}}/>
                  Éditer
                </button>
              )}
            </div>
          </div>
          <div className="user-infos-names">
            <div className="user-infos-names">
              <p><span className="field-title">Nom :</span> {cgpUser?.first_name}</p>
              <p><span className="field-title">Prénom :</span> {cgpUser?.last_name}</p>
              <p><span className="field-title">Email :</span> {cgpUser?.email}</p>
              <div className="phone-field">
                  <p><span className="field-title">Téléphone :</span> {cgpUser?.phone_number}</p>
              </div>
            </div>
          </div>
            {cgpUser?.kyc_verification_status === 'verified' && (
              <InstructionsContainer
                content={"Votre compte étant maintenant vérifié, vous ne pouvez plus mettre à jour vos informations. Pour toute demande de modification, vous pouvez nous écrire et notre équipe du service client s’occupera de vous !"}
                extraChildren={ <button id="open_os_intercom" className="user-infos-top-container__button" style={{marginTop: 16}}>
                  <ChatIcon style={{width: 16}}/>
                  Nous contacter
                </button>}
              />
            )}
        </div>
      </div>
  </>
  );
};

export default CGPProfile;
