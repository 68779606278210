import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';

import {MyTextInput} from "../../Inputs";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import NumberHelper from "../../../Helpers/NumberHelper";

import './style.scss';

const DepositForm = ({product, page, func, pullData, isClicked, minimumInvestment, cgp}) => {
  const [amount, setAmount] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && localStorage.getItem('InvestmentAmount')) || null);
  const [cgpEntryFees, setCgpEntryFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && localStorage.getItem('CgpEntryFees')) || null);
  const [cgpManagementFees, setCgpManagementFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && localStorage.getItem('CgpManagementFees')) || null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorCgpEntryFees, setErrorCgpEntryFees] = useState('');
  const [errorCgpManagementFees, setErrorCgpManagementFees] = useState('');
	const [total, setTotal] = useState(null);

	const updateAmount = (value) => {
		let minimum = parseInt(minimumInvestment)
		if (value === '' || value === null || value === 0) {
			setError('Un montant doit être renseigné');
		} else if (value < minimum) {
			setError(`L'investissement doit être au minimum de ${MoneyHelper.formatEuro(minimum, false, 0, 0)}`);
		} else {
			setError('');
		}
		setAmount(value);
	};

	const updateCgpEntryFees = (fees) => {
	  if (fees < 0 ) {
			setErrorCgpEntryFees(`Doit être entre 0 et ${product.cgp_fees_infos?.max_entry_fees}%`)
	  } else if (fees > product.cgp_fees_infos.max_entry_fees) {
		  setErrorCgpEntryFees(`Doit être entre 0 et ${product.cgp_fees_infos?.max_entry_fees}%`)
	  } else {
			setErrorCgpEntryFees('')
			setCgpEntryFees(fees)
	  }
	}
	
	const updateCgpManagementFees = (fees) => {
		if (fees < 0 ) {
			setErrorCgpManagementFees(`Doit être entre 0 et 1%`)
		} else if (fees > 1) {
			setErrorCgpManagementFees(`Doit être entre 0 et 1%`)
		} else {
			setErrorCgpManagementFees('')
			setCgpManagementFees(fees)
		}
	}
	
	useEffect(() => {
		if (amount !== null && cgpEntryFees !== null) {
			setTotal(amount * ((cgpEntryFees / 100) + 1))
		}
	}, [amount, cgpEntryFees]);
	
  return (
    <>
      <Formik
        initialValues={{
          amount: amount,
	        cgpEntryFees: cgpEntryFees,
	        cgpManagementFees: cgpManagementFees
        }}
        onSubmit={ async (values ) => {
          setIsLoading(true);
          if (page === 'opportunityView') {
            if (amount !== null) {
              localStorage.setItem('InvestmentAmount', amount)
              const currentUrl = new URL(window.location.href);
              currentUrl.pathname += '/finalise';
              window.location.replace(currentUrl.toString());
            } else {
              setError("Vous devez entrer un montant")
            }
          } else if (page === 'opportunityView' && product.name === 'Livret P.') {
            if (amount !== null) {
              window.location.href = `/opportunites/livret-p/deposit${`?amount=${amount}`}`
            } else {
              setError("Vous devez entrer un montant")
            }
          } else if (page === 'opportunityStep1') {
						if (cgpEntryFees !== null) {
							if (total !== null) {
								localStorage.setItem('InvestmentAmount', amount)
								localStorage.setItem('CgpEntryFees', cgpEntryFees)
								cgpManagementFees && localStorage.setItem('CgpManagementFees', cgpManagementFees)
								func(true)
							}
						} else {
							setAmount(amount);
							if (amount !== null) {
								localStorage.setItem('InvestmentAmount', amount)
								func(true)
							}
						}
          } else if (page === 'opportunityStep2') {
	          if (cgpEntryFees !== null) {
		          if (total !== null) {
			          localStorage.setItem('InvestmentAmount', amount)
			          cgp && localStorage.setItem('CgpEntryFees', cgpEntryFees)
								cgpManagementFees && localStorage.setItem('CgpManagementFees', cgpManagementFees)
			          func()
		          }
	          } else {
							if (cgp) {
								localStorage.setItem('InvestmentAmount', amount)
								func()
							} else {
								setAmount(amount);
								amount !== null && localStorage.setItem('InvestmentAmount', amount)
								pullData(amount, true);
							}
	          }
          }
          setIsLoading(false);
        }}
      >
        <Form className="complete-infos__container">
          <>
	          <h5 className="os-subtitle1">Montant de la souscription</h5>
            <MyTextInput
              name="amount"
              type="number"
              placeholder={`${minimumInvestment !== undefined && NumberHelper.numberWithSpaces(minimumInvestment)} €`}
              isClicked={isClicked}
              value={amount}
              onChange={(e) => updateAmount(e.target.value)}
            />
            {error !== '' && <p className="error">{error}</p>}
          </>

	        {(cgp && (page === 'opportunityStep1' || page === 'opportunityStep2')) && (
		        <>
			        <h5 className="os-subtitle1">Frais d'entrée</h5>
			        <MyTextInput
				        name="cgpEntryFees"
				        type="number"
				        placeholder={`0-${product.cgp_fees_infos.max_entry_fees}%`}
				        isClicked={isClicked}
				        value={cgpEntryFees}
				        onChange={(e) => updateCgpEntryFees(e.target.value)}
			        />
							<span className="os-caption" style={{marginBottom: 8}}>Les droits d’entrée versés par le souscripteur s’élèvent à {product.cgp_fees_infos.max_entry_fees}% maximum du montant de souscription et vous sont redistribués dans leur entièreté</span>
							{errorCgpEntryFees !== '' && <p className="error">{errorCgpEntryFees}</p>}
							
							{product.cgp_fees_infos.management_fees === 'special' && (
								<>
									<h5 className="os-subtitle1">Frais de gestion</h5>
									<span className="os-caption" style={{marginBottom: 8}}>
										Les frais de gestion versés par le souscripteur se décomposent de la manière suivante :
										<br /><br />
										1% d’incompressible du montant de souscription pour Openstone
										<br />
										1% maximum du montant de souscription qui vous sont redistribués
									</span>
									<MyTextInput
										name="cgpManagementFees"
										type="number"
										placeholder={`0-1%`}
										isClicked={isClicked}
										value={cgpManagementFees}
										onChange={(e) => updateCgpManagementFees(e.target.value)}
									/>
									{errorCgpManagementFees !== '' && <p className="error">{errorCgpManagementFees}</p>}
								</>
							)}
							
			        {total !== null && <p className="os-body1 info-text">Montant total à régler par le client (Investissement + frais d’entrée) : <strong>{MoneyHelper.formatEuro(total, false, 0, 0)}</strong></p>}
		        </>
	        )
					}

          <div className="submit-container">
            <button className="submit-btn" style={{width: '100%', marginTop: 8}} type="submit" disabled={isLoading || error !== '' || errorCgpEntryFees !== '' || errorCgpManagementFees !== ''}>
	            Continuer la souscription
						</button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
export default DepositForm;
