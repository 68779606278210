import React from 'react';
import OsCard from "../../../components/card/basic";
import Images from "../../../assets/images";

import './style.scss';

const Campaigns = () => {
  return (
    <div className="os-flex__column performance-container">
      <OsCard bg="green">
        <div className="os-flex announcement_container">
          <div className="announcement-left">
            <h4 className="os-h4" style={{color: 'white'}}>À venir prochainement</h4>
            <p className="os-body1">Organisez et planifiez des campagnes destinées à vos clients</p>
            <ul>
              <li>Personnalisation de votre marque</li>
              <li>Campagnes emailing de reporting et marketing que vous pouvez personnaliser et envoyer à vos clients</li>
              <li>Suivi des performances des campagnes</li>
            </ul>
          </div>
          <img src={Images.cgp.performance} alt="" className="performance-container__img"/>
        </div>
      </OsCard>
    </div>
  );
};

export default Campaigns;
