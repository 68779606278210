import React from "react"
import ContentLoader from "react-content-loader"

const FinaliseLoader0 = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 0 400 460"
    backgroundColor="#dddedf"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="10" y="25" rx="10" ry="10" width="58.5%" height="29%" />
    <rect x="255" y="25" rx="10" ry="10" width="33.5%" height="35%" />
    <rect x="10" y="165" rx="10" ry="10" width="58.5%" height="19%" />



  </ContentLoader>
)

export default FinaliseLoader0