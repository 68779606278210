import Network from './Network.ts';

class SignatureNetwork extends Network {
  async fill_values(contractType: string, productId: number, values: object, forType: string, recordId: string) {
    return await this.post('/contracts/fill_values', {
      contract_type: contractType,
      product_id: productId,
      values: values,
      for_type: forType,
      record_id: recordId
    });
  }

  async sign(contractType: string, productId: number, values: object, forType: string, coupon: string, recordId: string) {
    return await this.post('/contracts/sign', {
      contract_type: contractType,
      product_id: productId,
      values: values,
      for_type: forType,
      coupon: coupon === null && "",
      record_id: recordId
    });
  }
}

export default new SignatureNetwork();
