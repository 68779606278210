import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

import authenticationStore from "../../stores/AuthenticationStore.ts";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";

import './style.scss';

const OTP_TIMER = 30;
const Otp = ({phoneNumber, page, func, recordType, redirectUrl}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [otpTimer, setOtpTimer] = useState(OTP_TIMER);
  const [isSuccessResend, setIsSuccessResend] = useState(false);
  const [codIsValid, setCodeIsValid] = useState(false)
  const [loading, setLoading] = useState(false);

  const handleChange = (code) => {
    setCode(code);
    code.length === 6 && setCodeIsValid(true);
  }

  const decrementTimer = () => {
    return setInterval(() => {
      setOtpTimer((prevOtpTimer) => {
        if (prevOtpTimer > 0) {
          return prevOtpTimer - 1;
        }
        return prevOtpTimer;
      });
    }, 1000);
  };

  useEffect(() => {
    const interval = decrementTimer();
    if (isSuccessResend) decrementTimer();
    return () => clearInterval(interval);
  }, [isSuccessResend]);

  const validateOtp = async () => {
    setLoading(true);
    const response = await authenticationStore.validateOTP(
      phoneNumber,
      code,
      'Web_email',
      authenticationStore.user?.id && authenticationStore.user?.id,
      recordType
    );

    if (!response || response === false) {
      toast.error("Le code renseigné n'est pas valide");
      setLoading(false);
    } else if (response) {
			if (recordType === 'cgp') {
				await authenticationStore.loadCgpUserData().then(async cgp => {
					if (cgp.kyc_verification_status !== 'verified') {
						cgp.has_uploaded !== true ?  navigate('/sign-up/cgp/documents') : navigate('/cgp/verification')
					} else if (cgp.kyc_verification_status === 'verified') {
					  navigate('/cgp/dashboard')
					}
				})
			} else if (recordType !== 'cgp') {
				await authenticationStore.loadUserData().then(async () => {
					if (page === 'withdraw') {
						func(true);
					} else if (page === 'editUser') {
						toast.success('Votre numéro de téléphone a bien été modifié');
						window.location.reload();
					} else if (page === 'collaboration' || redirectUrl !== '') {
						navigate(redirectUrl);
					} else {
						navigate('/overview');
					}
				})
			}
    }
  };

  const requestOtp = async () => {
    const response = await authenticationStore.requestOTP(
      phoneNumber,
      authenticationStore.user?.id && authenticationStore.user?.id,
    );

    if (response) {
      setOtpTimer(OTP_TIMER);
      setIsSuccessResend(true);
      setCode('');
      setCodeIsValid(false);
    } else {
      toast.error("Ce numéro de téléphone est déjà associé à un compte, veuillez vous connecter via téléphone");
    }
  };

  return (
    <div className="otp-container">
      <p className="otp-text">Nous vous avons envoyé un SMS contenant un code de validation au numéro suivant: {' '}
        <span className="otp-phone-number">{phoneNumber}</span>
      </p>
      <OtpInput
        value={code}
        onChange={handleChange}
        numInputs={6}
        separator={<span style={{marginLeft: '8px'}}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid #DCE0E4",
          borderRadius: "8px",
          width: "38px",
          height: "45px",
          fontSize: "14px",
          color: "#000",
          fontWeight: "400",
          caretColor: "#DCE0E4",
          backgroundColor: 'transparent'
        }}
        focusStyle={{
          borderBottom: "1px solid #133251",
          outline: "none"
        }}
        containerStyle={{
          width: '100%',
          justifyContent: 'center',
          flexWrap: 'no-wrap',
          marginTop: 16
        }}
      />
      <div className="otp-submit-container">
        <button className="phone-submit" onClick={() => validateOtp()} disabled={!codIsValid}>
          {loading ? (<>
            <CircularProgress color="inherit" />
            Validation en cours...
          </> ) : 'Valider' }
        </button>
      </div>
      <p style={{marginTop: 16, marginBottom: 5}}>Vous n'avez pas reçu de code ?</p>
      {otpTimer === 0 ? (
        <span onClick={() => otpTimer === 0 && requestOtp()} className="otp-new">En recevoir un nouveau {' '}</span>
      ) : (
        <>
          <span className="otp-new-disabled">En recevoir un nouveau {' '}</span>
          <span> dans {otpTimer} secondes </span>
        </>
      )}
    </div>
  );
};

export default Otp;
