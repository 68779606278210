import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {Helmet} from "react-helmet";

import ProductNework from "../../../config/Network/ProductNework.ts";

import OpportunityViewLoader from "../../../components/Loaders/Opportunities/OpportunityViewLoader";
import ProductPresentation from "../../../components/Product/Presentation";

import './style.scss';
import PublicProductPresentation from "../../../components/Product/Presentation/public";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import {useSearchParams} from "react-router-dom";

const OpportunityView = ({restricted}) => {
  let { id } = useParams();
  let { state } = useLocation();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageTab, setPageTab] = useState(0);
  const [isShowingVideo, setIsShowingVideo] = useState(false);
	const isCgp = useState(localStorage.getItem('CgpUserAuthToken') !== null)[0];
	const [searchParams, setSearchParams] = useSearchParams();

  const toggle = type => {
    if (type === 'video') {
      setIsShowingVideo(!isShowingVideo);
    }
  }

  const fetchOpportunity = async () => {
    const response = await ProductNework.getProduct(id);
    if (response) {
      setProduct(response);
      setLoading(false);
    }
  }
	
	const fetchPublicOpportunity = async () => {
    const response = await ProductNework.getPublicProduct(id);
    if (response) {
      setProduct(response);
      setLoading(false);
    }
  }
	
	useEffect(() => {
		restricted ? fetchOpportunity() : fetchPublicOpportunity();
		localStorage.removeItem('transactionStatus');
	}, []);
	
	const renderOpportunity = () => {
    let component;
    if (loading || product === null ) {
      component = <OpportunityViewLoader />
    } else if (!loading && product) {
			if (restricted) {
        component = <ProductPresentation product={product} toggle={toggle} isShowingVideo={isShowingVideo} />
			} else {
				component = <PublicProductPresentation product={product} toggle={toggle} isShowingVideo={isShowingVideo} />
			}
    }
    return component;
  }

  const renderProductCategory = (product) => {
    if (product.category_name === 'Alternatifs') {
      return 'Private Equity'
    } else {
      return product.category_name
    }
  }
	
	useEffect(() => {
		setSearchParams({userType: isCgp ? 'cgp' : 'part'})
	}, [])

  useEffect(() => {
    (state !== null && state.tab === 1) && setPageTab(1);
  }, [state])

  return (
    <>
      <Helmet>
        <title>Opportunités | Openstone</title>
      </Helmet>
      {!restricted && <Navbar />}
      <div className={`product-view-container ${!restricted && 'public-container'}`}>
        <div className="product-view-title">
          <h4 className="os-h4" style={{marginBottom: 0}}>{restricted ? product.name : 'Projet confidentiel'}</h4>
          <span className="product-view-category">{renderProductCategory(product)}</span>
        </div>
        <div className="product-ariane-nav">
          <a href="/opportunites" className="product-ariane-nav-item">Opportunités</a>
          <div className="circle"/>
          <a href={`/opportunites/${product.category_slug}`} className="product-ariane-nav-item">{renderProductCategory(product)}</a>
          <div className="circle"/>
          <span className="product-ariane-nav-item product-ariane-nav-item-disabled">{restricted ? product.name : 'Projet confidentiel'}</span>
        </div>
        {renderOpportunity()}
      </div>
	    {!restricted && <Footer />}
    </>
  );
};

export default OpportunityView;
