import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {InputAdornment} from "@mui/material";
import Divider from "@mui/material/Divider";
import {toast} from "react-toastify";

import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import {sourceOfFunds, walletTypes} from "../choices";
import OsSelect from "../../../../components/Form/OsSelect";
import MoneyHelper from "../../../../Helpers/MoneyHelper";
import OsCheckbox from "../../../../components/Form/OsCheckbox";
import OSButton from "../../../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Revenus = ({user, handleStep, page, isCgp, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const financials = page === 'companyForm' ? company?.financial_situation : user?.financial_situation;
  const [totalAmount, setTotalAmount] = useState(0);

  const validationSchema = yup.object({
	  incomeAmount: yup.string().required('Requis'),
	  charges: yup.string().required('Requis').max(100, "Ne peut pas excéder 100%"),
	  retreatAmount: yup.string().required('Requis'),
	  investmentsAmount: yup.string().required('Requis'),
	  walletAmount: yup.string().required('Requis'),
	  funds: yup.array().min(1, 'Requis'),
	  property: yup.array().min(1, 'Requis'),
  })

{/*iban: isCgp && yup.string()
                  .min(27, "L'IBAN doit être composé de 27 charactères")
                  .max(27, "L'IBAN doit être composé de 27 charactères")
                  .matches(/^FR/, "L'IBAN doit être français 'FR76...'")
                  .required("Requis pour CGP")*/}
  const formik = useFormik({
    initialValues: {
	    incomeAmount: '',
	    charges: '',
	    retreatAmount: '',
	    investmentsAmount: '',
	    property: [],
	    propertyAmount: '',
	    walletAmount: '',
	    funds: [],
      //iban: company ? company.iban : user.iban
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const datasToSubmit = {
        "income": values.incomeAmount,
        "charges": values.charges,
        "retreat_amount": values.retreatAmount,
        "investments_amount": values.investmentsAmount,
        "total_amount": totalAmount,
        "property": values.property,
	      "property_amount": values.propertyAmount,
        "wallet_amount": values.walletAmount,
	      "source_of_funds": values.funds
      };
      async function handleRequest() {
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord({ financial_situation: datasToSubmit}, company.id, 'Company') : await CgpNetwork.updateRecord({ financial_situation: datasToSubmit}, user.id, 'User');
        } else {
          page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id, { financial_situation: datasToSubmit}) : await AuthenticationNetwork.editUser({financial_situation: datasToSubmit});
        }
      }
      const response = handleRequest();
      if (response) {
        setSubmitting(false);
        toast.success('Votre situation financière a bien été enregistrée', {
          toastId: 'completeInfosSuccessToast'
        });
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });

    useEffect(() => {
      formik.setValues({
          incomeAmount: financials?.income || '',
          charges: financials?.charges || '',
          retreatAmount: financials?.retreat_amount || '',
          investmentsAmount: financials?.investments_amount || '',
          property: financials?.property || [],
          propertyAmount: financials?.property_amount || '',
          walletAmount: financials?.wallet_amount || '',
          funds: financials?.source_of_funds || [],
      });
    }, [financials]);

    useEffect(() => {
      setTotalAmount(formik.values.incomeAmount +
	      formik.values.investmentsAmount +
	      formik.values.retreatAmount)
    }, [formik.values])

  return (
    <div>
      <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
        {/* isCgp && (
          <>
            <h6 className="os-h6">Informations bancaires</h6>
            <p>Renseignez un IBAN français afin de pouvoir utiliser le prélèvement SEPA lors de la souscription</p>
            <p>Conseil: Noter manuellement les IBAN afin d'éviter toute erreur / usurpation</p>
            <div className="user-survey-questions-container">
              <TextField
                fullWidth
                id="iban"
                name="iban"
                label="IBAN"
                placeholder="FR76"
                value={formik.values.iban}
                onChange={formik.handleChange}
                error={formik.touched.iban && Boolean(formik.errors.iban)}
                helperText={formik.touched.iban && formik.errors.iban}
                variant={"outlined"}
              />
            </div>
          </>
        )*/}
        <h6 className="os-h6">Revenus & charges</h6>
        <p className="user-survey-question">Vos revenus annuels sont estimés à :</p>
        <div className="user-survey-questions-container">
          <TextField
            fullWidth
            id="incomeAmount"
            name="incomeAmount"
            type="number"
            label="Revenus liés à votre travail"
            value={formik.values.incomeAmount}
            onChange={formik.handleChange}
            error={formik.touched.incomeAmount && Boolean(formik.errors.incomeAmount)}
            helperText={formik.touched.incomeAmount && formik.errors.incomeAmount}
            variant={"outlined"}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
          <TextField
              fullWidth
              id="retreatAmount"
              name="retreatAmount"
              type="number"
              label="Revenus issus de votre retraite"
              value={formik.values.retreatAmount}
              onChange={formik.handleChange}
              error={formik.touched.retreatAmount && Boolean(formik.errors.retreatAmount)}
              helperText={formik.touched.retreatAmount && formik.errors.retreatAmount}
              variant={"outlined"}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
          />
          <TextField
              fullWidth
              id="investmentsAmount"
              name="investmentsAmount"
              type="number"
              label="Revenus issus de vos placements"
              value={formik.values.investmentsAmount}
              onChange={formik.handleChange}
              error={formik.touched.investmentsAmount && Boolean(formik.errors.investmentsAmount)}
              helperText={formik.touched.investmentsAmount && formik.errors.investmentsAmount}
              variant={"outlined"}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
          />
	        <p className="os-body1">Montant total de vos revenus: <strong className="info-text">{MoneyHelper.formatEuro(totalAmount, false, 0, 0)}</strong></p>
        </div>
	      
	      <p className="user-survey-question">Charges (vos charges annuelles sont estimées à …% du revenu)</p>
	      <div className="user-survey-questions-container">
            <TextField
              fullWidth
              id="charges"
              name="charges"
              type="number"
              label="Charges annuelles en %"
              value={formik.values.charges}
              onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
                      formik.setFieldValue('charges', value);
                  }
              }}
              error={formik.touched.charges && Boolean(formik.errors.charges)}
              helperText={formik.touched.charges && formik.errors.charges}
              variant="outlined"
              InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </div>
        <Divider />

        <h6 className="os-h6">Patrimoine <span className="error"> {formik.touched.property && formik.errors.property}</span></h6>
        <p className="user-survey-question">Êtes-vous propriétaire de :</p>
        <div className="user-survey-questions-container">
            {[
                { value: 'Votre résidence principale', label: 'Votre résidence principale' },
                { value: 'Une résidence secondaire', label: 'Une résidence secondaire' },
                { value: 'Un ou plusieurs logements mis en location', label: 'Un ou plusieurs logements mis en location' },
                { value: 'Aucun', label: 'Aucun' },
            ].map (property => (
                <OsCheckbox
                    key={`property${property.value}`}
                    formik={formik}
                    name="property"
                    value={property.value}
                    label={property.value}
                />
            ))}
		        {(formik.values.property.length > 0 && formik.values.property[0] !== 'Aucun') && (
			        <TextField
				        fullWidth
				        id="propertyAmount"
				        name="propertyAmount"
				        type="number"
				        label="Estimez vos biens immobiliers en €"
				        value={formik.values.propertyAmount}
				        onChange={formik.handleChange}
				        error={formik.touched.propertyAmount && Boolean(formik.errors.propertyAmount)}
				        helperText={formik.touched.propertyAmount && formik.errors.propertyAmount}
				        variant={"outlined"}
				        InputProps={{
					        inputProps: { min: 0 },
					        endAdornment: <InputAdornment position="end">€</InputAdornment>
				        }}
			        />
		        )}
        </div>
        <Divider />

        <h6 className="os-h6">Valeur du portefeuille <span className="error"> {formik.touched.walletAmount && formik.errors.walletAmount}</span></h6>
        <p className="user-survey-question">Quelle est la valeur de votre portefeuille d’instruments financiers (votre patrimoine placé, hors biens immobiliers et hors autres actifs physiques comme œuvres d’art par exemple) :</p>
        <div className="user-survey-questions-container">
          <OsSelect
              formik={formik}
              name="walletAmount"
              label="Valeur du portefeuille"
              options={walletTypes.sort().map(type => ({
                  value: type.value,
                  label: type.value,
              }))}
          />
        </div>
	      
	      <Divider />
	      
	      {/* Provenance des fonds */}
	      
	      <h6 className="os-h6">
		      Provenance des fonds
		      <span className="error"> {formik.touched.funds && Boolean(formik.errors.funds)} {formik.touched.funds && formik.errors.funds}</span>
	      </h6>
	      <p className="user-survey-question">Quelle est la provenance des fonds dédiés aux investissements sur la plateforme ? *</p>
	      <div className="user-survey-questions-container">
		      {sourceOfFunds.map(f => (
			      <OsCheckbox
				      key={`funds-${f.value}`}
				      formik={formik}
				      name="funds"
				      value={f.value}
				      label={f.value}
			      />
		      ))}
	      </div>
	      
	      <div className="user-survey-btn-container">
		      <OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
		      <button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
	      </div>
	      <div className="user-survey-btn-container">
		      {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
	      </div>
      </form>
    </div>
  );
};

export default Revenus;
