import React from 'react';

import './style.scss'
import Badge from "../Badge/Badge";
import {CircularProgress} from "@mui/material";

/**
 * A simple Button component.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.link - Whether to set a link that will redirect.
 * @param {string} props.title - Whether to set the title of the button.
 * @param {string} props.variant - Whether to set a certain color of the button.
 * @param {string} props.size - Whether to set the size of the button.
 * @param {boolean} props.fit - Whether to display a logo inside.
 * @param {string} props.onClick - Whether to add a onClick function.
 * @param {string} props.coming - Whether to add a coming feature style.
 * @param {boolean} props.submitting - Whether the action on the button is performing.
 */

const OSButton = ({link, title, variant, size, fit, onClick, icon, coming, submitting, target, position}) => {
  if (onClick) {
    return (
      <button disabled={submitting} onClick={onClick} className={`os-button ${size === '' ? `os-button-${variant}` : `os-button-${variant}__${size}`} ${fit && 'os-button-fit'}`}>
        {submitting ? (<div className="os-flex gap-16 btn-submitting">
          <CircularProgress color="inherit" style={{width: 20, height: 20}}/>
          <span>Envoi en cours...</span>
        </div> ) : ( <>
          {icon} {title}
        </>
        )}

        {coming && (
          <div className="coming-soon-container">
            <Badge color={coming && 'info'}>{coming && 'À venir'}</Badge>
          </div>
        )}
      </button>
    );
  } else {
    return (
      <a href={link} className={`os-button ${size === '' ? `os-button-${variant}` : `os-button-${variant}__${size}`} ${fit && 'os-button-fit'}`} target={target && "_blank"}>
        {(position === 'start' || !position) && icon} {title} {position === 'end' && icon}
        {coming && (
          <div className="coming-soon-container">
            <Badge color={coming && 'info'}>{coming && 'À venir'}</Badge>
          </div>
        )}
      </a>
    );
  }
};

export default OSButton;
