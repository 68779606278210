import APIs from './Network.ts';

class UserTransactionNetwork extends APIs {
  async list({ page = 1, perPage = 20, transaction_type, date_range }) {
    let extraFilters = {};
    if (transaction_type) {
      extraFilters = { ...extraFilters, transaction_type };
    }
    if (date_range) {
      extraFilters = { ...extraFilters, date_range };
    }

    return await this.get('/user_transactions', {
      page,
      per_page: perPage,
      ...extraFilters,
    });
  }

  async withdraw(amount: number, br_id: string, iban: string, bic: string) {
    return await this.post(
      '/user_transactions/withdraw',
      {
        amount: amount,
        br_id: br_id,
        iban,
        bic
      },
      true,
    );
  }

  async cancelTransaction(transactionId: number) {
    return await this.delete(`/user_transactions/${transactionId}`, {});
  }

  async myTransactions(profile, recordId) {
    return await this.get('/users/my_transactions', {
      profile: profile ? 'user' : 'company',
      record_id: recordId && recordId
    });
  }
}

export default new UserTransactionNetwork();
