import React from 'react';
import {Link} from "react-router-dom";
import WarningIcon from '@mui/icons-material/Warning';

import './style.scss';

const ValidateIdentityBanner = ({object, page, defaultProfile}) => {
  const textToRender = () => {
    let title; let link; let key; let style; let icon; let text;
    if (defaultProfile) {
      if (object.account_status !== 'verified') {
        if (object.kyc_verification_status === 'verified') {
          key = 'verified';
          title = 'Ne laissez pas votre argent dormir, réalisez votre premier dépôt';
          link = '/opportunites/livret-p/livretp';
          style = 'blue';
          text = 'Déposer';
        } else if (object.kyc_verification_status === 'pending') {
          key = 'pending';
          title = "Validez votre identité pour finaliser votre compte";
          link = page === 'dashboard' ? '/user_survey' : '/kyc';
          style = 'yellow';
          icon = <WarningIcon/>
          text = 'Finaliser';
        } else if (object.kyc_verification_status === 'processing') {
          key = 'processing';
          title = "Votre document d'idendité est en cours de validation";
          link = '#';
          style = 'yellow';
          text = 'Continuer';
        }
      } else if (object.account_status === 'verified') {
        key = 'verified';
        title = 'Invitez vos proches, gagnez 250€';
        link = '/parrainage'
        style = 'blue';
        text = 'Inviter';
      }
    } else {
      if (object.livretp_status !== 'verfied' && object.os_status !== 'verified') {
        if (object.kyc_status === 'verified') {
          key = 'verified';
          title = 'Ne laissez pas votre argent dormir, réalisez votre premier dépôt';
          link = '/opportunites';
          style = 'blue';
          text = 'Déposer';
        } else if (object.kyc_status === 'pending') {
          key = 'pending';
          title = "Validez l'identité de votre société pour finaliser votre compte";
          link = '/company/create';
          style = 'yellow';
          icon = <WarningIcon />
          text = 'Finaliser';
        } else if (object.kyc_status === 'processing') {
          key = 'processing';
          title = "Vos documents sont en cours de validation.";
          link = '#';
          style = 'yellow';
          text = 'Continuer';
        } else if (object.kyc_status === 'unverified') {
          key = 'unverified';
          title = "Vous n'avez pas terminé la création de votre société, cliquez ici pour finaliser";
          link = '/company/edit';
          style = 'yellow';
          text = 'Continuer';
        }
      }
    }
    return { title, link, key, style, icon, text }
  }

  return (
    <div className={`livretp-banner livretp-banner__${textToRender().style}`}>
      <div className="livretp-banner-left-content">
        <span className={`livretp-banner-icon__${textToRender().style}`}>{textToRender().icon}</span>
        <Link className={`livretp_banner-link livretp_banner-link__${textToRender().style}`} to={textToRender().link}>{textToRender().title}</Link>
      </div>
      <Link className={`livretp_banner-link-btn livretp_banner-link-btn__${textToRender().style}`} to={textToRender().link}>{textToRender().text}</Link>
    </div>
  );
};

export default ValidateIdentityBanner;
