import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {observer} from 'mobx-react';
import {ReactComponent as Error} from "../../../assets/Illustrations/error.svg";
import {ReactComponent as Success} from "../../../assets/Illustrations/success.svg";
import {toast} from "react-toastify";

import QuestionNetwork from "../../../config/Network/QuestionNetwork.ts";
import AuthenticationStore from "../../../stores/AuthenticationStore.ts";
import OSButton from "../../../components/Button";

import './style.scss';

const checkPosition = (answer, position) => {
  let letter;
  if (position === 0) {
    letter = 'A'
  } else if (position === 1) {
    letter = 'B'
  } else if (position === 2) {
    letter = 'C'
  } else if (position === 3) {
    letter = 'D'
  }
  return letter;
}

const BoostQuestion = () => {
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('Company')));
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState({});
  const [isAnswered, setIsAnswered] = useState(!defaultProfile ? question.company_answer_id : question.user_answer_id);
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [answered, setAnswered] = useState(false);

  const loadQuestion = async () => {
    const response = await QuestionNetwork.active();
    if (response) {
      setQuestion(response)
      defaultProfile ? setIsAnswered(response.user_answer_id) : setIsAnswered(response.company_answer_id)
      setLoading(false);
    }
  };

  useEffect(() => {
    loadQuestion();
  }, []);

  const AnswerCard = ({answer, position, page}) => (
    <div onClick={() => setSelectedAnswer(answer)} className={`answer-card ${(!answered && selectedAnswer.id === answer.id) && 'answer-card-selected'} ${page === 'result' && 'answer-card-full'}`}>
      {(position || position === 0) && <p className="answer-letter">{checkPosition(answer, position)}</p>}
      <p className="answer-content">{answer.title}</p>
    </div>
  );

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setAnswered(true);
      return <div className="timer">C'est terminé !</div>;
    }

    return (
      <div className="timer">
        <div className="text">Temps restant</div>
        <div className="value">{remainingTime}</div>
        <div className="text">secondes</div>
      </div>
    );
  };

  const answerQuestion = async (expired) => {
    setAnswered(true);
    const response = await QuestionNetwork.answerActive(selectedAnswer.id, !defaultProfile ? 'company' : 'user');
    (response && !expired) && toast.success('Votre réponse a bien été envoyée')
    await AuthenticationStore.loadUserData(); // to reload the user's data
  }

  useEffect(() => {
    isAnswered && navigate('/opportunites/livret-p/boosts')
  }, [isAnswered])

  const renderQuestionOrAnswer = () => {
    if (answered) {
      const content = () => {
        if (selectedAnswer.correct_answer) {
          return (
            <>
              <div className="question-illustration">
                <Success />
              </div>
              <h4 className="os-h4">Bravo ! Vous avez trouvé la bonne réponse !</h4>
              <AnswerCard answer={selectedAnswer} page="result"/>
              <p className="question-joke">{question.description}</p>
              <p className="question-text">Votre boost va être activé.</p>
              <p className="question-text">Tentez votre chance la semaine prochaine pour en gagner un nouveau.</p>
            </>
          )
        } else {
          return (
            <>
              <div className="question-illustration">
                <Error />
              </div>
              <div>
                <h4 className="os-h4">Ooops ! La bonne réponse était ...</h4>
                {question.answers.map((a) => a.correct_answer && <AnswerCard answer={a} key={a.id} page="result"/>)}
                <p className="question-joke">{question.description}</p>
                <p className="question-text">Tentez votre chance la semaine prochaine pour en gagner un nouveau.</p>
              </div>
            </>
          )
        }
      }
      return (
        <div className="question-container">
          <div className="question-response-container">
            {content()}
            <div style={{marginTop: 10}}>
              <OSButton title="Continuer" link="/opportunites/livret-p/my-investment" size="large" variant="primary" />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="question-container">
          <div className="content-container">
            <div className="upper-part">
              <CountdownCircleTimer
                isPlaying
                duration={30}
                colors={['#36B37E', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[20, 10, 5, 0]}
                onComplete={() => answerQuestion(true)}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
            <p className="question-title">Question</p>
            <p className="title">{question.title}</p>
            <div className="answers">
              {question.answers.map((answer, index) => <AnswerCard answer={answer} position={index} key={answer.id} />)}
            </div>
          </div>
          <div className="question-submit">
            <button className="question-submit-btn" onClick={() => answerQuestion()}>Valider ma réponse</button>
          </div>
        </div>
      )
    }
  }

  if (loading || question === {}) {
    return 'Chargement ...'
  } else if (!loading && question !== {}) {
    return renderQuestionOrAnswer();
  }
};

export default observer(BoostQuestion);
