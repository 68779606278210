import React, {useEffect, useState} from 'react';

import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";
import AuthenticationStore from "../../../stores/AuthenticationStore.ts";
import {ProfileAvatar, OtherProfileAvatar} from "./Avatar/avatar";
import OSButton from "../../Button";

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';

import './style.scss';
const Profile = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(localStorage.getItem('company')));
  const [isOpen, setIsOpen] = useState(false);
  const currentProfile = defaultProfile ? user : company;
  const [companies, setCompanies] = useState(null);
  const fetchCompanies = async () => {
    const response = await CompanyNetwork.myCompanies();
    if (response) {
      setCompanies(response);
    }
  }
  function logout() {
    AuthenticationStore.signOut();
    localStorage.clear();
    window.location.replace('/');
  }

  function renderCompanies() {
    if (companies.length > 0) {
      return companies.map((c, index) => (!company || (c.id !== company.id)) && (
        <OtherProfileAvatar profile={c} index={index} key={index} menu={true} />
      ))
    }
  }

  useEffect(() => {
    fetchCompanies();
  }, [])

  useEffect(() => {
    !defaultProfile && (setCompany(JSON.parse(localStorage.getItem('company'))));
  }, [defaultProfile])

  return (
    <>
      <div className="switch-profile-container" onClick={() => setIsOpen(!isOpen)}>
        <div className="os-flex gap-8">
          {defaultProfile ? <ProfileAvatar profile={currentProfile} main/> : <OtherProfileAvatar profile={company} index={0} main/>}
          <p className="os-body2">
            {defaultProfile
                ? `${currentProfile?.full_name?.slice(0, 8)}${currentProfile?.full_name?.length > 8 ? '...' : ''}`
                : `${company?.legal_name?.slice(0, 7)}${company?.legal_name?.length > 7 ? '...' : ''}`}
          </p>
        </div>
        <UnfoldMoreIcon />
      </div>
      {isOpen && (
        <div className="other-profiles os-flex__column gap-8">
          {!defaultProfile ? (
            <>
              <OtherProfileAvatar profile={company} index={0} menu main />
              <ProfileAvatar profile={user} menu />
            </>
          ) : (
            <ProfileAvatar profile={user} menu main />
          )}
          {renderCompanies()}
          {companies?.length > 0 && (
            <OSButton variant="secondary" size="small" title="Voir mes sociétés" link="/companies" />
          )}
          <div className="os-flex os-gap-8" style={{flexDirection: 'row', justifyContent: "space-between"}}>
            <OSButton variant="no_border"
                      size="small"
                      title="Ajouter une société"
                      fit icon={<AddIcon />}
                      onClick={() => {
                        localStorage.removeItem('CompanySurveyStep');
                        localStorage.removeItem('company');
                        localStorage.setItem('defaultProfile', 'true');
                        window.location.replace('/company/create')
                      }}
            />
            <OSButton variant="no_border" size="small" title="Se déconnecter" fit onClick={() => logout()} icon={<LogoutIcon />} />
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
