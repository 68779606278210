import React from "react";
import "./style.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PartnerViewCard = ({partner}) => {
  return (
    <div className="partner-vc-container">
      <div className="partner-vc-left">
        <div style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.6) 100%), url(${partner.cover.large})`}} className="partner-vc-card" >
          <div className="partner-vc-card-bottom-container">
            <img loading="lazy" src={partner.logo?.original} alt="" className="partner-vc-card-logo"/>
          </div>
        </div>
      </div>
      <div className="partner-vc-infos">
        <div className="partner-vc-title">
          <h4 className="partner-vc-title os-h4">{partner.name}</h4>
          <div className="partner-vc-link-container">
            <a href={partner.link} className="partner-vc-link" target="_blank" rel="noreferrer">Aller sur le site</a>
            <ArrowForwardIcon />
          </div>
        </div>
        <div className="partner-view-element-container">
          <div className="partner-vc-content" dangerouslySetInnerHTML={{__html: partner.content}} />
        </div>
      </div>
    </div>
  );
};

export default PartnerViewCard;
