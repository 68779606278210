import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import ReferralNetwork from "../../config/Network/ReferralNetwork.ts";

import TextField from "@material-ui/core/TextField";

const AddReferralCodeForm = ({func}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object({
    code: Yup.string().required("Veuillez saisir un code"),
  })

  async function addPromotion(code) {
    setError(null)
    setSuccess(false)
    const response = await ReferralNetwork.addCode(code);
    if (response.error) {
      setSuccess(false);
      setError(response.error);
    } else if (response.success) {
      setError(null);
      setSuccess(true);
      setTimeout(() => {
        func(true);
      }, 2000)
    }
    setSubmitting(false);
    setIsLoading(false);
  }

  const formik= useFormik({
    initialValues: {code: ''},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      setIsLoading(true);
      await addPromotion(values.code)
    },
  });

  return (
    <div className="add-coupon-form">
      <form className="os-flex gap-16 os-user-survey-form" onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="code"
          name="code"
          label="Code de parrainage"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          variant={"outlined"}
          className="by-1"
        />

        <div className="user-survey-btn-container">
          <button type="submit" className="user-survey-btn" disabled={submitting || isLoading}>Continuer</button>
        </div>
      </form>
      {error !== null && <span className="error">{error}</span>}
      {success && <span className="success">Code ajouté avec succès !</span>}
    </div>
  );
};
export default AddReferralCodeForm;
