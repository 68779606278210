import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import Images from "../../../assets/images";

import "./style.scss";

// import required modules

const SmallSlider = ({func}) => {
  return (
    <>
      <Swiper autoplay={{ delay: 3000, disableOnInteraction: false}}
        pagination={{clickable: true}}
        modules={[Autoplay, Pagination]}
        className="smallSwiper">
        <SwiperSlide style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0, 0.6) 100%), url(${Images.dimitri})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
          <div className="slider-content">
            <p className="slider-content-label">Découvrez Livret P.</p>
            <h4 className="slider-content-title">Dimitri vous dit tout sur le Livret P.</h4>
            <button className="slider-btn" onClick={() => func('video')}>Voir la vidéo</button>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SmallSlider;
