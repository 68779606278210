import React from 'react';
import {Helmet} from "react-helmet";

import Phone from "../../components/Phone";
import PublicNav from "../../components/Navbar/publicNav";
import images from "../../assets/images";
import Footer from "../../components/Footer";
import OSButton from "../../components/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import OsCard from "../../components/card/basic";
import Images from "../../assets/images";

import Badge from "../../components/Badge/Badge";

import './style.scss';

const CgpLogin = () => {
  return (
    <>
      <Helmet>
        <title>Connexion partenaires</title>
      </Helmet>
      <PublicNav />
        <div className="container">
          <div className="auth-container green-bg">
						<div className="os-flex logo-container">
            	<img src={Images.brand.longLogo} className="auth-os-logo"/>
						</div>
            <div className="auth-phone-container">
              <div className="os-flex__column top-container" style={{alignItems: "flex-start"}}>
								<div className="os-flex badges-page-container">
									<h4 className="os-h4 auth-title">Connexion</h4>
									<div style={{marginTop: 8}}>
										<Badge color="white">Espace partenaire</Badge>
									</div>
								</div>
              <div className="os-flex top-form" style={{marginBottom: 24}}>
								<span className="signup-text">Vous êtes un partenaire et vous n'êtes pas encore inscrit ?</span>
								<a href="/sign-up/cgp" className="signup-link">Inscrivez-vous !</a>
              </div>
								<OsCard>
									<div className="os-flex info-callout">
										<span className="os-caption">Vous êtes déjà inscrit en tant que particulier ou client Livret P ?</span>
										<OSButton variant="no_border" size="large" title="Connectez-vous" fit link="/sign-in" icon={<ArrowForwardIcon />} position="end"/>
									</div>
									<p className="os-subtitle2">Connectez-vous avec votre numéro de téléphone</p>
                  <Phone user={null} page="auth" recordType="cgp" />
								</OsCard>
              </div>
            </div>
            <div className="labels">
              <img src={images.brand.labels} alt="Labels Openstone"/>
            </div>
          </div>
        </div>
	    <Footer />
    </>
  );
};

export default CgpLogin;
