import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import Chips from "../../../../components/Chips/Chips";
import getBadge from "../../../../components/Badge/getBadge";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// custom style
import "./style.scss";

const SmallSlider = ({items, opened, product}) => {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="productSwiper">
        {items.map(item => (
          <SwiperSlide key={`${item?.id}-${item?.created_at}`}>
            <img
              src={item}
              alt="Openstone - image produit"
              className="swiper-slide-image"
            />
            <div className="absolute-chip">
              {!product.subscription_state === 'end' && getBadge(product.subscription_state === 'verified' ? 'verified' : 'pending', 'kyc_status', product.subscription_state === 'verified' ? 'Souscription ouverte' : 'Souscription fermée')}
              {product.subscription_state === 'end' && getBadge('verified', 'kyc_status', 'Souscription terminée')}
            </div>
              {product.offer_label && (
                <div className={`slider-content ${opened ? 'slider-content-open-bg' : 'slider-content-close-bg'}`}>
                  <div className="os-chips-list" style={{marginTop: 16}}>
                    <Chips transparent>{product.offer_label}</Chips>
                  </div>
                </div>
              )}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default SmallSlider;
