import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
    Collapse, IconButton, Box
} from '@mui/material';
// components
import Label from '../label';
import Scrollbar from '../scrollbar';
import {TableHeadCustom} from './index.js';
import moment from "moment/moment";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PercentIcon from '@mui/icons-material/Percent';

import MoneyHelper from "../../Helpers/MoneyHelper";
import OSButton from "../Button";
import React, {useState} from "react";
import OsModal from "../Modals";
import CgpNetwork from "../../config/Network/CgpNetwork.ts";
import {toast} from "react-toastify";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import './myTable.scss';

// ----------------------------------------------------------------------

MyTable.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
};

export default function MyTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />
            <TableBody>
                {tableData.map((row, index) => row.transaction_type !== 'fees' && (
                    <MyTableRow key={`tableCell${index}-${row.created_at}-${row.notes}`} row={row} index={index} {...other}/>
                ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

MyTableRow.propTypes = {
  row: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.string,
    status: PropTypes.string,
    date: PropTypes.string,
  }),
};

const checkRowType = (transaction, other) => {
  let title;
  let growth;
  let amount = MoneyHelper.formatEuro(transaction.amount);
  if (transaction.transaction_type === 'withdraw') {
    title = 'Retrait';
    growth = <div className="arrow-container arrow-container-red"><SouthWestIcon /></div>;
  } else if (transaction.transaction_type === 'top_up') {
    title = other.cgp ? 'Soucription' : 'Dépôt';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
  } else if (transaction.transaction_type === 'interest_from_base_rate') {
    title = 'Intérêts';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
    amount = MoneyHelper.formatEuro(transaction.amount, false, 4, 4);
  } else if (transaction.transaction_type === 'interest_from_boosts') {
    title = 'Intérêts boost';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
    amount = MoneyHelper.formatEuro(transaction.amount, false, 4, 4);
  } else if (transaction.transaction_type === 'gift_in_euro') {
    title = 'Autres primes';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
  } else if (transaction.transaction_type === 'referral_bonus') {
    title = 'Prime de parrainage';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
  } else if (transaction.transaction_type === 'referred_bonus') {
    title = 'Prime de bienvenue';
    growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
  } else if (transaction.transaction_type === 'taxes') {
    title = 'Prélèvements et impôts';
    growth = <div className="arrow-container arrow-container-red"><SouthWestIcon /></div>
    amount = MoneyHelper.formatEuro(transaction.amount, false, 2, 2);
  } else if (transaction.transaction_type === 'transfer') {
    title = 'Transfert';
    growth = <div className="arrow-container arrow-container-info"><MoveUpIcon /></div>
  } else if (transaction.transaction_type === 'cgp_entry_fees') {
    title = "Frais d'entrée";
    growth = <div className="arrow-container arrow-container-info"><PercentIcon /></div>
  } else if (transaction.transaction_type === 'engagement') {
		title = "Engagement";
		growth = <div className="arrow-container arrow-container-green"><ArrowOutwardIcon /></div>;
	}
  return {title, growth, amount};
};

const checkRowStatus = (transaction, other) => {
  const livretp = window.location.href.includes("livret");
  let status;
  if (transaction.status === 'success' || transaction.status === 'paid') {
		status = 'Validé';
	} else if (transaction.status === 'error') {
    status = 'Erreur';
  } else if (transaction.status === 'pending') {
    if (livretp) {
      status = 'En cours';
    } else {
      status = other.cgp ? "En attente des fonds" : 'En cours';
    }
  } else if (transaction.status === 'pending_scpi') {
    status = 'En attente';
  } else if (transaction.status === 'potential') {
    status =  'En cours';
  } else if (transaction.status === 'not_started') {
		if (other.cgp) {
			status = transaction.type === 'engagement' ? "Paiement à réaliser" : "En attente de signature"
		} else {
			status = "Paiement à réaliser"
		}
  }
  return status;
};

function MyTableRow({ row, index, ...other }) {
	const [isShowing, setIsShowing] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const livretp = window.location.href.includes("livret");
	const [open, setOpen] = useState(false);

	async function sendReminderAction() {
		setSubmitting(true);

		const response = await CgpNetwork.reSendSignature(row.ys_signature_request_id);
		if (response.status === 200) {
			toast.success(response.message, {
				toastId: `reSentDocument`
			});
			setIsShowing(false)
		} else if (response.status === 401) {
			toast.error(response.message, {
				toastId: 'reSentDocument-error'
			});
		} else {
			toast.error(`Un problème est survenu lors de l'envoi, si le problème persiste veuillez contacter nos équipes.`,{
				toastId: `error-sentDocument`
			})
		}
		setSubmitting(false);
	}

	const checkPaiementLink = (row) => {
		localStorage.setItem('InvestmentAmount', row.amount)
		if (livretp) {
			return `https://app.openstone.com/opportunites/livret-p/deposit?transaction_id=${row.id}&amount=${row.amount}`
		} else {
			return `https://app.openstone.com/opportunites/${row.product?.category_slug}/${row.product?.slug}/finalise?transaction_id=${row.id}`
		}
	}

    const checkFundCallAmount = (row, fund_call) => {
        if (fund_call.call_number === 1) {
            return (
                <>
                    {MoneyHelper.formatEuro(fund_call.amount)}
                    <p style={{margin: 0, fontSize: 11}}>Dont frais d'entrée {MoneyHelper.formatEuro(row.associated_fees.amount)}</p>
                </>
            )
        } else {
           return MoneyHelper.formatEuro(fund_call.amount)
        }
    }


    return (
        <>
            {row.transaction_type !== 'cgp_entry_fees' && (
                <TableRow className={`table-row ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <TableCell className={row?.associated_fund_calls?.length > 0 && 'funds-call-row'}>
                        <div style={{ display: "flex", alignItems: 'center', gap: 10 }}>
                            {checkRowType(row, other).growth}
                            <span className="ds_category_name">{checkRowType(row, other).title}</span>
                        </div>
                        {row?.associated_fees && (
                            <div style={{ display: "flex", alignItems: 'center', gap: 10, margin: '12px 0 4px 0' }}>
                                {checkRowType(row.associated_fees, other).growth}
                                <span className="ds_category_name">{checkRowType(row.associated_fees, other).title}</span>
                            </div>
                        )}
                        {row?.associated_fund_calls?.length > 0 && (
                            <div className="funds-calls-btn" onClick={() => setOpen(!open)}>
                                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                <span>{open ? "Cacher l'appel de fonds" : "Afficher  l'appel de fonds"}</span>
                            </div>
                        )}
                    </TableCell>

                    <TableCell className={row?.associated_fund_calls?.length > 0 && 'funds-call-row'}>
                        <span className="ds_category_name">{moment(row.created_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}</span>
                        {row.associated_fees && (
                            <div style={{ margin: '12px 0 4px 0' }}>
                                <span className="ds_category_name">{moment(row.associated_fees.created_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}</span>
                            </div>
                        )}
                    </TableCell>

                    <TableCell className={row?.associated_fund_calls?.length > 0 && 'funds-call-row'}>
                        <span className="ds_category_name">{checkRowType(row, other).amount}</span>
                        {row.associated_fees && (
                            <div style={{ margin: '12px 0 4px 0' }}>
                                <span className="ds_category_name">{checkRowType(row.associated_fees, other).amount}</span>
                            </div>
                        )}
                    </TableCell>

                    <TableCell className={row?.associated_fund_calls?.length > 0 && 'funds-call-row'}>
                        <Label
                            variant="soft"
                            color={
                                ((row.status === 'pending' || row.status === 'pending_scpi' || row.status === 'potential') && 'info') ||
                                (row.status === 'error' && 'error') || (row.status === 'not_started' && 'warning') ||
                                'success'
                            }
                        >
                            {checkRowStatus(row, other)}
                        </Label>
                    </TableCell>

                    <TableCell className={row?.associated_fund_calls?.length > 0 && 'funds-call-row'}>
                        <div className="os-flex gap-16">
                            {(row?.related_doc !== null && row?.transaction_type === 'top_up') && <OSButton link={row?.related_doc?.document?.url} variant="no_border-info" fit size="small" title="Consulter le bulletin" target />}
                            {(other.cgp && (row?.status === 'not_started' && row?.transaction_type === 'top_up')) && <OSButton onClick={() => setIsShowing(true)} variant="no_border-info" fit size="small" title="Envoyer un rappel" />}
                            {(!other.cgp && (row.status === 'not_started' || row.status === 'error')) && (
                                <OSButton title="Effectuer le paiement" variant="primary-full" fit size="small" link={checkPaiementLink(row)} />
                            )}
                        </div>
                    </TableCell>
                </TableRow>
            )}

            {open && (
                <TableRow className="collapsed-row">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={0}>
                                {row.associated_fund_calls && row?.associated_fund_calls?.length > 0 && (
                                    <>
                                        <Table size="small" aria-label="associated-fund-calls">
                                            <TableBody>
                                                {row.associated_fund_calls.map((fund_call, fcIndex) => (
                                                    <TableRow key={fcIndex}>
                                                        <TableCell component="th" scope="row" style={{fontSize: 12}}>
                                                            Appel de fond #{fund_call.call_number}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: 12}}>
                                                            <div>
                                                                Date dû: {moment(fund_call.due_date, 'YYYY-MM-DD').format('DD/MM/yyyy')}
                                                            </div>
                                                            <div>
                                                                Payé le: {fund_call.paid_at && moment(fund_call.paid_at, 'YYYY-MM-DD').format('DD/MM/yyyy')}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell style={{fontSize: 12}}>{checkFundCallAmount(row, fund_call)}</TableCell>
                                                        <TableCell style={{fontSize: 12}}>
                                                            <Label
                                                                variant="soft"
                                                                color={
                                                                    ((fund_call.status === 'pending' || fund_call.status === 'pending_scpi' || fund_call.status === 'potential') && 'info') ||
                                                                    (fund_call.status === 'error' && 'error') || (fund_call.status === 'not_started' && 'warning') ||
                                                                    'success'
                                                                }>
                                                                {checkRowStatus(fund_call, other)}
                                                            </Label>
                                                        </TableCell>
																											  <TableCell component="th" scope="row" style={{fontSize: 12}}>
																													{fund_call?.related_document ? <OSButton link={fund_call?.related_document?.document?.url} variant="no_border-info" fit size="small" title="Consulter l'appel" target /> :
																														<Label variant="soft" color="info">
																															Appel à venir
																														</Label>}
																											  </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                )}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <OsModal title="Confirmer le nouvel envoi de signature éléctronique" isShowing={isShowing} hide={() => setIsShowing(false)} >
                <>
                    <p>L'action suivante enverra à nouveau la demande de signature pour cette investissement, voulez-vous continuer ?</p>
                    <div className="os-flex gap-16 modal-btns-container">
                        <OSButton onClick={() => sendReminderAction()} title="Fermer" variant="primary-full" size="large" submitting={submitting} />
                        <OSButton onClick={() => sendReminderAction()} title="Envoyer" variant="primary" size="large" />
                    </div>
                </>
            </OsModal>
        </>
    );
}
