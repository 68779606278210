import React, {useEffect, useState} from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DocumentNetwork from "../../../config/Network/DocumentNetwork.ts";

import EmptyContent from "../../../components/empty-content/EmptyContent";
import DocumentTable from "../../../components/Table/DocumentTable";

import {ReactComponent as NoDocs} from "../../../assets/Illustrations/no-documents.svg";
import TransactionsLoader from "../../../components/Loaders/Transactions";
import OSButton from "../../../components/Button";

import './style.scss';

const BrTransactions = ({page}) => {
  const [loadingBr, setLoadingBr] = useState(true);
  const [brDocs, setBrDocs] = useState([]);
  const defaultProfile = useState(JSON.parse(localStorage.getItem('defaultProfile')))[0];

  const getBr = async () => {
    setLoadingBr(true);
    const response = defaultProfile === true ? await DocumentNetwork.my_br(9) : await DocumentNetwork.my_company_br(9);
    if (response) {
      setBrDocs(response);
      setLoadingBr(false);
    }
  };

  function renderWithdrawals() {
    if (loadingBr) {
      return [1, 2, 3, 4].map(l => <TransactionsLoader key={l}/>);
    } else if (!loadingBr && document !== undefined) {
      if (brDocs !== [] && brDocs.length > 0) {
        return <DocumentTable title="" tableData={page === 'preview' ? brDocs.slice(0,9) : brDocs} subheader=''
                              tableLabels={[
                                { id: 'title', label: 'Nom du produit' },
                                { id: 'created_at', label: 'Date' },
                                { id: 'amount', label: 'Montant' },
                                { id: 'status', label: 'Statut' },
                                { id: 'bulletin', label: 'Bulletin de retrait' },
                              ]} />
      } else {
        return (
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <EmptyContent illustration={<NoDocs/>} text="Aucun bulletin de retrait à afficher" />
          </div>
        )
      }
    }
  }

  useEffect(() => {
    getBr();
  }, [])

  return (
    <div className={page !== 'preview' && 'my-documents-container'}>
      {page !== 'preview' && (
        <div className="back-to-docs" onClick={() => window.location.replace('/documents')}>
          <ArrowBackIcon /> Revenir aux documents
        </div>
      )}
      <h4 className="os-h4" style={{marginTop: 24}}>Mes bulletins de retrait</h4>
      <p className="os-body2">Vos bulletins signés pour chacun de vos retraits</p>
      <div className="my-transactions-container">
        {renderWithdrawals()}
      </div>
      {(page === 'preview' && (!loadingBr && brDocs?.length > 0)) && (
        <div className="see-more">
          <OSButton size="large" variant="secondary" title="Voir plus de bulletins" link="/documents/bulletins/retraits" fit />
        </div>
      )}
    </div>
  );
};

export default BrTransactions;
