import React, {useEffect, useState} from 'react';
import OsModal from "../Modals";
import OSButton from "../Button";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';

import './style.scss';

const IntegratedSignature = ({show, updateShow, signatureLink, body, title}) => {
  const [signatureState, setSignatureState] = useState(null);

  useEffect(() => {
    if (show) {
      const script = document.createElement('script');
      script.src = 'https://cdn.yousign.tech/iframe-sdk-1.5.0.min.js'; // Use the appropriate Yousign SDK URL
      script.integrity = 'sha384-wrNTU81R+b2K+9A1aE1c3blhReNpdRK6Tn1fzXOXhWGZGUk+Btp0dyfnk45pVpOr'
      script.crossOrigin = 'anonymous'
      document.body.appendChild(script);
			

      script.onload = () => {
        const yousign = new window.Yousign({
          signatureLink: signatureLink,
          iframeContainerId: 'yousign-iframe-container',
          isSandbox: process.env.REACT_APP_ENV !== 'production',
          frameAncestors: 'none',
	        disableDomainValidation: process.env.REACT_APP_ENV !== 'production',
          onSuccess: () => setSignatureState('signed'),  // Callback when operation is successful
          onError: () => setSignatureState('error'),      // Callback on error
        });
        yousign.onSuccess((data) => {
          setSignatureState('signed');
        });

        yousign.onError((data) => {
          setSignatureState('error');
        });
      };
    }
  }, [show])

  const renderSignatureModalBody = () => {
    if (signatureState === 'signed') {
      return (
        <div className="signature-container os-flex__column gap-16">
          <div className="signature-container-message os-flex gap-16">
            <CheckCircleOutlineIcon />
            <p className="success os-body1">Nous avons bien reçu votre signature, vous pouvez fermer cette page et accéder à la plateforme Openstone</p>
          </div>
          <div className="os-flex gap-16" style={{justifyContent: 'flex-end'}}>
            <OSButton variant="primary" size="medium" title="Fermer et accéder à la plateforme" onClick={() => updateShow()} fit />
          </div>
        </div>
      )
    } else if (signatureState === 'error') {
      return (
        <div className="signature-container-error os-flex__column gap-16">
          <div className="os-flex gap-16">
            <ErrorIcon fontSize="large"/>
            <p className="error os-body1">Une erreur est survenue lors de la signature du document, veuillez recharger la page ou contacter nos équipes</p>
          </div>
          <div className="os-flex gap-16" style={{justifyContent: 'flex-end'}}>
            <button id="open_os_intercom" className="write-us-btn">Nous écrire</button>
            <OSButton variant="primary" size="medium" title="Recharger la page" onClick={() => window.location.reload()} fit />
          </div>
        </div>
      )
    } else {
      return <div id="yousign-iframe-container"></div>;
    }
  }

  return (
    <div>
      <OsModal title={title} isShowing={show} cantClose>
        <p>{body}</p>
        {renderSignatureModalBody()}
      </OsModal>
    </div>
  );
};

export default IntegratedSignature;
