import APIs from './Network.ts';

class CompanyTransactionNetwork extends APIs {
  async list({ page = 1, perPage = 20, transaction_type, date_range }) {
    let extraFilters = {};
    if (transaction_type) {
      extraFilters = { ...extraFilters, transaction_type };
    }
    if (date_range) {
      extraFilters = { ...extraFilters, date_range };
    }

    return await this.get('/company_transactions', {
      page,
      per_page: perPage,
      ...extraFilters,
    });
  }

  async withdraw(amount: number, br_id: string, iban: string, bic: string) {
    return await this.post(
      '/company_transactions/withdraw',
      {
        amount: amount.toString(),
        br_id: br_id,
        iban,
        bic
      },
      true,
    );
  }

  async cancelTransaction(transactionId: number) {
    return await this.delete(`/company_transactions/${transactionId}`, {});
  }
}

export default new CompanyTransactionNetwork();
