import React from "react"
import ContentLoader from "react-content-loader"

const OpportunityCardLoader = (props) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={600}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="5" y="-17" rx="5" ry="5" width="100%" height="100%" />
  </ContentLoader>
)

export default OpportunityCardLoader;
